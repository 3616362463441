import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterEvent,
} from '@angular/router';
import { HttpclientService } from '../../../../apiservices/httpclient.service';
import { HistoryService } from 'src/app/apiservices/history.service';
import { env } from '../../../../config/config';
import { ScrollService } from '../../../../apiservices/scroll.services';

import { HostListener } from '@angular/core';
import { OwlCarousel } from 'ngx-owl-carousel';
@Component({
  selector: 'app-service-detailsec',
  templateUrl: './service-detailsec.component.html',
  styleUrls: ['./service-detailsec.component.css'],
})
export class ServiceDetailsecComponent implements OnInit {

  @ViewChild('owlcarousel') owlElement: OwlCarousel;
  servicelist;
  url;
  flag;
  service_slide;
  current_title;
  index = 0;
  screenWidth: number;

  customOptions: OwlOptions = {
    loop: false,
    autoplay: false,
    margin: 30,
    nav: true,
    dots: false,

    navText: [
      '<i class="ti ti-arrow-left"></i>',
      '<i class="ti ti-arrow-right"></i>',
    ],
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 4,
      },
    },
  };

  slideStore = [
    {
      title: 'Search Engine Optimization',
    },
    {
      title: 'Pay per click Services',
    },
    {
      title: 'Social Media Marketing',
    },
    {
      title: 'Google Marketing',
    },
    {
      title: 'Email Maketing',
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private service: HttpclientService,
    private history: HistoryService,
    private ElementRef: ElementRef 
  ) {}
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenWidth = window.innerWidth;

  }

  ngOnInit(): void {
    this.url = env.apiurl;
    this.service.getAllValues('services').subscribe((res) => {
      this.service_slide = res;

      let serve = this.history.get_services();
      this.index = this.service_slide.findIndex((r) => r.id === serve.id);
      let custom: OwlOptions = {
        loop: false,
        autoplay: false,
        margin: 30,
        nav: true,
        dots: false,
        startPosition: this.index,

        navText: [
          '<i class="ti ti-arrow-left" id="arrow-left"></i>',
          '<i class="ti ti-arrow-right" id="arrow-right"></i>',
        ],
        responsive: {
          0: {
            items: 2,
          },
          400: {
            items: 2,
          },
          740: {
            items: 3,
          },
          940: {
            items: 4,
          },
        },
      };
      this.owlElement.options = custom;
    });
    this.servicelist = this.history.get_services();

    if (this.servicelist.sub_services.length > 0) {
      this.servicelist.sub_services.sort((a, b) => a.sort_order - b.sort_order);
    }
    this.current_title = this.servicelist.title;

    if (this.servicelist.tech_logo.length > 0) {
      this.flag = true;
    }
  }

  get_service(e, i) {

    if (this.servicelist.title != e.title) {
      this.history.set_services(e);
      window.location.href = `/services/${e.service_slug}`;

    }

  }
  next_nav(e) {
    console.log('hello');

  }
}
