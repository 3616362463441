import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexSteelPlantComponent } from './index-steel-plant/index-steel-plant.component';
import { ContactPageComponent } from './inner-pages/contact-page/contact-page.component';
import { ServicepageComponent } from './servicepage/servicepage.component';
import { ServicedetailpageComponent } from './servicedetailpage/servicedetailpage.component';
import { AboutuspageComponent } from './aboutuspage/aboutuspage.component';
import { CareerpageComponent } from './careerpage/careerpage.component';
import { ContactuspageComponent } from './contactuspage/contactuspage.component';
import { WhitepaperComponent } from './whitepaper/whitepaper.component';
import { WhitepaperdetailComponent } from './whitepaperdetail/whitepaperdetail.component';
import { LabsComponent } from './labs/labs.component';
import { FormsPageComponent } from './forms-page/forms-page.component';
const routes: Routes = [
  { path: '', component: IndexSteelPlantComponent },
  { path: 'contact-page', component: ContactPageComponent },
  { path: 'services', component: ServicepageComponent },
  {
    path: 'services/:slug',
    component: ServicedetailpageComponent,
  },
  { path: 'about-us', component: AboutuspageComponent },
  { path: 'careers-in-6solve', component: CareerpageComponent },
  { path: 'contact-us', component: ContactuspageComponent },
  { path: 'whitepapers', component: WhitepaperComponent },
  { path: 'whitepaper/:slug/:id', component: WhitepaperdetailComponent },
  { path: 'labs', component: LabsComponent },
  { path: 'forms', component: FormsPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
