import { Component, OnInit } from '@angular/core';
import {HttpclientService} from '../../../../apiservices/httpclient.service'
import {env} from '../../../../config/config'

@Component({
  selector: 'app-about-howwework',
  templateUrl: './about-howwework.component.html',
  styleUrls: ['./about-howwework.component.css']
})
export class AboutHowweworkComponent implements OnInit {
  ourworklist=[]
  content
  flag=false
  url
  constructor(private service:HttpclientService) {

    this.ourworklist=[
      {
        image:"assets/images/services/website development.png",
       title:"Some Work Done by Us" ,
      
       content1:"We help enterprises to continuously reinvent existing systems and applications to meet IT goals that are aligned with business requirements. Our deep domain expertise helps us to operate in complex environments and manage mission-critical applications to ensure zero downtime.",

      
      },
      {
       image:"assets/images/services/business application development.png",
       title:"Some Work Done by Us" ,
        content1:"We help enterprises to continuously reinvent existing systems and applications to meet IT goals that are aligned with business requirements. Our deep domain expertise helps us to operate in complex environments and manage mission-critical applications to ensure zero downtime.",
 
       },
       {
       image:"assets/images/services/mobile app development.png",
       title:"Some Work Done by Us" ,
        content1:"We help enterprises to continuously reinvent existing systems and applications to meet IT goals that are aligned with business requirements. Our deep domain expertise helps us to operate in complex environments and manage mission-critical applications to ensure zero downtime.",
 
       
       },
       {
       image:"assets/images/services/digital creative solutions.png",
       title:"Some Work Done by Us" ,
        content1:"We help enterprises to continuously reinvent existing systems and applications to meet IT goals that are aligned with business requirements. Our deep domain expertise helps us to operate in complex environments and manage mission-critical applications to ensure zero downtime.",
 
      
       },
       {
       image:"assets/images/services/digital marketing.png",
       title:"Some Work Done by Us" ,
        content1:"We help enterprises to continuously reinvent existing systems and applications to meet IT goals that are aligned with business requirements. Our deep domain expertise helps us to operate in complex environments and manage mission-critical applications to ensure zero downtime.",
 
        
       },
       
    ]
   }

  ngOnInit(): void {
    this.url=env.apiurl
    this.service.getAllValues('about-us-workstatuses').subscribe(res => {
      this.content=res
      if(this.content.length>0){
        this.flag=true
      }
    })
  }

}
