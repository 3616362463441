
    <header class="site-header mo-left header ext-header navstyle1">
		<div class="middle-bar bg-white">
			<div class="container">
		
				<div class="middle-area">
					<div class="logo-header">
						<a routerLink="/"><img src="assets/images/logo-2.png" alt=""></a>
					</div>
					<div class="service-list">
						<ul>
							<li>
								<i class="la la-clock"></i>
								<h4 class="title">Open Time</h4>
								<span>Mon-Fri: 8am-7pm</span>
							</li>
							<li>
								<i class="las la-phone-volume"></i>
								<h4 class="title">Call us</h4>
								<span>(+00)888.666.88</span>
							</li>
							<li>
								<i class="la la-map"></i>
								<h4 class="title">Office Address</h4>
								<span>183 Donato Parkways, NY</span>
							</li>
						</ul>
					</div>
				</div>
				
			</div>
		</div>	
	
        <div class="sticky-header main-bar-wraper navbar-expand-lg">
            <div class="main-bar clearfix ">
                <div class="container clearfix">
           
                    <div class="logo-header mostion">
						<a  routerLink="/"><img src="assets/images/logo-2.png" alt=""></a>
					</div>
              
                    <button class="navbar-toggler collapsed navicon justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
						<span></span>
						<span></span>
						<span></span>
					</button>
           
                    <div class="extra-nav">
                        <div class="extra-cell">
                            <button id="quik-search-btn" type="button" class="site-button-link"><i class="la la-search"></i></button>
						</div>
                    </div>
                 
                    <div class="dlab-quik-search ">
                        <form action="#">
                            <input name="search" value="" type="text" class="form-control" placeholder="Type to search">
                            <span id="quik-search-remove"><i class="ti ti-close"></i></span>
                        </form>
                    </div>
           
                    <div class="header-nav navbar-collapse collapse justify-content-start onepage" id="navbarNavDropdown">
						<div class="logo-header d-md-block d-lg-none">
							<a  routerLink="/"><img src="assets/images/logo-2.png" alt=""></a>
						</div>
                        <ul class="nav navbar-nav navbar">	
							<li><a href="#home" class="scroll nav-link active">Home <i class="fa fa-chevron-down"></i></a>
								<ul class="sub-menu">
									<li><a [routerLink] = "''">Home construction</a></li>
									<li><a [routerLink] = "'/index-food-factory'">Home Food Industry</a></li>
									<li><a [routerLink] = "'/index-agriculture'">Home Agriculture</a></li>
									<li><a [routerLink] = "'/index-steel-plant'">Home Steel Plant</a></li>
									<li><a [routerLink] = "'/index-solar-plant'">Home Solar Plant</a></li>
								</ul>
							</li>
							<li><a href="#about-us" class="scroll nav-link">About Us</a></li>
							<li><a href="#services" class="scroll nav-link">Services</a></li>
							<li><a href="#projects" class="scroll nav-link">Projects</a></li>
							<li><a href="#team" class="scroll nav-link">Team</a></li>
							<li><a href="#news" class="scroll nav-link">News</a></li>
							<li><a href="#client" class="scroll nav-link">Client Says</a></li>
						</ul>
						<div class="dlab-social-icon">
							<ul>
								<li><a class="site-button facebook sharp-sm outline fa fa-facebook" href="javascript:void(0);"></a></li>
								<li><a class="site-button twitter sharp-sm outline fa fa-twitter" href="javascript:void(0);"></a></li>
								<li><a class="site-button linkedin sharp-sm outline fa fa-linkedin" href="javascript:void(0);"></a></li>
								<li><a class="site-button instagram sharp-sm outline fa fa-instagram" href="javascript:void(0);"></a></li>
							</ul>
						</div>	
                    </div>
                </div>
            </div>
        </div>
    
    </header>

