<form
  class="contact-box dzForm"
  [formGroup]="OnboardingfbForm"
  id="OnboardingfbForm"
>
  <div class="section-head text-left">
    <h2 class="title">Feedback Form II (Onboarding Feedback)</h2>
  </div>
  <div class="row feedback-form-row">
    <div class="col-lg-6 col-md-12">
      <mat-form-field appearance="outline" class="first">
        <mat-label>Name</mat-label>
        <input
          matInput
          type="text"
          placeholder="Your Name"
          formControlName="name"
          maxlength="32"
          required
          (keypress)="onKeyPress($event)"
        />

        <mat-error
          class="error"
          *ngIf="Onboardingfb_submitted && errorControl.name.errors?.required"
        >
          Name is required.
        </mat-error>
        <mat-error
          class="error"
          *ngIf="Onboardingfb_submitted && errorControl.name.errors?.pattern"
        >
          Must contain only alphabets of minimum 2 characters to maximum 32
          characters
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-lg-6 col-md-12">
      <mat-form-field appearance="outline">
        <mat-label>Contact Number</mat-label>
        <input
          matInput
          placeholder="Contact Number"
          formControlName="phone"
          required
          onkeypress="return event.charCode >= 48 && event.charCode <= 57 && this.value.length!=10"
        />

        <mat-error
          class="error"
          *ngIf="Onboardingfb_submitted && errorControl.phone.errors?.required"
        >
          Contact number is required.
        </mat-error>
        <mat-error
          class="error"
          *ngIf="Onboardingfb_submitted && errorControl.phone.errors?.pattern"
        >
          Please enter a valid 10 digit Contact number.
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-lg-6 col-md-12">
      <mat-form-field appearance="outline">
        <mat-label>Email Address</mat-label>
        <input
          matInput
          placeholder="Email Address"
          formControlName="email"
          required
        />

        <mat-error
          class="error"
          *ngIf="Onboardingfb_submitted && errorControl.email.errors?.required"
        >
          Email address is required.
        </mat-error>
        <mat-error
          class="error"
          *ngIf="Onboardingfb_submitted && errorControl.email.errors?.pattern"
        >
          Please enter a valid Email address.
        </mat-error>
      </mat-form-field>
    </div>

    <div class="rating-division">
      <hr />
      <h3>Feedback Category :</h3>
      <cite
        >Please rate your satisfaction on a scale of 1 to 5, with 1 being Very
        Dissatisfied and 5 being Very Satisfied</cite
      >
      <ul class="ul-rating">
        <hr />
        <li><h3>Academic Programs :</h3></li>
        <ul class="ul-sub-rating">
          <li class="rating-sub-list">
            <label>Quality of Courses </label><span>:</span
            ><label
              ><mat-star-rating
                [rating]="add_Onboardingfb.Quality_of_Courses"
                class="star-list"
                [starCount]="starCount"
               (ratingUpdated)="onRatingChanged($event, 'QOC')"
              ></mat-star-rating
            ></label>
          </li>
          <li class="error-li" *ngIf="Onboardingfb_submitted && errorControl.quality_of_courses.errors?.required">
            <div>
            <span
            class="error"
          >
            This field is required.
          </span>
          </div>
        </li>

          <li class="rating-sub-list">
            <label>Relevance of Curriculum </label><span>:</span
            ><label
              ><mat-star-rating
                [rating]="add_Onboardingfb.Relevance_of_Curriculum"
                class="star-list"
                [starCount]="starCount"
                (ratingUpdated)="onRatingChanged($event,'ROC')"
              ></mat-star-rating
            ></label>

          </li>
          <li class="error-li" *ngIf="Onboardingfb_submitted && errorControl.relevance_of_curriculum.errors?.required">
            <div>
            <span
            class="error"
          >
            This field is required.
          </span>
          </div>
        </li>

          <li class="rating-sub-list">
            <label>Learning Resources </label><span>:</span
            ><label
              ><mat-star-rating
                [rating]="add_Onboardingfb.Learning_Resources"
                class="star-list"
                [starCount]="starCount"
                (ratingUpdated)="onRatingChanged($event,'LR')"
              ></mat-star-rating
            ></label>

          </li>
          <li class="error-li" *ngIf="Onboardingfb_submitted && errorControl.learning_resources.errors?.required">
            <div>
            <span
            class="error"
          >
            This field is required.
          </span>
          </div>
        </li>

        </ul>
        <hr/>
        <li><h3>Faculty and Instructors :</h3></li>
        <ul class="ul-sub-rating">
          <li class="rating-sub-list">
            <label>Teaching Effectiveness </label><span>:</span
            ><label
              ><mat-star-rating
                [rating]="add_Onboardingfb.Teaching_Effectiveness"
                [starCount]="starCount"
                class="star-list"
                (ratingUpdated)="onRatingChanged($event,'TE')"
              ></mat-star-rating
            ></label>

          </li>
          <li class="error-li" *ngIf="Onboardingfb_submitted && errorControl.teaching_effectiveness.errors?.required">
            <div>
            <span
            class="error"
          >
            This field is required.
          </span>
          </div>
        </li>
          <li class="rating-sub-list">
            <label>Accessibility and Support </label><span>:</span
            ><label
              ><mat-star-rating
                [rating]="add_Onboardingfb.Accessibility_and_Support"
                [starCount]="starCount"
                class="star-list"
                (ratingUpdated)="onRatingChanged($event,'AS')"
              ></mat-star-rating
            ></label>

          </li>
          <li class="error-li" *ngIf="Onboardingfb_submitted && errorControl.accessibility_and_support.errors?.required">
            <div>
            <span
            class="error"
          >
            This field is required.
          </span>
          </div>
        </li>
          <li class="rating-sub-list">
            <label>Communication </label><span>:</span
            ><label
              ><mat-star-rating
                [rating]="add_Onboardingfb.Communication"
                [starCount]="starCount"
                class="star-list"
                (ratingUpdated)="onRatingChanged($event,'CN')"
              ></mat-star-rating
            ></label>
          </li>
          <li class="error-li" *ngIf="Onboardingfb_submitted && errorControl.communication.errors?.required">
            <div>
            <span
            class="error"
          >
            This field is required.
          </span>
          </div>
        </li>
        </ul>
        <hr />
        <li><h3>Administrative Services:</h3></li>
        <ul class="ul-sub-rating">
          <li class="rating-sub-list">
            <label>Enrollment and Registration Process</label><span>:</span
            ><label
              ><mat-star-rating
                [rating]="add_Onboardingfb.Enrollment_and_Registration_Process"
                class="star-list"
                [starCount]="starCount"
                (ratingUpdated)="onRatingChanged($event,'ERP')"
              ></mat-star-rating
            ></label>
          </li>
          <li class="error-li" *ngIf="Onboardingfb_submitted && errorControl.enrollment_and_registration_process.errors?.required">
            <div>
            <span
            class="error"
          >
            This field is required.
          </span>
          </div>
        </li>
          <li class="rating-sub-list">
            <label>Financial Aid and Services</label><span>:</span
            ><label
              ><mat-star-rating
                [rating]="add_Onboardingfb.Financial_Aid_and_Services"
                class="star-list"
                [starCount]="starCount"
                (ratingUpdated)="onRatingChanged($event,'FAS')"
              ></mat-star-rating
            ></label>
          </li>
          <li class="error-li" *ngIf="Onboardingfb_submitted && errorControl.financial_aid_and_services.errors?.required">
            <div>
            <span
            class="error"
          >
            This field is required.
          </span>
          </div>
        </li>
          <li class="rating-sub-list">
            <label>Timeliness of Responses</label><span>:</span
            ><label
              ><mat-star-rating
                [rating]="add_Onboardingfb.Timeliness_of_Responses"
                class="star-list"
                [starCount]="starCount"
                (ratingUpdated)="onRatingChanged($event,'TOR')"
              ></mat-star-rating
            ></label>
          </li>
          <li class="error-li" *ngIf="Onboardingfb_submitted && errorControl.timeliness_of_responses.errors?.required">
            <div>
            <span
            class="error"
          >
            This field is required.
          </span>
          </div>
        </li>
        </ul>
        <hr/>
        <li><h3>Facilities and Resources:</h3></li>
        <ul class="ul-sub-rating">
          <li class="rating-sub-list">
            <label>Internet Facilities</label><span>:</span
            ><label
              ><mat-star-rating
                [rating]="add_Onboardingfb.Internet_Facilities"
                class="star-list"
                [starCount]="starCount"
                (ratingUpdated)="onRatingChanged($event,'IF')"
              ></mat-star-rating
            ></label>
          </li>
          <li class="error-li" *ngIf="Onboardingfb_submitted && errorControl.internet_facilities.errors?.required">
            <div>
            <span
            class="error"
          >
            This field is required.
          </span>
          </div>
        </li>
          <li class="rating-sub-list">
            <label>Technology and Equipment</label><span>:</span
            ><label
              ><mat-star-rating
                [rating]="add_Onboardingfb.Technology_and_Equipment"
                class="star-list"
                [starCount]="starCount"
                (ratingUpdated)="onRatingChanged($event,'TAE')"
              ></mat-star-rating
            ></label>
          </li>
          <li class="error-li" *ngIf="Onboardingfb_submitted && errorControl.technology_and_equipment.errors?.required">
            <div>
            <span
            class="error"
          >
            This field is required.
          </span>
          </div>
        </li>
          <li class="rating-sub-list">
            <label>Working Environment</label><span>:</span
            ><label
              ><mat-star-rating
                [rating]="add_Onboardingfb.Working_Environment"
                class="star-list"
                [starCount]="starCount"
                (ratingUpdated)="onRatingChanged($event,'WE')"
              ></mat-star-rating
            ></label>
          </li>
          <li class="error-li" *ngIf="Onboardingfb_submitted && errorControl.working_environment.errors?.required">
            <div>
            <span
            class="error"
          >
            This field is required.
          </span>
          </div>
        </li>
        </ul>
        <hr />
      </ul>
    </div>
    <hr />
    <div class="col-lg-12 col-md-12">
      <label class="query-label1">Additional Comments/Feedback</label>
      <mat-form-field appearance="outline">
        <mat-label class="shade">Kindly share your info</mat-label>
        <textarea
          matInput
          placeholder="Type Your Feedback here"
          formControlName="addtional_comments"
        ></textarea>
      </mat-form-field>
    </div>
  </div>
</form>
