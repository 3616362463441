<link class="skin" rel="stylesheet" type="text/css" href="/assets/css/skin/skin-4.css" />
<div class="page-wraper">
  <app-header-four></app-header-four>
  <div class="page-content bg-white">
    <app-about-slider></app-about-slider>
    <div class="content-block">
      <app-about-content></app-about-content>
      <app-about-testimonal></app-about-testimonal>
      <app-about-howwework></app-about-howwework>
    </div>
  </div>
  <app-footer-four></app-footer-four>
  <button class="scroltop style2 radius" type="button" (click)="move_top()">
    <i class="fa fa-arrow-up"></i>
  </button>
</div>