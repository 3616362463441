import { Component, OnInit } from '@angular/core';
import {HttpclientService} from '../../../../apiservices/httpclient.service'
import {env} from '../../../../config/config'
@Component({
  selector: 'app-about-labs',
  templateUrl: './about-labs.component.html',
  styleUrls: ['./about-labs.component.scss']
})
export class AboutLabsComponent implements OnInit {
env:any
content:any
flag:boolean=false
  constructor(private service:HttpclientService) { }

  ngOnInit() {
    this.env=env.apiurl
    this.getpage_content()
  }

 getpage_content(){
  this.service.getAllValues('about-us-labs').subscribe(res => {
    this.content=res[0]
       if(this.content.length>0){
      this.flag=true
    }
  })
 }

}
