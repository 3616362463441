<div
  class="section-full content-inner  bg-white"
  id="about-us"
  style="background-image: url(assets/images/pattern/pt13.png);"
>
  <div class="container">
    <div class="row align-items-center" *ngFor="let i of content; index as j">
      <div class="col-lg-6 col-md-12 m-b30 steel-about align-self-start steel-order">
        <div class="row sp10 imagebanner">
          <div class="col-md-12 m-b10">
            <img
              src="{{ env + i.content_image.url }}"
              class="img-cover rounded mx-auto img-fluid"
              alt="service_overview_image-{{j}}"
            />
          </div>
          <!-- <div class="col-md-5 m-b10">
						<div class="steel-year bg-primary">
							<div>
								<h2 class="no-title">22+</h2>
								<h4 class="title">Years Experience Working</h4>
							</div>
						</div>
					</div> -->
          <!-- <div class="col-md-7 m-b10">
						<img src="assets/images/about/steel/pic2.jpg" class="img-cover" alt=""/>
					</div> -->
        </div>
      </div>
      <div class="col-lg-6 m-b15 steel-about">
        <div class="section-head m-b20 text-center">
          <h5 class="title-small">{{ subtitle }}</h5>
          <div class="dlab-separator-outer ">
            <div class="dlab-separator bg-primary style-skew"></div>
          </div>
          <h1 class="title">{{ i.content_title }}</h1>
        </div>
        <p>{{ i.content_desc }}</p>
        <!-- <ul class="list-check white agro-list">
					<li *ngFor = "let item of itemList">
						{{item.list}}
					</li>
				</ul> -->
        <!-- <div class="text-center"> -->
        <a (click)="navigateTo()" class="site-button">
          {{ i.button_text }}
          <i class="fa fa-chevron-right" aria-hidden="true"></i>
          <span class="circle"></span>
        </a>

        <!-- </div> -->
      </div>
    </div>
  </div>
</div>
