<div class="section-full bg-gray content-inner" id="scheme">
  <div class="container">
    <div class="row p-md-b30">
      <div class="section-head col-12 text-left">
        <h2 class="title">Career Paths</h2>
      </div>
    </div>
    <div class="row justify-content-center">
      <div
        class="wow scheme-fade fadeInUp col-md-6 col-lg-6 col-12"
        data-wow-duration="2s"
        data-wow-delay="0.2s"
        *ngFor="let item of content; index as j; last as isLast"
        [ngClass]="{
          'last-item': isLast,
          'prev-item': j === content.length - 2
        }"
      >
        <div class="scheme-inner justify-content-center">
          <div class="position-content">
            <div
              class="justify-content-center align-items-center d-flex circle"
            >
              <div class="scheme-circle">
                <div class="scheme-circle-inner">
                  <img
                    class="object-fit scheme-icon"
                    alt="scheme-icon"
                    src="{{ url + item.scheme_icon.url }}"
                  />
                </div>
              </div>
            </div>
            <div class="scheme-content">
              <div class="scheme-inner-content">
                <h5 class="text-center scheme-title">{{ item.title }}</h5>

                <div class="p-lr40 p-t10 p-b40 text-center">
                  <p class="text-center scheme-text">
                    {{ item.description }}
                  </p>
                  <a
                    *ngIf="item.Button_txt"
                    class="site-button white"
                    (click)="openDialog()"
                    ><span _ngcontent-qtu-c105="" class="circle1"></span>
                    {{ item.Button_txt }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
