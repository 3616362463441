<form class="contact-box dzForm" [formGroup]="Wrap_UpFbForm">
  <div class="section-head  text-left">
    <h2  class="title">Feedback Form III (Wrap-Up Feedback)</h2>
  </div>
<div class="row feedback-form-row">
<div class="col-lg-6 col-md-12">
  <mat-form-field appearance="outline" class="first">
    <mat-label>Name</mat-label>
    <input
      matInput
      type="text"
      placeholder="Your Name"
      formControlName="name"
      maxlength="32"
      required

      (keypress)="onKeyPress($event)"
    />

    <mat-error
      class="error"
      *ngIf="
        Wrap_UpFbForm_submitted && errorControl.name.errors?.required
      "
    >
      Name is required.
    </mat-error>
    <mat-error
      class="error"
      *ngIf="
        Wrap_UpFbForm_submitted && errorControl.name.errors?.pattern
      "
    >
    Must contain only alphabets of minimum 2 characters to maximum 32 characters
    </mat-error>
  </mat-form-field>
</div>

<div class="col-lg-6 col-md-12">
  <mat-form-field appearance="outline">
    <mat-label>Contact Number</mat-label>
    <input
      matInput
      placeholder="Contact Number"
      formControlName="phone"
      required
      onkeypress="return event.charCode >= 48 && event.charCode <= 57 && this.value.length!=10"
    />

    <mat-error
      class="error"
      *ngIf="
        Wrap_UpFbForm_submitted && errorControl.phone.errors?.required
      "
    >
    Contact number is required.
    </mat-error>
    <mat-error
      class="error"
      *ngIf="
        Wrap_UpFbForm_submitted && errorControl.phone.errors?.pattern
      "
    >
      Please enter a valid 10 digit Contact number.
    </mat-error>
  </mat-form-field>
</div>

<div class="col-lg-6 col-md-12">
  <mat-form-field appearance="outline">
    <mat-label>Email Address</mat-label>
    <input
      matInput
      placeholder="Email Address"
      formControlName="email"
      required
    />

    <mat-error
      class="error"
      *ngIf="
        Wrap_UpFbForm_submitted && errorControl.email.errors?.required
      "
    >
    Email address is required.
    </mat-error>
    <mat-error
      class="error"
      *ngIf="
        Wrap_UpFbForm_submitted && errorControl.email.errors?.pattern
      "
    >
      Please enter a valid Email address.
    </mat-error>
  </mat-form-field>
</div>

<hr>
<div class="col-lg-12 col-md-12">
  <label class="query-label1">What do you like most about 6Solve LABs?</label>
  <mat-form-field appearance="outline">
    <mat-label class="shade">Kindly share your info</mat-label>
    <textarea
      matInput
      placeholder="Type Your Feedback here"
      formControlName="about_6Solve"
    ></textarea>
  </mat-form-field>
</div>


<hr>
<div class="col-lg-12 col-md-12">
  <label class="query-label1">What areas do you think need improvement?</label>
  <mat-form-field appearance="outline">
    <mat-label class="shade">Kindly share your info</mat-label>
    <textarea
      matInput
      placeholder="Type Your Feedback here"
      formControlName="need_Improvement"
    ></textarea>
  </mat-form-field>
</div>
<hr>
<div class="col-lg-12 col-md-12">
  <label class="query-label1">Do you have any suggestions for enhancing your educational experience with us?</label>
  <mat-form-field appearance="outline">
    <mat-label class="shade">Kindly share your info</mat-label>
    <textarea
      matInput
      placeholder="Type Your Feedback here"
      formControlName="enhanced_Experience"
    ></textarea>
  </mat-form-field>
</div>
<hr>
<div class="col-lg-12 col-md-12">
  <label class="query-label1"> Additional Comments/Feedback:</label>
  <mat-form-field appearance="outline">
    <mat-label class="shade">Kindly share your info</mat-label>
    <textarea
      matInput
      placeholder="Type Your Feedback here"
      formControlName="additional_Comments"
    ></textarea>
  </mat-form-field>
</div>

<hr>
<div class="col-lg-12 col-md-12">
<mat-radio-group aria-label="Would you recommend 6Solve LABs to others?" formControlName="recommend_6Solve_LABs">
  <div class="m-b10">
    <mat-label class="query-label1"> Would you recommend 6Solve LABs to others? *</mat-label>
  </div>
  <div class="mat-radio-cta">
  <mat-radio-button [value]="i"  *ngFor="let i of recommend_6Solve_LABs_data">{{i}}</mat-radio-button>

  </div>
</mat-radio-group>

<span
class="error"
*ngIf="
Wrap_UpFbForm_submitted && errorControl.recommend_6Solve_LABs.errors?.required
"
>
This field is required.
</span>
</div>

</div>
</form>
