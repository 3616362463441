// module.exports=
// {
//     apiurl:"localhost:4000"
// }
// export const env = {
//   apiurl: 'https://stg-v1.6solve.com/api',
// };
// export const env = {
//   apiurl: 'http://192.168.194.229:4000',
// };
// export const env = {
//   apiurl: 'http://localhost:4000',
// };
export const env = {
  apiurl: 'https://6solve.com/api',
};
