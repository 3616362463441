<div class="clear" id="comment-list">
	<div class="comments-area" id="comments">
		<h2 class="comments-title">8 Comments</h2>
		<div class="clearfix">
			<ol class="comment-list">
				<li class="comment">
					<div class="comment-body">
						<div class="comment-author vcard"> <img  class="avatar photo" src="assets/images/testimonials/pic1.jpg" alt=""> <cite class="fn">Oliver</cite> <span class="says">says:</span> </div>
						<div class="comment-meta"> <a href="javascript:void(0);">October 6, 2015 at 7:15 am</a> </div>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neqnsectetur adipiscing elit. Nam viae neqnsectetur adipiscing elit.
							Nam vitae neque vitae sapien malesuada aliquet. </p>
						<div class="reply"> <a href="javascript:void(0);" class="comment-reply-link">Reply</a> </div>
					</div>
					<ol class="children">
						<li class="comment odd parent">
							<div class="comment-body">
								<div class="comment-author vcard"> <img  class="avatar photo" src="assets/images/testimonials/pic2.jpg" alt=""> <cite class="fn">Harry</cite> <span class="says">says:</span> </div>
								<div class="comment-meta"> <a href="javascript:void(0);">October 6, 2015 at 7:15 am</a> </div>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neque vitae sapien malesuada aliquet. 
									In viverra dictum justo in vehicula. Fusce et massa eu ante ornare molestie. Sed vestibulum sem felis, 
									ac elementum ligula blandit ac.</p>
								<div class="reply"> <a href="javascript:void(0);" class="comment-reply-link">Reply</a> </div>
							</div>
							<ol class="children">
								<li class="comment odd parent">
									<div class="comment-body">
										<div class="comment-author vcard"> <img  class="avatar photo" src="assets/images/testimonials/pic3.jpg" alt=""> <cite class="fn">Thomas</cite> <span class="says">says:</span> </div>
										<div class="comment-meta"> <a href="javascript:void(0);">October 6, 2015 at 7:15 am</a> </div>
										<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neque vitae sapien malesuada aliquet. 
											In viverra dictum justo in vehicula. Fusce et massa eu ante ornare molestie. Sed vestibulum sem felis, 
											ac elementum ligula blandit ac.</p>
										<div class="reply"> <a href="javascript:void(0);" class="comment-reply-link">Reply</a> </div>
									</div>
								</li>
							</ol>
						</li>
					</ol>				
				</li>
				<li class="comment">
					<div class="comment-body">
						<div class="comment-author vcard"> <img  class="avatar photo" src="assets/images/testimonials/pic1.jpg" alt=""> <cite class="fn">Stacy poe</cite> <span class="says">says:</span> </div>
						<div class="comment-meta"> <a href="javascript:void(0);">October 6, 2015 at 7:15 am</a> </div>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neque vitae sapien malesuada aliquet. 
							In viverra dictum justo in vehicula. Fusce et massa eu ante ornare molestie. Sed vestibulum sem felis, 
							ac elementum ligula blandit ac.</p>
						<div class="reply"> <a href="javascript:void(0);" class="comment-reply-link">Reply</a> </div>
					</div>
				</li>
				<li class="comment">
					<div class="comment-body">
						<div class="comment-author vcard"> <img  class="avatar photo" src="assets/images/testimonials/pic2.jpg" alt=""> <cite class="fn">Jamie</cite> <span class="says">says:</span> </div>
						<div class="comment-meta"> <a href="javascript:void(0);">October 6, 2015 at 7:15 am</a> </div>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neque vitae sapien malesuada aliquet. 
							In viverra dictum justo in vehicula. Fusce et massa eu ante ornare molestie. Sed vestibulum sem felis, 
							ac elementum ligula blandit ac.</p>
						<div class="reply"> <a href="javascript:void(0);" class="comment-reply-link">Reply</a> </div>
					</div>
				</li>
				<li class="comment">
					<div class="comment-body">
						<div class="comment-author vcard"> <img  class="avatar photo" src="assets/images/testimonials/pic3.jpg" alt=""> <cite class="fn">Anna</cite> <span class="says">says:</span> </div>
						<div class="comment-meta"> <a href="javascript:void(0);">October 6, 2015 at 7:15 am</a> </div>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neque vitae sapien malesuada aliquet. 
							In viverra dictum justo in vehicula. Fusce et massa eu ante ornare molestie. Sed vestibulum sem felis, 
							ac elementum ligula blandit ac.</p>
						<div class="reply"> <a href="javascript:void(0);" class="comment-reply-link">Reply</a> </div>
					</div>
				</li>
			</ol>
			<div class="comment-respond" id="respond">
				<h4 class="comment-reply-title" id="reply-title">Leave a Reply <small> <a style="display:none;" href="javascript:void(0);" id="cancel-comment-reply-link" rel="nofollow">Cancel reply</a> </small> </h4>
				<form class="comment-form" id="commentform" method="post" action="http://sedatelab.com/developer/donate/wp-comments-post.php">
					<p class="comment-form-author">
						<label for="author">Name <span class="required">*</span></label>
						<input type="text" value="" name="author"  placeholder="Author" id="author">
					</p>
					<p class="comment-form-email">
						<label for="email">Email <span class="required">*</span></label>
						<input type="text" value="" placeholder="Email" name="" id="email">
					</p>
					<p class="comment-form-url">
						<label for="url">Website</label>
						<input type="text"  value=""  placeholder="Website"  name="" id="url">
					</p>
					<p class="comment-form-comment">
						<label for="comment">Comment</label>
						<textarea rows="8" name="comment" placeholder="Comment" id="comment"></textarea>
					</p>
					<p class="form-submit">
						<button name="submit" value="Submit" type="submit" class="site-button radius-no button-style-2 primary">
							<span>SUBMIT</span>
							<i class="la la-long-arrow-alt-right"></i>
						</button>
					</p>
				</form>
			</div>
		</div>
	</div>
</div>
