import { Component, OnInit } from '@angular/core';
import { HttpclientService } from '../../../../apiservices/httpclient.service';
import { env } from '../../../../config/config';
import { HistoryService } from 'src/app/apiservices/history.service';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterEvent,
} from '@angular/router';

@Component({
  selector: 'app-service-four',
  templateUrl: './service-four.component.html',
  styleUrls: ['./service-four.component.css'],
})
export class ServiceFourComponent implements OnInit {
  serviceList;
  flag = false;
  url;
  set_flag = false;
  height_arr: any = [];
  max_height = 118;


  constructor(
    private service: HttpclientService,
    private history: HistoryService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.url = env.apiurl;

    this.service.getAllValues('services').subscribe((res) => {
      this.serviceList = res;
      if (this.serviceList.length > 0) {
        this.flag = true;
      }
      console.log(this.url + this.serviceList[0].service_image.url);
    });
  }

  service_detail(e) {
    console.log(e);
    this.history.set_services(e);
    this.router.navigateByUrl(`/services/${e.service_slug}`);
  }

  set_height(elmt) {
    let elements;
    this.height_arr.push(elmt.offsetHeight);

    this.height_arr.sort(function (a, b) {
      return b - a;
    });

    if (this.flag) {

      elements = document.querySelectorAll('#service');
      for (let i in elements) {

        if (elements[i].style) {
          if (parseInt(this.serviceList.length) * 2 == this.height_arr.length) {
            elements[i].style.height = this.height_arr[0] + 'px';
          }
        }

      }



    }
  }
}
