<div class="section-full content-service bg-gray">
  <div class="container">
    <div class="section-head text-center">
      <h1 class="title-small">OUR SERVICES</h1>
      <div class="dlab-separator-outer">
        <div class="dlab-separator bg-primary style-skew"></div>
      </div>
     
    </div>

    <div class="row">
      <div class="col-lg-12 pt-lg-3 pb-lg-3 topslide">
        <owl-carousel-o
          [options]="customOptions"
          #owlcarousel
          id="owlcarousel"
          class="testimonial-box-carousel2 owl-dots-black-full owl-theme owl-btn-center-lr owl-btn-3 btn-white"
        >
          <ng-template carouselSlide *ngFor="let slide of service_slide">
            <a
              class="text-decoration-none text-center slider-anchor"
              (click)="get_service(slide, $event)"
            >
              <p
                class=" text-white-75 m-0 slider-content {{
                  current_title == slide.title ? 'active' : ''
                }}"
              >
                {{ slide.title }}
              </p>
              <span class="circle"></span>
          
            </a>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
    <h1></h1>
    <div
      class="row align-items-center serviceorder m-b30 m-t30"
      *ngFor="let item of servicelist.sub_services; index as j"
    >
      <div
        class="col-lg-6 col-md-12 steel-about service-order align-self-start"
      >
        <img
          src="{{ url + item.image.url }}"
          class="service-image control-width img-fluid rounded"
          alt="servicedetail_ourservice_image-{{j}}"
        />
      </div>
      <div class="col-lg-6 steel-about servicecontent">
        <div class="section-head m-b20 text-center">
          <h5 class="title-small">{{ item.subtitle }}</h5>

          <h2 class="title">{{ item.title }}</h2>
        </div>
        <p>{{ item.long_desc }}</p>
      </div>
    </div>
    <div class="row serviceicon-row" flag="flag">
      <div
        class="col-md-1 col-1 service-icon"
        *ngFor="let item of servicelist.tech_logo"
      >
        <img src="{{ url + item.url }}" alt="icon" class="icon" />
      </div>
    </div>
  </div>
</div>
