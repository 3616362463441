
<div class="dlab-bnr-inr overlay-black-middle bg-pt" style="background-image:url(assets/images/banner/{{data.bg_image}}); background-position:center;">
	<div class="container">
		<div class="dlab-bnr-inr-entry">
			<h1 class="text-white"> {{data.pagetitle}}</h1>
			
			<div class="breadcrumb-row">
				<ul class="list-inline">
					<li><a routerLink="/">Home</a></li>
					<li>{{data.title}}</li>
				</ul>
			</div>
		
		</div>
	</div>
</div>

