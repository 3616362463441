<div class="container form-container">
  <div mat-dialog-title class="logo-section m-b30 text-left">
    <div class="d-flex justify-content-between">
      <div class="logo-div">
        <img src="{{ logo }}" class="object-fit company-logo" />
      </div>

      <i class="fa fa-close" mat-dialog-close></i>
    </div>
  </div>

  <mat-dialog-content class="mat-typography dialog-content">
    <mat-tab-group (selectedIndexChange)="onTabChanged($event)">
      <mat-tab label="Feedback Form I (General Feedback)">
        <app-feeback-form1></app-feeback-form1>
      </mat-tab>
      <mat-tab label="Feedback Form II (Onboarding Feedback)">
        <app-feedback-form2></app-feedback-form2>
      </mat-tab>
      <mat-tab label="Feedback Form III (Wrap-Up Feedback) ">
        <app-feedback-form3></app-feedback-form3>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
  <mat-dialog-actions align="end" class="dialog-action">
    <div class="d-flex">
      <a class="site-button white" mat-dialog-close
        >Close<span class="circle1"></span
      ></a>
      <a
        name="submit"
        type="submit"
        value="Submit"
        class="site-button white"
        (click)="
          selectedTab == 0
            ? generalFb()
            : selectedTab == 1
            ? onboardingFb()
            : wrap_UpFb()
        "
      >
        Submit<span class="circle1"></span>
      </a>
    </div>
  </mat-dialog-actions>
</div>
