
<div class="container form-container">
    <div class="row">
      <div class="col-lg-12 col-12 col-md-12 m-b30">

          <div mat-dialog-title class="logo-section m-b30 text-left">

                <div class="d-flex justify-content-between">
                  <div class="logo-div">
                    <img src="{{logo}}" class="object-fit company-logo" />
                  </div>

                  <i class="fa fa-close" mat-dialog-close></i>
                </div>
          </div>



          <mat-dialog-content class="dialog-content">

            <form class="contact-box dzForm" [formGroup]="enquiryForm">
              <div class="section-head  text-left">
                <h2  class="title">Enquiry form</h2>
              </div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <mat-form-field appearance="outline" class="first">
                <mat-label>Name</mat-label>
                <input
                  matInput
                  type="text"
                  placeholder="Your Name"
                  formControlName="name"
                  maxlength="32"
                  required
                  [(ngModel)]="inputValue"
                  (keypress)="onKeyPress($event)"
                />

                <mat-error
                  class="error"
                  *ngIf="
                    enquiry_submitted && errorControl.name.errors?.required
                  "
                >
                  Name is required.
                </mat-error>
                <mat-error
                  class="error"
                  *ngIf="
                    enquiry_submitted && errorControl.name.errors?.pattern
                  "
                >
                Must contain only alphabets of minimum 2 characters to maximum 32 characters
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-6 col-md-12">
              <mat-form-field appearance="outline">
                <mat-label>Contact Number</mat-label>
                <input
                  matInput
                  placeholder="Contact Number"
                  formControlName="phone"
                  required
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57 && this.value.length!=10"
                />

                <mat-error
                  class="error"
                  *ngIf="
                    enquiry_submitted && errorControl.phone.errors?.required
                  "
                >
                Contact number is required.
                </mat-error>
                <mat-error
                  class="error"
                  *ngIf="
                    enquiry_submitted && errorControl.phone.errors?.pattern
                  "
                >
                  Please enter a valid 10 digit Contact number.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-6 col-md-12">
              <mat-form-field appearance="outline">
                <mat-label>Email Address</mat-label>
                <input
                  matInput
                  placeholder="Email Address"
                  formControlName="email"
                  required
                />

                <mat-error
                  class="error"
                  *ngIf="
                    enquiry_submitted && errorControl.email.errors?.required
                  "
                >
                Email address is required.
                </mat-error>
                <mat-error
                  class="error"
                  *ngIf="
                    enquiry_submitted && errorControl.email.errors?.pattern
                  "
                >
                  Please enter a valid Email address.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-6 col-md-12">
              <mat-form-field appearance="outline">
                <mat-label>Address *</mat-label>
                <input
                  matInput
                  placeholder="Type Your Address here"
                  formControlName="address"
                >
                <mat-error
                class="error"
                *ngIf="
                  enquiry_submitted &&
                  errorControl.address.errors?.required
                "
              >
              Address is required.
              </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-6 col-md-12">
              <mat-form-field appearance="outline">
                <mat-label>Highest Educational Qualifications *</mat-label>
                <mat-select formControlName="qualification">
                  <mat-option
                    [value]="i"
                    *ngFor="let i of qualification"
                  >
                    {{ i }}
                  </mat-option>
                </mat-select>
                <mat-error
                  class="error"
                  *ngIf="
                    enquiry_submitted &&
                    errorControl.qualification.errors?.required
                  "
                >
                Educational qualification is required.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-6 col-md-12">
              <mat-form-field appearance="outline">
                <mat-label>Preferred Joining Date</mat-label>
                  <input id="datepicker" matInput  readonly="true"  [matDatepicker]="datepicker" formControlName="intended_date" required="true"
                  (click)="datepicker.open()"
                  (focus)="datepicker.open()"
                  (keydown)="datepicker.open()">
                  <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
                  <mat-datepicker #datepicker></mat-datepicker>
                <mat-error
                  class="error"
                  *ngIf="
                    enquiry_submitted && errorControl.intended_date.errors?.required
                  "
                >
                Preferred joining date is required.
                </mat-error>
                <!-- <mat-error
                  class="error"
                  *ngIf="
                    enquiry_submitted && errorControl.intended_date.errors?.pattern
                  "
                >
                  Please enter a valid 10 digit mobile number.
                </mat-error> -->
              </mat-form-field>
            </div>

            <div class="col-lg-6 col-md-12">
              <mat-form-field appearance="outline">
                <mat-label>Preferred Career Stream *</mat-label>
                <mat-select formControlName="Preferredcareer">
                  <mat-option
                    [value]="i.title"
                    *ngFor="let i of preferences"
                  >
                    {{ i.title }}
                  </mat-option>
                </mat-select>
                <mat-error
                  class="error"
                  *ngIf="
                    enquiry_submitted &&
                    errorControl.Preferredcareer.errors?.required
                  "
                >
                  Career stream is required.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-6 col-md-12">
              <mat-form-field appearance="outline">
                <mat-label>Preferred Career Path *</mat-label>
                <mat-select formControlName="Preferredpath">
                  <mat-option
                    [value]="i.title"
                    *ngFor="let i of preferred_path"
                  >
                    {{ i.title }}
                  </mat-option>
                </mat-select>
                <mat-error
                  class="error"
                  *ngIf="
                    enquiry_submitted &&
                    errorControl.Preferredpath.errors?.required
                  "
                >
                  Career Path is required.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-6 col-md-12">
              <mat-radio-group aria-label="What specific information are you looking for?" formControlName="Specific_information" (change)="changedetect('specific')">
                <div class="m-b10">
                  <mat-label class="query-label">What specific information are you looking for?</mat-label>
                </div>
                <div class="mat-radio-cta">
                <mat-radio-button [value]="i"  *ngFor="let i of Specific_information">{{i}}</mat-radio-button>
                <mat-radio-button value="Others">Others</mat-radio-button>
                </div>
              </mat-radio-group>


              <mat-form-field appearance="outline"  *ngIf="flag_other_specify">
                <mat-label>Please specify</mat-label>
              <input

              matInput
              type="text"
              placeholder="Please specify"
              formControlName="other_specific"
              maxlength="32"
              required
            />

            <mat-error
              class="error"
              *ngIf="
                enquiry_submitted && errorControl.other_specific.errors?.required
              "
            >
              Kindly provide you specification.
            </mat-error>
           </mat-form-field>
            </div>

            <div class="col-lg-6 col-md-12">
              <mat-radio-group aria-label="How did you hear about us?" formControlName="Heared_about_us" (change)="changedetect('about')">
                <div class="m-b10">
                <mat-label  class="query-label">How did you hear about us?</mat-label>
                </div>
                <div class="mat-radio-cta">
                  <mat-radio-button [value]="i"  *ngFor="let i of Heared_about_us">{{i}}</mat-radio-button>
                  <mat-radio-button value="Others">Others</mat-radio-button>
                </div>

              </mat-radio-group>
              <mat-form-field appearance="outline"  *ngIf="flag_other_about">
                <mat-label>Please specify</mat-label>
              <input

              matInput
              type="text"
              placeholder="Please specify"
              formControlName="other_about"
              maxlength="32"
              required
            />

            <mat-error
              class="error"
              *ngIf="
                enquiry_submitted && errorControl.other_about.errors?.required
              "
            >
              Kindly provide you specification.
            </mat-error>
           </mat-form-field>
            </div>

            <div class="col-lg-12 col-md-12">
              <mat-form-field appearance="outline">
                <mat-label>Message</mat-label>
                <textarea
                  matInput
                  placeholder="Type Your Message here"
                  formControlName="message"
                ></textarea>
              </mat-form-field>
            </div>
            <div class="col-lg-12 col-md-12">
              <div> <mat-label class="query-label "  for="resume" >Upload your resume :</mat-label></div>

              <div class="m-t15">
                <input
                type="file"
                class="uploads"
                id="resume"
                placeholder="Resume"
                formControlName="Resume"
                (change)="onFileSelected($event,'Resume')"
                [(ngModel)]="resume"
                name="resume"
              /></div>

              </div>

          </div>
        </form>
        </mat-dialog-content>

        <mat-dialog-actions align="end" class="dialog-action">
          <div class="d-flex">
          <a class="site-button white " mat-dialog-close>Close<span  class="circle1"></span></a>
               <a
                    name="submit"
                    type="submit"
                    value="Submit"
                    class="site-button white "
                    (click)="enquiries()"
                  >
                    Submit<span  class="circle1"></span>
                  </a>
                </div>
        </mat-dialog-actions>

      </div>
    </div>
  </div>

