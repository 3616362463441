import { Component, OnInit, ViewChild ,Inject} from '@angular/core';
import { HttpclientService } from '../../../../apiservices/httpclient.service';
import { env } from '../../../../config/config';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import {FeebackForm1Component} from './feeback-form1/feeback-form1.component'
import {FeedbackForm2Component} from './feedback-form2/feedback-form2.component'
import {FeedbackForm3Component} from './feedback-form3/feedback-form3.component'
import { addListener } from 'process';
import { EventEmitter } from 'stream';
@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.css']
})
export class FeedbackFormComponent implements OnInit {
  logo:any
 selectedTab:any=0
 @ViewChild(FeebackForm1Component) FeebackForm1Component: FeebackForm1Component;
 @ViewChild(FeedbackForm2Component) FeebackForm2Component: FeedbackForm2Component;
 @ViewChild(FeedbackForm3Component) FeedbackForm3Component: FeedbackForm3Component;
  constructor(private service: HttpclientService, public dialog: MatDialog) { }

  ngOnInit() {


    this.logo=env.apiurl +  localStorage.getItem('company_logo')
  }
  onTabChanged(event: MatTabChangeEvent): void {

    this.selectedTab=event
  }


  generalFb(){
 this.FeebackForm1Component.generalfeedback()
console.log('tab1 called')
  }

  onboardingFb(){
    this.FeebackForm2Component.onboardfeedback()
    console.log('tab2 called')
  }

  wrap_UpFb(){
    this.FeedbackForm3Component.wrap_upfeedback()
    console.log('tab3 called')
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  openDialog(data:any) {

    this.dialog.open(DialogElementsExampleDialog, {
      width: '30rem',
      height: '11rem',
      data:{
      formtype:data
      }
    }
    );
  }
}

@Component({
  selector: 'dialog-elements-example-dialog',
  template: `
    <div class="icon">
      <i class="fa fa-check-circle" aria-hidden="true"></i>
    </div>
    <div mat-dialog-content style="text-align: center;
    margin: 1rem;">{{data.formtype}} form has been submitted successfully.</div>
    <div mat-dialog-actions></div>`,
  styles: [
    '.fa-check-circle{color:green; font-size:50px;}.icon,.mat-dialog-content{text-align:center}',
  ],
})
export class DialogElementsExampleDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    console.log('Received data:', data);
  }
}
