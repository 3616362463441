
<div class="section-full bg-gray content-inner-2 overlay-primary-dark" id="client" style="background-image:url(assets/images/pattern/pt-img.jpg);">
	<div class="container">
		<div class="row">
			<div class="col-lg-12 section-head text-center text-white">
				<h5 class="title-small">HOW WE PERFORMED</h5>
				<div class="dlab-separator-outer">
					<div class="dlab-separator bg-white style-skew"></div>
				</div>
				<h2 class="title">Words From Satisfied Customers</h2>
			</div>
			<div class="col-lg-12">
				<div class="section-content">
					<owl-carousel-o [options]="customOptions" class="testimonial-three owl-loaded owl-theme owl-carousel owl-btn-3 btn-white owl-btn-center-lr">
						<ng-template carouselSlide *ngFor="let slide of slideStore">
							<div class="slide">
								<div class="testimonial-15 quote-right">
									<div class="testimonial-text">
										<p>{{slide.description}}</p>
									</div>
									<div class="testimonial-detail clearfix">
										<div class="testimonial-pic radius"><img src="assets/images/testimonials/{{slide.image}}" width="100" height="100" alt=""></div>
										<strong class="testimonial-name">{{slide.name}}</strong> <span class="testimonial-position">{{slide.position}}</span> 
									</div>
								</div>
							</div>
						</ng-template>
					</owl-carousel-o>					
				</div>
			</div>
		</div>
	</div>
</div>

