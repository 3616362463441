import { Component, OnInit } from '@angular/core';
import {HttpclientService} from '../../../../apiservices/httpclient.service'
import {env} from '../../../../config/config'
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-about-service',
  templateUrl: './about-service.component.html',
  styleUrls: ['./about-service.component.css']
})
export class AboutServiceComponent implements OnInit {

  content
	flag=false
  env
  subtitle= "OVERVIEW";
  title= "Enabling a dynamic future";
  description= "We are an IT solution provider based out of South India.  We are an 100% agile company providing IT solutions using new age technologies. We are successful in providing cutting  edge solution for business problems using our 6 core themes.";

  itemList: any[] = [
    {list: "This Is How Steel Industry Will Look Like In 10 Years Time."},
    {list: "The Rank Of Steel Industry In Consumer's Market."},
    {list: "You Will Never Believe These Bizarre Truth Of Steel Industry."},
  ]

  constructor(private service:HttpclientService,
    private router: Router,
    private snap_route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.env=env.apiurl
    this.service.getAllValues('service-page-2nd-sections').subscribe(res => {
      this.content=res
      if(this.content.length>0){
        this.flag=true
      }
    })
  }
  scrollTo(section) {
    document.querySelector('#' + section)
    .scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

navigateTo(){
  this.router.navigate(['/contact-us']).then(() => {
    // Ensure that the element is available

    setTimeout(()=>{
      this.scrollTo('contactus');
    },500);

  });
}

}
