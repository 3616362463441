<div class="section-full text-white counter-staus-box bg-black" id="stats">
	<div class="row spno">
		<div class="col-lg-3 col-md-6 col-sm-12 col-12 wow fadeInLeft d-flex align-items-center" style="background-image:url(assets/images/about/steel/pic3.jpg)" data-wow-duration="2s" data-wow-delay="0.2s">
			<div class="section-head text-white m-b0 p-a30">
				<h2 class="title">We're Thriving And Building Better Products</h2>
			</div>
		</div>
		<div class="col-lg-3 col-md-6 col-sm-4 col-6 wow fadeInUp bg-primary d-flex align-items-center" data-wow-duration="2s" data-wow-delay="0.2s">
			<div class="icon-bx-wraper center counter-style-5">
				<div class="icon-xl m-b20">
					<span class="icon-cell"><i class="flaticon-worker"></i></span>
				</div>
				<div class="icon-content">
					<div class="dlab-separator bg-white"></div>
					<h2 class="dlab-tilte counter">1226</h2>
					<p>Happy Client</p>
				</div>
			</div>
		</div>
		<div class="col-lg-3 col-md-6 col-sm-4 col-6 wow fadeInUp bg-secondry d-flex align-items-center" data-wow-duration="2s" data-wow-delay="0.4s">
			<div class="icon-bx-wraper center counter-style-5">
				<div class="icon-xl m-b20">
					<span class="icon-cell"><i class="flaticon-settings"></i></span>
				</div>
				<div class="icon-content">
					<div class="dlab-separator bg-primary"></div>
					<h2 class="dlab-tilte counter">1552</h2>
					<p>Workers Hand</p>
				</div>
			</div>
		</div>
		<div class="col-lg-3 col-md-6 col-sm-4 col-12 wow fadeInUp bg-primary d-flex align-items-center" data-wow-duration="2s" data-wow-delay="0.6s">
			<div class="icon-bx-wraper center counter-style-5">
				<div class="icon-xl m-b20">
					<span class="icon-cell"><i class="flaticon-conveyor-1"></i></span>
				</div>
				<div class="icon-content">
					<div class="dlab-separator bg-white"></div>
					<h2 class="dlab-tilte counter">1156</h2>
					<p>Active Experts</p>
				</div>
			</div>
		</div>
	</div>
</div>