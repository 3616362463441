<div
  class="section-full content-inner  bg-white"
  id="about-us"
  style="background-image: url(assets/images/pattern/pt13.png);"
>
  <div class="container">
    <div class="section-head m-b20 text-left">
      <!-- <h5 class="title-small">{{ subtitle }}</h5> -->

      <h1 class="title">{{ content.Section_title }}</h1>
    </div>
    <div class="row align-items-center">
      <div class="col-lg-4 col-md-12 p-r40 m-b30 steel-about align-self-start steel-order d-none d-lg-block">
        <div class="row sp10 imagebanner">
          <div class="col-md-12 m-b10 ">
            <img
              src="{{ env + content.Image.url }}"
              class=" about-image mx-auto img-fluid"
              alt="labs_side_image"
            />
          </div>
          <!-- <div class="col-md-5 m-b10">
						<div class="steel-year bg-primary">
							<div>
								<h2 class="no-title">22+</h2>
								<h4 class="title">Years Experience Working</h4>
							</div>
						</div>
					</div> -->
          <!-- <div class="col-md-7 m-b10">
						<img src="assets/images/about/steel/pic2.jpg" class="img-cover" alt=""/>
					</div> -->
        </div>
      </div>
      <div class="col-lg-8 m-b15  steel-about wow fadeInUp"  data-wow-duration="2s"
      data-wow-delay="0.3s">

        <div class=descrip [innerHTML]="content.Description">

        </div>

        <!-- <ul class="list-check white agro-list">
					<li *ngFor = "let item of itemList">
						{{item.list}}
					</li>
				</ul> -->
        <!-- <div class="text-center"> -->
        <!-- <a href="/contact-us" class="site-button">
          {{ i.button_text }}
          <i class="fa fa-chevron-right" aria-hidden="true"></i>
          <span class="circle"></span>
        </a> -->

        <!-- </div> -->
      </div>
    </div>
  </div>
</div>
