import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { HeaderOneComponent } from './components/header/header-one/header-one.component';
import { HeaderTwoComponent } from './components/header/header-two/header-two.component';
import { HeaderThreeComponent } from './components/header/header-three/header-three.component';
import { HeaderFourComponent } from './components/header/header-four/header-four.component';
import { FooterComponent } from './components/footer/footer.component';
import { FooterOneComponent } from './components/footer/footer-one/footer-one.component';
import { FooterTwoComponent } from './components/footer/footer-two/footer-two.component';
import { FooterThreeComponent } from './components/footer/footer-three/footer-three.component';
import { FooterFourComponent } from './components/footer/footer-four/footer-four.component';
import { ServiceComponent } from './components/service/service.component';
import { TestimonialsComponent } from './components/testimonials/testimonials.component';
import { AboutComponent } from './components/about/about.component';
import { SlidersComponent } from './components/sliders/sliders.component';
import { TeamComponent } from './components/team/team.component';
import { BlogComponent } from './components/blog/blog.component';
import { WorkComponent } from './components/work/work.component';
import { ContactComponent } from './components/contact/contact.component';
import { CommentsComponent } from './components/comments/comments.component';
import { WidgetsComponent } from './components/widgets/widgets.component';
import { CallToActionComponent } from './components/call-to-action/call-to-action.component';
import { SearchComponent } from './components/widgets/search/search.component';
import { RecentPostComponent } from './components/widgets/recent-post/recent-post.component';
import { NewsletterComponent } from './components/widgets/newsletter/newsletter.component';
import { CategoryListComponent } from './components/widgets/category-list/category-list.component';
import { ArchivesComponent } from './components/widgets/archives/archives.component';
import { ServiceOneComponent } from './components/service/service-one/service-one.component';
import { ServiceTwoComponent } from './components/service/service-two/service-two.component';
import { ServiceThreeComponent } from './components/service/service-three/service-three.component';
import { ServiceFourComponent } from './components/service/service-four/service-four.component';
import { AboutOneComponent } from './components/about/about-one/about-one.component';
import { AboutTwoComponent } from './components/about/about-two/about-two.component';
import { AboutThreeComponent } from './components/about/about-three/about-three.component';
import { AboutFourComponent } from './components/about/about-four/about-four.component';
import { AboutFiveComponent } from './components/about/about-five/about-five.component';
import { BlogOneComponent } from './components/blog/blog-one/blog-one.component';
import { BlogTwoComponent } from './components/blog/blog-two/blog-two.component';
import { BlogThreeComponent } from './components/blog/blog-three/blog-three.component';
import { SliderOneComponent } from './components/sliders/slider-one/slider-one.component';
import { SliderTwoComponent } from './components/sliders/slider-two/slider-two.component';
import { SliderThreeComponent } from './components/sliders/slider-three/slider-three.component';
import { SliderFourComponent } from './components/sliders/slider-four/slider-four.component';
import { SliderLabsComponent } from './components/sliders/slider-four/slider-labs/slider-labs.component';
import { TeamOneComponent } from './components/team/team-one/team-one.component';
import { TeamTwoComponent } from './components/team/team-two/team-two.component';
import { TeamThreeComponent } from './components/team/team-three/team-three.component';
import { TeamFourComponent } from './components/team/team-four/team-four.component';
import { TestimonialOneComponent } from './components/testimonials/testimonial-one/testimonial-one.component';
import { TestimonialTwoComponent } from './components/testimonials/testimonial-two/testimonial-two.component';
import { TestimonialThreeComponent } from './components/testimonials/testimonial-three/testimonial-three.component';
import { TestimonialFourComponent } from './components/testimonials/testimonial-four/testimonial-four.component';
import { HeaderFiveComponent } from './components/header/header-five/header-five.component';
import { WorkOneComponent } from './components/work/work-one/work-one.component';
import { WorkTwoComponent } from './components/work/work-two/work-two.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CounterComponent } from './components/counter/counter.component';
import { CounterOneComponent } from './components/counter/counter-one/counter-one.component';
import { ContactFormComponent } from './components/contact/contact-form/contact-form.component';
import { ContactMapComponent } from './components/contact/contact-map/contact-map.component';
import { WorkThreeComponent } from './components/work/work-three/work-three.component';
import { TestimonialFiveComponent } from './components/testimonials/testimonial-five/testimonial-five.component';
import { FooterFiveComponent } from './components/footer/footer-five/footer-five.component';
import { PageHeaderComponent } from './components/header/page-header/page-header.component';
import { PageHeaderOneComponent } from './components/header/page-header/page-header-one/page-header-one.component';
import { ListItemsComponent } from './components/widgets/list-items/list-items.component';
import { TeamFiveComponent } from './components/team/team-five/team-five.component';
import { CallActionOneComponent } from './components/call-to-action/call-action-one/call-action-one.component';
import { CallActionTwoComponent } from './components/call-to-action/call-action-two/call-action-two.component';
import { SliderFiveComponent } from './components/sliders/slider-five/slider-five.component';
import { BlogFourComponent } from './components/blog/blog-four/blog-four.component';
import { ServicesliderComponent } from './components/sliders/serviceslider/serviceslider.component';
import { AboutServiceComponent } from './components/about/about-service/about-service.component';
import { ServiceSectionComponent } from './components/service/service-section/service-section.component';
import { ServiceDetailSliderComponent } from './components/sliders/service-detail-slider/service-detail-slider.component';
import { ServiceDetailsecComponent } from './components/service/service-detailsec/service-detailsec.component';
import { AboutContentComponent } from './components/about/about-content/about-content.component';
import { AboutSliderComponent } from './components/sliders/about-slider/about-slider.component';
import { AboutTestimonalComponent } from './components/testimonials/about-testimonal/about-testimonal.component';
import { AboutHowweworkComponent } from './components/work/about-howwework/about-howwework.component';
import { CareersliderComponent } from './components/sliders/careerslider/careerslider.component';
import { CareercontentComponent } from './components/careercontent/careercontent.component';
import { SliderWhitepaperComponent } from './components/sliders/slider-whitepaper/slider-whitepaper.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { MatFormFieldAnimations } from '@angular/material/form-field';
// import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFileUploadModule } from 'angular-material-fileupload';
import { MatDialogModule } from '@angular/material/dialog';
import { ContactsliderComponent } from './components/sliders/contactslider/contactslider.component';
import { AboutLabsComponent } from './components/about/about-labs/about-labs.component';
import { ServiceLabsComponent } from './components/service/service-labs/service-labs.component';
import { TeamLabsComponent } from './components/team/team-labs/team-labs.component';
import { SchemeComponent } from './components/scheme/scheme.component';
import { EnquiryFormComponent } from './components/contact/enquiry-form/enquiry-form.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FeedbackFormComponent } from './components/contact/feedback-form/feedback-form.component';
import { ApplyOnlineComponent } from './components/contact/apply-online/apply-online.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {
  MAT_RADIO_DEFAULT_OPTIONS,
  MatRadioModule,
} from '@angular/material/radio';
import { EnrollmentFormComponent } from './components/contact/enrollment-form/enrollment-form.component';
import { FeebackForm1Component } from './components/contact/feedback-form/feeback-form1/feeback-form1.component';
import { FeedbackForm2Component } from './components/contact/feedback-form/feedback-form2/feedback-form2.component';
import { FeedbackForm3Component } from './components/contact/feedback-form/feedback-form3/feedback-form3.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCardModule } from '@angular/material/card';
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [
    //ComponentsComponent,
    HeaderComponent,
    HeaderOneComponent,
    HeaderTwoComponent,
    HeaderThreeComponent,
    HeaderFourComponent,
    FooterComponent,
    FooterOneComponent,
    FooterTwoComponent,
    FooterThreeComponent,
    FooterFourComponent,
    ServiceComponent,
    TestimonialsComponent,
    AboutComponent,
    SlidersComponent,
    TeamComponent,
    BlogComponent,
    WorkComponent,
    ContactComponent,
    CommentsComponent,
    WidgetsComponent,
    CallToActionComponent,
    SearchComponent,
    RecentPostComponent,
    NewsletterComponent,
    CategoryListComponent,
    ArchivesComponent,
    ServiceOneComponent,
    ServiceTwoComponent,
    ServiceThreeComponent,
    ServiceFourComponent,
    AboutOneComponent,
    AboutTwoComponent,
    AboutThreeComponent,
    AboutFourComponent,
    AboutFiveComponent,
    BlogOneComponent,
    BlogTwoComponent,
    BlogThreeComponent,
    SliderOneComponent,
    SliderTwoComponent,
    SliderThreeComponent,
    SliderFourComponent,
    TeamOneComponent,
    TeamTwoComponent,
    TeamThreeComponent,
    TeamFourComponent,
    TestimonialOneComponent,
    TestimonialTwoComponent,
    TestimonialThreeComponent,
    TestimonialFourComponent,
    HeaderFiveComponent,
    WorkOneComponent,
    WorkTwoComponent,
    CounterComponent,
    CounterOneComponent,
    ContactFormComponent,
    ContactMapComponent,
    WorkThreeComponent,
    TestimonialFiveComponent,
    FooterFiveComponent,
    PageHeaderComponent,
    PageHeaderOneComponent,
    ListItemsComponent,
    TeamFiveComponent,
    CallActionOneComponent,
    CallActionTwoComponent,
    SliderFiveComponent,
    BlogFourComponent,
    ServicesliderComponent,
    AboutServiceComponent,
    ServiceSectionComponent,
    ServiceDetailSliderComponent,
    ServiceDetailsecComponent,
    AboutContentComponent,
    AboutSliderComponent,
    AboutTestimonalComponent,
    AboutHowweworkComponent,
    CareersliderComponent,
    CareercontentComponent,
    ContactsliderComponent,
    SliderLabsComponent,
    AboutLabsComponent,
    ServiceLabsComponent,
    TeamLabsComponent,
    SchemeComponent,
    EnquiryFormComponent,
    ApplyOnlineComponent,
    FeedbackFormComponent,
    EnrollmentFormComponent,
    FeebackForm1Component,
    FeedbackForm2Component,
    FeedbackForm3Component,
    StarRatingComponent,
    SliderWhitepaperComponent,
  ],
  exports: [
    //ComponentsComponent,
    HeaderComponent,
    HeaderOneComponent,
    HeaderTwoComponent,
    HeaderThreeComponent,
    HeaderFourComponent,
    FooterComponent,
    FooterOneComponent,
    FooterTwoComponent,
    FooterThreeComponent,
    FooterFourComponent,
    ServiceComponent,
    TestimonialsComponent,
    AboutComponent,
    SlidersComponent,
    TeamComponent,
    BlogComponent,
    WorkComponent,
    ContactComponent,
    CommentsComponent,
    WidgetsComponent,
    CallToActionComponent,
    SearchComponent,
    RecentPostComponent,
    NewsletterComponent,
    CategoryListComponent,
    ArchivesComponent,
    ServiceOneComponent,
    ServiceTwoComponent,
    ServiceThreeComponent,
    ServiceFourComponent,
    AboutOneComponent,
    AboutTwoComponent,
    AboutThreeComponent,
    AboutFourComponent,
    AboutFiveComponent,
    BlogOneComponent,
    BlogTwoComponent,
    BlogThreeComponent,
    SliderOneComponent,
    SliderTwoComponent,
    SliderThreeComponent,
    SliderFourComponent,
    TeamOneComponent,
    TeamTwoComponent,
    TeamThreeComponent,
    TeamFourComponent,
    TestimonialOneComponent,
    TestimonialTwoComponent,
    TestimonialThreeComponent,
    TestimonialFourComponent,
    HeaderFiveComponent,
    WorkOneComponent,
    WorkTwoComponent,
    CounterComponent,
    CounterOneComponent,
    ContactFormComponent,
    ContactMapComponent,
    WorkThreeComponent,
    TestimonialFiveComponent,
    FooterFiveComponent,
    PageHeaderOneComponent,
    ListItemsComponent,
    TeamFiveComponent,
    CallActionOneComponent,
    CallActionTwoComponent,
    SliderFiveComponent,
    BlogFourComponent,
    ServicesliderComponent,
    AboutServiceComponent,
    ServiceSectionComponent,
    ServiceDetailSliderComponent,
    ServiceDetailsecComponent,
    AboutContentComponent,
    AboutSliderComponent,
    AboutTestimonalComponent,
    AboutHowweworkComponent,
    CareersliderComponent,
    CareercontentComponent,
    ContactsliderComponent,
    SliderLabsComponent,
    AboutLabsComponent,
    ServiceLabsComponent,
    TeamLabsComponent,
    SchemeComponent,
    EnquiryFormComponent,
    ApplyOnlineComponent,
    FeedbackFormComponent,
    EnrollmentFormComponent,
    FeebackForm1Component,
    FeedbackForm2Component,
    FeedbackForm3Component,
    StarRatingComponent,
    SliderWhitepaperComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    CarouselModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    BrowserModule,
    BrowserAnimationsModule,
    // MatFormFieldAnimations,
    // MatFormFieldControl,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatFileUploadModule,
    MatDialogModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatCardModule,
    //AppRoutingModule
  ],

  providers: [DatePipe],
})
export class ComponentModule {}
