import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-call-action-one',
  templateUrl: './call-action-one.component.html',
  styleUrls: ['./call-action-one.component.css']
})
export class CallActionOneComponent implements OnInit {
	
  constructor() { }

  ngOnInit(): void {
  }

}
