import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import {HttpclientService} from '../../../../apiservices/httpclient.service';
import {env} from '../../../../config/config';

@Component({
  selector: 'app-about-testimonal',
  templateUrl: './about-testimonal.component.html',
  styleUrls: ['./about-testimonal.component.css']
})
export class AboutTestimonalComponent implements OnInit {
  subtitle= "OUR HAPPY Customers";
  title= "Customer Testimonials";
  constructor(private service:HttpclientService) { }

  content
  flag=false
  url

  ngOnInit(): void {
    this.url=env.apiurl
    this.service.getAllValues('about-us-testimonials').subscribe(res => {
      this.content=res
      console.log(this.content)
      if(this.content.length>0){
        this.flag=true
      }
    })
  }

  customOptions: OwlOptions = {
		loop:true,
		autoplay:true,
		margin:30,
		nav:false,
		dots: true,
		navText: ['<i class="flaticon-left-arrow"></i>', '<i class="flaticon-right-arrow"></i>'],
		responsive:{
			0: {
				items: 1
			},
			400: {
				items: 1
			},
			740: {
				items: 1
			},
			940: {
				items: 2
			}
		}
	}
	images=[{
    image: "pic5.jpg",
  },
{
	image: "pic6.jpg",
}]

}
