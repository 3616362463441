import { FormGroup, FormBuilder, FormControl,Validators, FormGroupDirective, NgForm } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core/error/error-options";
export interface add_resume{
    name:string
    phone:number
    email:string
    current_location:string
    experience:string
    qualification:string
    resume:File
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      return !!(control && control.invalid && (control.dirty || control.touched));
    }
  }