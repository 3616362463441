<div id="demo" class="carousel slide" data-ride="carousel">
	<ul class="carousel-indicators">
		<li data-target="#demo" data-slide-to="0" class="active"></li>
		<li data-target="#demo" data-slide-to="1"></li>
		<li data-target="#demo" data-slide-to="2"></li>
	</ul>
	<div class="carousel-inner">
		<div class="carousel-item active">
			<div class="slide1" style="background-image: url(assets/images/main-slider/construct/slide1.jpg);">
				<div class="carousel-caption text-white">
					<h1>WE BUILD <br/>YOUR DREAM</h1>
					<p>We have 15 years experience in construction.</p>
				</div>   
			</div>
		</div>
		<div class="carousel-item">
			<div class="slide1" style="background-image: url(assets/images/main-slider/construct/slide2.jpg);">
				<div class="carousel-caption text-white">
					<h1>FROM CONCEPT <br/>TO CREATION.</h1>
					<p>We have 15 years experience in construction.</p>
				</div>   
			</div>
		</div>
		<div class="carousel-item" style="background-image: url(assets/images/main-slider/construct/slide3.jpg);">
			<div class="slide1">
				<div class="carousel-caption text-white">
					<h1>MAKING DREAMS<br/>COME TO LIFE</h1>
					<p>We have 15 years experience in construction.</p>
				</div>   
			</div>
		</div>
	</div>
	<a class="carousel-control-prev" href="#demo" data-slide="prev">
		<span class="carousel-control-prev-icon"></span>
	</a>
	<a class="carousel-control-next" href="#demo" data-slide="next">
		<span class="carousel-control-next-icon"></span>
	</a>
</div>