import { Component, OnInit } from '@angular/core';
import {HttpclientService} from '../../../../apiservices/httpclient.service'
import {env} from '../../../../config/config'
import {EnquiryFormComponent} from '../../contact/enquiry-form/enquiry-form.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-about-three',
  templateUrl: './about-three.component.html',
  styleUrls: ['./about-three.component.css']
})
export class AboutThreeComponent implements OnInit {

  content
	flag=false
  env

constructor(private service:HttpclientService,    public dialog: MatDialog,) { }

  ngOnInit(): void {
    this.env=env.apiurl
    this.get_allvalues()
  }
  // scrollTo(section) {
  //   document.querySelector('#' + section)
  //   .scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  // }

  openDialog() {
    this.dialog.open(EnquiryFormComponent,{width: '100%',maxWidth:'100%'});
  }
  get_allvalues(){

    this.service.getAllValues('placement-details').subscribe(res => {
      this.content=res[0]
      if(this.content.length>0){
        this.flag=true
      }
    })

  }


}
