<div
  class="section-full content-inner content-inner-padd bg-gray"
  id="about-us"
  style="background-image: url(assets/images/pattern/pt13.png);">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-12 m-b30 steel-about">
        <div class="section-head m-b20 text-center">
          <h5 class="title-small">{{ subtitle }}</h5>
          <div class="dlab-separator-outer ">
            <div class="dlab-separator bg-primary style-skew"></div>
          </div>

        </div>
        <span *ngFor="let i of content">
          <div class="section-head m-b20 text-center">
            <h1 class="title">{{ i.content_title }}</h1>
          </div>
          <p class="text-justify">{{ i.content_desc }}</p>
          <div class="text-center request-btn">
            <a (click)="navigateTo()" class="site-button request_cta">
              {{ i.button_text }}
              <i class="fa fa-chevron-right ml-3" aria-hidden="true"></i>
              <span class="circle"></span>
            </a>
          </div>
        </span>
      </div>
    </div>
  </div>
</div>
