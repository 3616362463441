import { Component, OnInit,Inject ,ElementRef} from '@angular/core';
import { HttpclientService } from '../../../../apiservices/httpclient.service';
import {EnquiryFormComponent} from '../enquiry-form/enquiry-form.component';
import {FeedbackFormComponent} from '../feedback-form/feedback-form.component'
import {EnrollmentFormComponent} from '../enrollment-form/enrollment-form.component'
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DOCUMENT, ViewportScroller } from '@angular/common';
interface enquiry {
  Student_name: string;
  Phone: number;
  Email_Id: string;
  Preferred_career: string;
  Preferred_scheme: string;
  Message: string;
}
@Component({
  selector: 'app-apply-online',
  templateUrl: './apply-online.component.html',
  styleUrls: ['./apply-online.component.scss'],

})
export class ApplyOnlineComponent implements OnInit {



  constructor(
    private service: HttpclientService,
   @Inject(DOCUMENT) private document: Document,
    private viewPortScroller: ViewportScroller,
    public dialog: MatDialog,
    private elref: ElementRef,
    ) { }

  ngOnInit() {

  }

  openDialog(component:any) {
    if (component=='FeedbackFormComponent'){
      this.dialog.open(FeedbackFormComponent,{width: '100%',maxWidth:'100%'});
    }else if(component=='EnquiryFormComponent'){
      this.dialog.open(EnquiryFormComponent,{width: '100%',maxWidth:'100%'});
    }else if(component=='EnrollmentFormComponent'){
      this.dialog.open(EnrollmentFormComponent,{width: '100%',maxWidth:'100%'});
    }


  }

  closeDialog() {
    this.dialog.closeAll();
  }
}



