<link
  href="https://fonts.googleapis.com/icon?family=Material+Icons"
  rel="stylesheet"
/>
<div class="section-full content-inner bg-gray">
  <div class="container">
    <div class="section-head text-center">
      <h5 class="title-small">CAREER OPPORTUNITY</h5>
      <div class="dlab-separator-outer">
        <div class="dlab-separator bg-primary style-skew"></div>
      </div>
      <div class="row">
        <div class="col-md-12 col-12 float-left">
          <div class="section-head text-center">
            <h1 class="title p-3">Upload your Resume</h1>
          </div>
          <form [formGroup]="resumeForm" class="row">
            <div class="col-md-6 col-12 float-left">
              <mat-form-field appearance="outline" class="first">
                <mat-label>Your Name</mat-label>
                <input
                  matInput
                  placeholder="Your Name"
                  formControlName="name"
                  [errorStateMatcher]="matcher"
                  required
                />
                <mat-error
                  class="error"
                  *ngIf="resume_submitted && errorControl.name.errors?.required"
                >
                  Name is required.
                </mat-error>
                <mat-error
                  class="error"
                  *ngIf="resume_submitted && errorControl.name.errors?.pattern"
                >
                  Name must be between 2 and 32 characters.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6 col-12 float-left">
              <mat-form-field appearance="outline">
                <mat-label>Phone/Mobile</mat-label>
                <input
                  matInput
                  placeholder="Phone/Mobile"
                  formControlName="phone"
                  [errorStateMatcher]="matcher"
                  required
                />
                <mat-error
                  class="error"
                  *ngIf="
                    resume_submitted && errorControl.phone.errors?.required
                  "
                >
                  Mobile number is required.
                </mat-error>
                <mat-error
                  class="error"
                  *ngIf="resume_submitted && errorControl.phone.errors?.pattern"
                >
                  Please enter a valid 10 digit mobile number.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6 col-12 float-left">
              <mat-form-field appearance="outline">
                <mat-label>Email address</mat-label>
                <input
                  matInput
                  placeholder="Email address"
                  formControlName="email"
                  [errorStateMatcher]="matcher"
                  required
                />

                <mat-error
                  class="error"
                  *ngIf="
                    resume_submitted && errorControl.email.errors?.required
                  "
                >
                  Email address is required.
                </mat-error>
                <mat-error
                  class="error"
                  *ngIf="resume_submitted && errorControl.email.errors?.pattern"
                >
                  Please enter a valid email address.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6 col-12 float-left">
              <mat-form-field appearance="outline">
                <mat-label>Current Location</mat-label>
                <input
                  matInput
                  placeholder="Current Location"
                  formControlName="location"
                />

                <mat-error
                  class="error"
                  *ngIf="
                    resume_submitted && errorControl.location.errors?.required
                  "
                >
                  Location is required.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-6 col-12 float-left">
              <mat-form-field appearance="outline">
                <mat-label>Total Experince</mat-label>
                <input
                  matInput
                  placeholder="Total Experince"
                  formControlName="experience"
                />

              </mat-form-field>
            </div>

            <div class="col-md-6 col-12 float-left">
              <mat-form-field appearance="outline">
                <mat-label>Highest Qualification</mat-label>
                <mat-select formControlName="qualification">
                  <mat-option [value]="i" *ngFor="let i of study">
                    {{ i }}
                  </mat-option>

                </mat-select>
              </mat-form-field>

              <div
                class="error"
                *ngIf="
                  resume_submitted &&
                  errorControl.qualification.errors?.required
                "
              >
                Qualification is required.
              </div>
            </div>

            <div class="col-md-12">
              <mat-form-field appearance="outline">
                <mat-label>Preferred job</mat-label>
                <mat-select formControlName="job">
                  <mat-option [value]="i.job_title" *ngFor="let i of content">
                    {{ i.job_title }}
                  </mat-option>

                </mat-select>
              </mat-form-field>
              <div
                class="error"
                *ngIf="
                  resume_submitted &&
                  errorControl.qualification.errors?.required
                "
              >
                Qualification is required.
              </div>
            </div>

            <div class="upload col-md-12 col-12 text-center">
              <label for="singleFile">Upload file</label>
              <input
                id="singleFile"
                type="file"
                [fileUploadInputFor]=""
                (change)="filechange($event)"
                formControlName="resume"
              />

            </div>
            <div class="col-md-12 col-12">
              <button
                mat-raised-button
                type="submit"
                class="formsubmit-btn"
                (click)="job_enquiry()"
              >
                Submit
              </button>
            </div>
          </form>
          <div class="dlab-separator-outer mt-5 d-block d-md-none">
            <div class="dlab-separator bg-primary style-skew"></div>
          </div>
        </div>
        <div class="col-md-12 col-12">
          <div class="section-head text-center">

            <h2 class="title p-3">Current Openings</h2>
            <P *ngIf="content == 0 || content == null" class="text-center">
              No current Openings
            </P>
          </div>
          <div class="row">
            <div class="col-md-6 col-12" *ngFor="let i of content; index as j">
              <mat-accordion class="accordion">
                <mat-expansion-panel
                  [expanded]="current == j"
                  (click)="set_current(j)"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{ i.job_title }} - {{ i.vacancy }} Post
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="align-left">
                    <h2>Overview</h2>
                    <div class="overview">
                      <span
                        class="left-align"
                        [innerHTML]="i.job_overview"
                      ></span>
                    </div>
                    <h2>Skills</h2>

                    <div class="skills">
                      <p>{{ i.job_skills }}</p>
                    </div>
                    <h2>Qualifications</h2>
                    <div class="qualification">
                      <span
                        class="left-align"
                        [innerHTML]="i.job_qualification"
                      ></span>

                      <p>
                        {{ send }}
                        <a href="mailto:{{ email }}">{{ email }}</a>
                      </p>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
