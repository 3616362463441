
<footer class="site-footer">
	<div class="footer-top"  style="background-image:url(assets/images/pattern/pt15.png);">
		<div class="container">
			<div class="row">
				<div class="col-md-6 col-5 col-xl-3 col-lg-3 col-sm-6 footer-col-4 col-12">
					<div class="widget widget_about border-0">
						<div class="footer-title">
							<h5 class="title text-white">{{about_title}}</h5>
							<div class="dlab-separator-outer">
								<div class="dlab-separator bg-primary style-skew"></div>
							</div>
						</div>
						<p class="mm-t5">{{description}}</p>
						<ul class="contact-info-bx">
							<li *ngFor="let item of contactList">
								<i [ngClass]="item.icon"></i>{{item.address}}
							</li>
						</ul>
					</div>
				</div>
				<div class="col-md-6 col-7 col-xl-3 col-lg-3 col-sm-6 footer-col-4 col-12">
					<div class="widget border-0 recent-posts-entry">
						<div class="footer-title">
							<h5 class="title text-white">{{post_title}}</h5>
							<div class="dlab-separator-outer">
								<div class="dlab-separator bg-primary style-skew"></div>
							</div>
						</div>
						<div class="widget-post-bx">
							<div *ngFor="let item of latestPost" class="widget-post clearfix">
								<div class="dlab-post-media"> 
									<img src="assets/images/blog/recent-blog/{{item.image}}" width="200" height="143" alt=""> 
								</div>
								<div class="dlab-post-info">
									<div class="dlab-post-header">
										<h6 class="post-title"><a href="javascript:void(0);">{{item.title}}</a></h6>
									</div>
									<div class="dlab-post-meta">
										<ul>
											<li class="post-author" [innerHTML]="item.author_name"></li>
											<li class="post-comment">{{item.comment}}</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6 col-xl-3 col-lg-3 col-sm-6 footer-col-4 col-12">
					<div class="widget widget_services border-0">
						<div class="footer-title">
							<h5 class="title text-white">{{company_title}}</h5>
							<div class="dlab-separator-outer">
								<div class="dlab-separator bg-primary style-skew"></div>
							</div>
						</div>
						<ul class="mm-t10">
							<li *ngFor="let item of serviceList">
								<a href="javascript:void(0);">{{item.title}}</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="col-md-6 col-xl-3 col-lg-3 col-sm-6 footer-col-4 col-12">
					<div class="widget">
						<div class="footer-title">
							<h5 class="title text-white">{{schedule_title}}</h5>
							<div class="dlab-separator-outer">
								<div class="dlab-separator bg-primary style-skew"></div>
							</div>
						</div>
						<ul class="thsn-timelist-list mm-t5">
							<li><span class="thsn-timelist-li-title">Mon – Tue</span><span class="thsn-timelist-li-value">10:00 – 18:00</span></li>
							<li><span class="thsn-timelist-li-title">Wed – Thur</span><span class="thsn-timelist-li-value">10:00 – 17:00</span></li>
							<li><span class="thsn-timelist-li-title">Fri – Sat</span><span class="thsn-timelist-li-value">10:00 – 12:30</span></li>
							<li><span class="thsn-timelist-li-title">Saturday</span><span class="thsn-timelist-li-value">10:00 – 12:30</span></li>
							<li><span class="thsn-timelist-li-title">Sunday</span><span class="thsn-timelist-li-value">Closed</span></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="footer-bottom">
		<div class="container">
			<div class="row">
				<div class="col-md-6 col-sm-6 text-left "> <span>Copyright © 2019 DexignZone</span> </div>
				<div class="col-md-6 col-sm-6 text-right "> 
					<div class="widget-link "> 
						<ul>
							<li><a href="javascript:void(0);"> About</a></li> 
							<li><a href="javascript:void(0);"> Help Desk</a></li> 
							<li><a href="javascript:void(0);"> Privacy Policy</a></li> 
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>


