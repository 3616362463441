import { Component, OnInit } from '@angular/core'
declare var TradeZone : any;
@Component({
  selector: 'app-aboutuspage',
  templateUrl: './aboutuspage.component.html',
  styleUrls: ['./aboutuspage.component.css'],
})
export class AboutuspageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    this.move_top()
    TradeZone.init();

  }

  move_top() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}
