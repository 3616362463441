<section class="section-full contactus p-t30 bg-gray" id="enquiry-form">
  <div class="container">
    <div class="section-head m-b30 text-center">
      <h2 class="title">YOUR INSIGHTS</h2>
    </div>
    <!-- <div class="row">
      <div class="col-lg-12 col-12 col-md-12 m-b30">
        <div class="d-flex justify-content-center align-item-center">
          <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
            <mat-button-toggle value="Enquiry">Enquiry</mat-button-toggle>
            <mat-button-toggle value="Feedback">Feedback</mat-button-toggle>
            <mat-button-toggle value="Enrollment">Enrollment</mat-button-toggle>
          </mat-button-toggle-group>
        </div>s

      </div>

    </div> -->
    <div class="row form-row">

      <div class="col-lg-12 col-12 col-md-12 m-b30">
        <div class="d-flex justify-content-center align-item-center enquire">

            <button
              class="site-button white enquire-cta"
              type="button"
               (click)="openDialog('EnquiryFormComponent')"
              data-toggle="modal"
              data-target="#myModal"
            >
            <img _ngcontent-erf-c251="" target="_blank" src="/assets\images\search.png" alt="whatsapp">Enquire<span class="circle1"></span>
            </button>
            <button
              class="site-button white enquire-cta"
              type="button"
              (click)="openDialog('FeedbackFormComponent')"
              data-toggle="modal"
              data-target="#myModal"
            >
            <img _ngcontent-erf-c251="" target="_blank" src="/assets\images\feedback.png" alt="whatsapp">Feedback<span  class="circle1"></span>
            </button>
            <button
              class="site-button white enquire-cta"
              type="button"
              (click)="openDialog('EnrollmentFormComponent')"
              data-toggle="modal"
              data-target="#myModal"
            >
            <img _ngcontent-erf-c251="" target="_blank" src="/assets\images\add-user.png" alt="whatsapp">Enrollment<span  class="circle1"></span>
            </button>
        </div>
      </div>
    </div>
  </div>

</section>
