import { Component, OnInit } from '@angular/core';
import {HttpclientService} from '../../../apiservices/httpclient.service'
import {env} from '../../../config/config'
import { HttpClient } from '@angular/common/http';
import { add_resume, MyErrorStateMatcher } from './resume.model';
import { FormGroup, FormBuilder, FormControl,Validators } from "@angular/forms";
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-careercontent',
  templateUrl: './careercontent.component.html',
  styleUrls: ['./careercontent.component.css']
})
export class CareercontentComponent implements OnInit {
  panelOpenState = false;

  add_resume=new FormData();
  content
  current=-1
  resume:any=[]
  flag=false
  resume_submitted=false
  overview=[]
  languages=[]
  study
  skills=[]

  resumeForm:FormGroup;
  matcher = new MyErrorStateMatcher();

send="Send cv to"
email

  constructor(private service:HttpclientService,private http: HttpClient,public dialog: MatDialog) {
    this.overview=[
      {
        label:"Published on",
        point:"5 days ago"

      },
      {
        label:"Vacancy ",
        point:"2"

      },
      {
        label:"Employment Status",
        point:"Full Time"

      },
      {
        label:"Experience",
        point:"3 - 5 Yrs"

      },
      {
        label:"Salary",
        point:"Not disclosed"

      },
      {
        label:"Interview Venue",
        point:"Trivandrum"

      },

    ]

    this.languages=[

      {
        lang:"C"
      },
      {
        lang:"JavaScript"
      },
      {
        lang:"SQL"
      },

    ]

    this.skills=[
      {
        skill:"T SQL"
      },
      {
        skill:"T SQL"
      },
      {
        skill:"XSLT/XSD"
      },
      {
        skill:"Actionscripts"
      },
    ]


  }

  ngOnInit(): void {
    let mail
    this.service.getAllValues('career-jobs').subscribe(res => {
      this.content=res
      if(this.content.length>0){
        this.content.map(i=>{
          i.panelOpenState=false
        })
        this.flag=true
      }
    })
    this.service.getconfigValue("config_email").subscribe(res => {

      this.email=res[0].content_value

      console.log(this.email)
    })

    this.service.getconfigValue("qualifications").subscribe(res => {
      console.log(res)
      this.study=res[0].content_value.split(',')
      console.log(this.study)

    })

    this.resumeForm = new FormGroup({
      name: new FormControl('',[Validators.required,Validators.pattern('[a-zA-Z ]{2,32}')]),
      phone:new FormControl('',[Validators.required,Validators.pattern('[0-9]{10}')]),
      email: new FormControl('',[
        Validators.required,
        Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      ]),
      location:new FormControl(),
      experience:new FormControl(),
      qualification:new FormControl(),
      job:new FormControl('',[Validators.required]),
      resume:new FormControl()
   });
  }

  get errorControl() {

    return this.resumeForm.controls
  }

  filechange(e){
    console.log(e)
    this.resume = e.target.files
    console.log(this.resume)
    }

  job_enquiry(){
    let result
    this.resume_submitted=true
    if (!this.resumeForm.valid) {
      this.resumeForm.markAllAsTouched();
      console.log('Please provide all the required values!')
      return false;
    } else {

      const formElement = document.querySelector('form');

        const formData = new FormData();

        const data = {
          name:'',
          phone:'',
          email:'',

          current_location:'',
          experience:'',
          qualification:'',
          preferred_job:''
        };


      data.name=this.resumeForm.value.name
      data.phone=this.resumeForm.value.phone
      data.email=this.resumeForm.value.email
      data.current_location=this.resumeForm.value.location
      data.experience=this.resumeForm.value.experience
      data.qualification=this.resumeForm.value.qualification
      data.preferred_job=this.resumeForm.value.job
      if(this.resume.length>0){
      formData.append(`files.resume`,this.resume[0],this.resume[0].name)
    }

      formData.append('data', JSON.stringify(data));
      this.service.contact_us(formData).subscribe(res => {
        console.log(res)

        result=res
        this.resume_submitted=false
        this.resumeForm.reset()
        console.log(this.resumeForm)
        this.resumeForm.get("job").markAsUntouched()
        this.resumeForm.clearValidators()
        this.resumeForm.controls['job'].setErrors(null)
        this.openDialog()
				setTimeout(() => {
					this.closeDialog()
				}, 2000);
        console.log(this.resume)
      })
    }
  }
  openDialog() {
		this.dialog.open(DialogElementsExampleDialog);
	  }

	  closeDialog(){
		  this.dialog.closeAll();
	  }

    set_current(j){
    if(this.current!=j){
      this.current=j
    }else{
      this.current=-1
    }

  }

}
@Component({
	selector: 'dialog-elements-example-dialog',
	template: `<div class="icon"><i class="fa fa-check-circle" aria-hidden="true"></i></div><div mat-dialog-content>Your Form has been submitted successfully.</div> <div mat-dialog-actions></div>`,
	styles:['.fa-check-circle{color:green; font-size:50px;}.icon,.mat-dialog-content{text-align:center}']
})
  export class DialogElementsExampleDialog {}
