import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
} from '@angular/core';
declare var TradeZone: any;
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { HttpclientService } from '../apiservices/httpclient.service';
import { HistoryService } from 'src/app/apiservices/history.service';
import { env } from '../config/config';
import { saveAs } from 'file-saver';
import { Meta } from '@angular/platform-browser';
import { SEOService } from '../apiservices/seo.service';
@Component({
  selector: 'app-whitepaperdetail',
  templateUrl: './whitepaperdetail.component.html',
  styleUrls: ['./whitepaperdetail.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class WhitepaperdetailComponent implements OnInit {
  url;
  flag;
  paper;
  file_flag = false;
  buttontext = 'Download as PDF';
  imagelink = '/assets/images/whitepaper.png';
  paperlink = '/whitepaper/whitepape';
  prtitle =
    'Overcoming the challenges of electronic data interchange (EDI) implementation';
  prplace = 'By Hiten Shah &amp; Anjana S';
  constructor(
    private service: HttpclientService,
    private history: HistoryService,
    private router: Router,
    private meta: Meta,
    private _seoService: SEOService,
    private route: ActivatedRoute
  ) {}
  richTextContent: string = ''; // Rich text content fetched from Strapi API
  sectionTitles: string[] = []; // Array to store section titles extracted from rich text content
  whitepaper_id: any;
  @ViewChild('contentContainer') contentContainer!: ElementRef;
  ngOnInit(): void {
    TradeZone.init();
    this.move_top();
    this.url = env.apiurl;
    this.route.params.subscribe((params) => {
      this.whitepaper_id = +params['id'];
    });

    this.get_whitepaper(this.whitepaper_id);
  }

  get_whitepaper(id: any) {
    this.service.get_whitepaper(id).subscribe((res) => {
      this.paper = res;
      this.richTextContent = this.paper.long_desc;
      // console.log('paper.long_desc', this.paper.long_desc);
      this.extractSectionTitles();
      if (this.paper) {
        this.flag = true;
        if (
          this.paper.download_file != null &&
          this.paper.cover_image != null
        ) {
          this.file_flag = true;
        }
      }

      // this.router.navigateByUrl(`/services/${res[0].service_slug}`);
    });
  }

  extractSectionTitles() {
    // Regular expression to match section titles
    const regex = /<h2.*?>(.*?)<\/h2>/g;
    let match;
    while ((match = regex.exec(this.richTextContent)) !== null) {
      // Extract title from match and push it to sectionTitles array
      this.sectionTitles.push(match[1]);
    }
  }

  scrollToSection(title: string) {
    // Find the content container element
    const contentContainer = this.contentContainer.nativeElement;
    // Find all h2 elements inside the content container
    const h2Elements = contentContainer.querySelectorAll('h2');

    // Loop through each h2 element
    h2Elements.forEach((h2Element: HTMLElement) => {
      // Check if the innerText of the h2 element matches the title
      if (h2Element.innerText.trim() === title) {
        // Calculate the offset based on your requirement
        const rect = h2Element.getBoundingClientRect();
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        const elementTop = rect.top + scrollTop;
        const offsetTop = elementTop - 77;
        // Scroll to the matched h2 element with the specified offset
        h2Element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
        window.scrollTo({
          top: offsetTop,
          behavior: 'smooth',
        });
      }
    });
  }
  download(file) {
    console.log(file);
    saveAs(this.url + file.url, file.mime);
  }

  move_top() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
