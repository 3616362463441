<div class="section-full content-inner-2 bg-white" id="news" style="background-image: url(assets/images/pattern/pt13.png);">	
		<div class="row">
			<div class="col-lg-12 section-head text-center">
				<h5 class="title-small">NEWS & UPDATES</h5>
				<div class="dlab-separator-outer">
					<div class="dlab-separator bg-primary style-skew"></div>
				</div>
				<h2 class="font-weight-700 m-b0">Latest From Our Blog</h2>
			</div>
		</div>
		<owl-carousel-o [options]="customOptions" class="blog-carousel">
			<ng-template carouselSlide *ngFor = "let slide of data">
				<div class="slide">
					<div class="blog-post style1 blog-grid ">
						<div class="dlab-post-media dlab-img-effect"> 
							<a href="#"><img src="assets/images/blog/grid/{{slide.image}}" alt=""></a> 
							<div class="dlab-post-meta">
								<ul>
									<li class="post-date"><span>{{slide.date}}</span> </li>
									<li class="post-author"><img src="assets/images/testimonials/{{slide.testimonial_image}}" alt=""><a href="#">{{slide.name}}</a> </li>
								</ul>
							</div>
						</div>
						<div class="dlab-info">
							<ul class="categories">
								<li><a [routerLink] = "['/blog-list-one']">{{slide.categorie}}</a></li>
							</ul>
							<div class="dlab-post-title ">
								<h3 class="post-title"><a [routerLink] = "['/blog-list-one']">{{slide.title}}</a></h3>
							</div>
							<div class="dlab-post-readmore"> 
								<a [routerLink] = "['/blog-single']" class="site-button white align-self-center ml-auto half-btn">
									<span>Read More</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</ng-template>
		</owl-carousel-o>
</div>
