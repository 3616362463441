import {
  Component,
  ElementRef,
  EventEmitter,
  Host,
  HostListener,
  OnInit,
  Output,
} from '@angular/core';
import { HttpclientService } from '../../../../../apiservices/httpclient.service';
import { env } from '../../../../../config/config';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FeedbackFormComponent } from '../feedback-form.component';
interface Wrap_UpFb {
  Name: string;
  Phone: number;
  Email_Id: string;
  about_6Solve:string;
  need_Improvement:string;
  enhanced_Experience:string;
  additional_Comments:string;
  recommend_6Solve_LABs:string;
}


@Component({
  selector: 'app-feedback-form3',
  templateUrl: './feedback-form3.component.html',
  styleUrls: ['./feedback-form3.component.css']
})
export class FeedbackForm3Component implements OnInit {
  Wrap_UpFbForm : FormGroup
  Wrap_UpFbForm_submitted:boolean=false
  add_Wrap_UpFb:Wrap_UpFb
  recommend_6Solve_LABs_data=[
'Yes',
'No',
'May be'
  ]
  constructor( public services: HttpclientService,
    @Host() private parent: FeedbackFormComponent) {

    this.Wrap_UpFbForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.pattern('[a-zA-Z ]{2,32}'),
      ]),
      phone: new FormControl('', [
        Validators.required,
        Validators.pattern('[0-9]{10}'),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),
      ]),
      about_6Solve: new FormControl(),
      need_Improvement:new FormControl(),
      enhanced_Experience:new FormControl(),
      additional_Comments:new FormControl(),
      recommend_6Solve_LABs:new FormControl('', [
        Validators.required])
    });
   }

  ngOnInit() {


  }

  get errorControl() {
    return this.Wrap_UpFbForm.controls;
  }

  onKeyPress(event: KeyboardEvent) {
    const key = event.key;

    // Allow only alphabetic characters (A-Z, a-z) and prevent other characters
    if (!/^[a-zA-Z ]+$/.test(key)) {
      event.preventDefault();
    }
  }

  wrap_upfeedback(){

    this.Wrap_UpFbForm_submitted = true;

    if (!this.Wrap_UpFbForm.valid) {
      this.Wrap_UpFbForm.markAllAsTouched();
      console.log('Please provide all the required values!');

      return false;
    }else{
      this.add_Wrap_UpFb={
        Name: this.Wrap_UpFbForm.value.name,
        Phone: this.Wrap_UpFbForm.value.phone,
        Email_Id: this.Wrap_UpFbForm.value.email,
        about_6Solve: this.Wrap_UpFbForm.value.about_6Solve,
        need_Improvement:this.Wrap_UpFbForm.value.need_Improvement,
        enhanced_Experience:this.Wrap_UpFbForm.value.enhanced_Experience,
        additional_Comments:this.Wrap_UpFbForm.value.additional_Comments,
        recommend_6Solve_LABs:this.Wrap_UpFbForm.value.recommend_6Solve_LABs
      }
      this.services.add_Wrap_Up_feedback(this.add_Wrap_UpFb).subscribe(
        (res) => {
          if (res) {
            this.parent.openDialog('Wrap-Up feedback');
            setTimeout(() => {
              this.parent.closeDialog();
            }, 2000);
            this.services
              .send_mail(this.add_Wrap_UpFb, 'Wrap_Up-form')
              .subscribe((res) => {
                if (res) {
                  console.log('mail sent successfully');
                }
              });
          }
        },
        (error) => {
          console.log(error);
        }
      );

    }
    }

 }
