
<div class="section-full call-action bg-primary wow fadeIn" data-wow-duration="2s" data-wow-delay="0.2s" style="background-image:url(images/pattern/pt5.png);">
	<div class="container">
		<div class="row">
			<div class="col-lg-9 text-black">
				<h2 class="title">Amazing Things Happen To Your Business </h2>
				<p class="m-b0">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
			</div>
			<div class="col-lg-3 d-flex">
				<a href="javascript:void(0);" class="site-button black align-self-center ml-auto button-style-2">
					<span>MARKET SECTORS</span>
					<i class="la la-long-arrow-alt-right"></i>
				</a>
			</div>
		</div>
	</div>
</div>

