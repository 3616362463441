<link
  class="skin"
  rel="stylesheet"
  type="text/css"
  href="/assets/css/skin/skin-4.css"
/>
<div class="page-wraper">
  <app-header-four></app-header-four>
  <div class="content-block">
    <div class="container">
      <div class="row" *ngIf="flag">
        <div class="col-md-12">
          <div class="banner">
            <div class="overlay-detail">
              <div class="container">
                <h1>{{ paper.title }}</h1>
                <p class="content-title">{{ paper.short_desc }}</p>
                <div *ngIf="paper.author_name">
                  <label class="author_name_label" for="author">Author</label>
                  <h6 class="author_name">{{ paper.author_name }}</h6>
                </div>

                <img
                  class="download-icon"
                  src="../../assets/images/download.png"
                  (click)="download(paper.download_file)"
                />
              </div>
            </div>

            <img
              class="banner-image-details"
              src="{{ url + paper.image.url }}"
              alt="white-paper"
            />
          </div>

          <div class="content-cover">
            <div class="indexing">
              <h1><b>Table of content</b></h1>
              <div
                *ngFor="let title of sectionTitles"
                (click)="scrollToSection(title)"
              >
                <p class="indexingP">{{ title }}</p>
                <!-- You can render other content of the section here if needed -->
              </div>
            </div>
            <div #contentContainer>
              <div class="content-details" [innerHTML]="richTextContent"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer-four></app-footer-four>
    <button class="scroltop style2 radius" type="button" (click)="move_top()">
      <i class="fa fa-arrow-up"></i>
    </button>
  </div>
</div>
