import { element } from 'protractor';
import { Component, OnInit, Input,ElementRef, ViewChild, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpclientService } from '../../../../apiservices/httpclient.service';
import { env } from '../../../../config/config';
import { HistoryService } from 'src/app/apiservices/history.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { HostListener } from '@angular/core';
import {SEOService} from '../../../../apiservices/seo.service'
import { EnquiryFormComponent } from '../../contact/enquiry-form/enquiry-form.component';
declare var menuList: any[];

@Component({
  selector: 'app-header-four',
  templateUrl: './header-four.component.html',
  styleUrls: ['./header-four.component.css'],
})
export class HeaderFourComponent implements OnInit {
  @Input() data: any;

  @ViewChild('contactus') contactUsElement: ElementRef;
  config;
  open_flag = false;
  screenWidth: number;
  pages;
  seo_collection
  logo;
  servicelist;
  flag = false;
  button_text;
  config_email;
  contact_show = false;
  phone;
  current_service;
  our_services;
  current_title;
  openhumberger = false;
   split_url
  constructor(
    private router: Router,
    private service: HttpclientService,
    private history: HistoryService,
    private snap_route: ActivatedRoute,
    private seo:SEOService,
    private elementRef: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    private viewPortScroller: ViewportScroller,
    public dialog: MatDialog,
  ) {
    let url=window.location.href
    let arr=url.split('//')
    this.split_url=arr[1].split('/')

    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenWidth = window.innerWidth;

  }

  ngOnInit(): void {
    console.log('on init');
    console.log(this.snap_route.snapshot.params.slug);
    this.get_config();
    this.get_menu_list()
    let current_page = window.location.href.split('/').pop();
    if (this.snap_route.snapshot.params.slug) {
      this.current_service = this.history.get_services();
      if (this.current_service != null) {
        this.current_title = this.current_service.title;
      }
    }
    this.openhumberger = false;

    this.service.get_seo().subscribe((res) => {

      console.log(res,'seo collection')
      this.seo_collection=res
      console.log(this.split_url,"split")
      let meta_data:any
        switch (this.split_url[1]) {
          case 'contact-us':
            meta_data=this.seo_collection.filter((e)=>e.Page_name=='contact_us')
            console.log(meta_data,"contact-us");
            break;
          case "about-us":
            meta_data=this.seo_collection.filter((e)=>e.Page_name=='about_us')
            console.log(meta_data,"about-us");
            break;
          case "careers-in-6solve":
            meta_data=this.seo_collection.filter((e)=>e.Page_name=='careers')
            console.log(meta_data,"careers-in-6solve");
            break;
          case "whitepaper":
            meta_data=this.seo_collection.filter((e)=>e.Page_name=='whitepaper')
            console.log(meta_data,"whitepaper");
            break;
          case "services":
            meta_data=this.seo_collection.filter((e)=>e.Page_name=='service')
            console.log(meta_data,"services");
            break;
            case "labs":
              meta_data=this.seo_collection.filter((e)=>e.Page_name=='labs')

              console.log(meta_data,"labs");
              break;
          default:
            meta_data=this.seo_collection.filter((e)=>e.Page_name=='home')
            console.log(meta_data,"home");
            break;
        }
      this.update_meta(meta_data)
    })

  }
  navigateTo() {
    if (this.split_url[1] === 'labs' || this.split_url[1] === 'forms' ) {
      // this.scrollspy('enquiry-form');
      this.dialog.open(EnquiryFormComponent,{width: '100%',maxWidth:'100%'});

    } else {

      this.router.navigate(['/contact-us']).then(() => {
        // Ensure that the element is available

        setTimeout(()=>{
          this.scrollTo('contactus');
        },500);

      });

        }
  }
  move_top() {
       window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  scrollspy(section){
    var element =  document.querySelector('#' + section)
    const topPosition = element.getBoundingClientRect().top + window.scrollY - 200;
    window.scrollTo({ top: topPosition, behavior: 'smooth' });
  }

  scrollTo(section) {
    document.querySelector('#' + section)
    .scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }
  open_humberger() {
    if (this.openhumberger == false) {
      this.openhumberger = true;
    } else {
      this.openhumberger = false;
    }
    console.log(this.openhumberger);
  }
  get_config() {
    this.service.getAllValues('configurations').subscribe((res) => {
      console.log('page', res);
      this.config = res;
      this.config.map((i) => {
        if (i.content_key == 'pages') {
          this.pages = i.content_value.split(',');
        } else if (i.content_key == 'company_logo') {
          this.logo = env.apiurl + i.logos[0].url;
          console.log(this.logo);
        } else if (i.content_key == 'header_button_text') {

          if(this.split_url[1] === 'labs' ||this.split_url[1] === 'forms'  ){
            this.button_text='Enquire now'
          }else{
            this.button_text = i.content_value;
          }

        } else if (i.content_key == 'config_email') {
          if(this.split_url[1] != 'labs' || this.split_url[1] != 'forms'){
            this.config_email = i.content_value;
          }
        } else if (i.content_key == 'config_phone') {
          if(this.split_url[1] != 'labs' || this.split_url[1] != 'forms'){
            this.phone = i.content_value;
          }

        }else if(i.content_key == 'whatsapp_number'){
          if(this.split_url[1] === 'labs' || this.split_url[1] === 'forms'){
            this.phone = i.content_value;
          }
        }else if(i.content_key == 'labs_email_id'){
          if(this.split_url[1] === 'labs' || this.split_url[1] === 'forms'){
            this.config_email = i.content_value;
          }
        }
      });
    });

    this.service.getAllValues('services').subscribe((res) => {
      this.servicelist = res;
      if (this.servicelist.length > 0) {
        this.flag = true;
      }
    });
  }

  update_meta(e){
    console.log(e[0].meta_title,'metadata')
        this.seo.updateTitle(e[0].meta_title)
        this.seo.updateKeywords(e[0].meta_keywords)
        this.seo.updateDescription(e[0].meta_description)
  }

  // get_service(){
  //   this.service.getAllValues("services").subscribe(res => {
  //     this.our_services=res
  //   })
  // }
  service_detail(e) {
    console.log(e);
    this.history.set_services(e);
    console.log(this.snap_route.snapshot.params.slug);
    let slug = this.snap_route.snapshot.params.slug;

    if (slug != e.service_slug) {
      window.location.href = `/services/${e.service_slug}`;
    } else {
      // this.router.navigateByUrl(`/services/${e.service_slug}`)
      window.location.reload();
    }
  }

  service_page(e) {
    if (window.innerWidth < 992) {
      if (e.target.id == 'service_link') {
        this.router.navigate(['/services']);
      } else {
        if (!this.open_flag) {
          this.open_flag = true;
        } else {
          this.open_flag = false;
        }
      }
    } else {
      this.open_flag = false;
    }
    // console.log(this.open_flag)
  }
  showcontact() {
    if (this.contact_show == false) {
      this.contact_show = true;
    } else {
      this.contact_show = false;
    }
  }
get_menu_list(){
  this.service.get_menulist().subscribe((res=>{
let menus= res

  }))
}

}
