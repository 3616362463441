import {
  AfterViewInit,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core'
declare var TradeZone : any;
@Component({
  selector: 'app-servicepage',
  templateUrl: './servicepage.component.html',
  styleUrls: ['./servicepage.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ServicepageComponent implements OnInit, AfterViewInit {
  constructor() {}

  ngOnInit(): void {
    this.move_top()
    TradeZone.init();
  }
  ngAfterViewInit() {
    console.log('after view')
  }
  move_top() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}
