import { Component, OnInit, ViewEncapsulation } from '@angular/core'

declare var TradeZone: any
@Component({
  selector: 'app-contactuspage',
  templateUrl: './contactuspage.component.html',
  styleUrls: ['./contactuspage.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ContactuspageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {

    TradeZone.init()
  }

  move_top() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}
