import { Component, OnInit } from '@angular/core';
import {HttpclientService} from '../../../../apiservices/httpclient.service'
import {env} from '../../../../config/config'

@Component({
  selector: 'app-about-slider',
  templateUrl: './about-slider.component.html',
  styleUrls: ['./about-slider.component.css']
})
export class AboutSliderComponent implements OnInit {

  slider
  env
  flag=false
  hide_flag=false

  constructor(private service:HttpclientService) { }

  ngOnInit(): void {
    this.get_banner()
    this.env=env.apiurl
  }

  get_banner(){
    let arr
    this.service.getBanner("about_us").subscribe(res => {
          arr=res
      
          this.slider=arr[0].image
        
          if(this.slider.length>0){
            this.flag=true
          }

          if(this.slider.length==1){
            this.hide_flag=true
          }else{
            this.hide_flag=false
          }
        })
  }

}
