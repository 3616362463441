import { Component, ElementRef, OnInit,Inject,Input, ViewChild  } from '@angular/core';
import { HttpclientService } from '../../../../apiservices/httpclient.service';
import { env } from '../../../../config/config';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { AnyARecord } from 'dns';


interface enrollment {
  Name: string;
  Phone: number;
  Email_Id: string;
  Fathername: string;
  Mothername:string
  Address: string;
  Gender:string
  DOB:any
  Degree:string
  Institutionname:string
  Universityname:string
  Passing_year:number
  Mark_grade:string
  Preferred_career_stream: string;
  Preferred_career_path: string;
  uploads:{
Photo:any
Adhaarcard:any
Degree_certificate:any

  }
  }


@Component({
  selector: 'app-enrollment-form',
  templateUrl: './enrollment-form.component.html',
  styleUrls: ['./enrollment-form.component.scss']
})
export class EnrollmentFormComponent implements OnInit {
  public datepicker: ElementRef
  @ViewChild("photo_upload", {static: false})
  InputVar: HTMLElement;
  @Inject(MAT_DIALOG_DATA) public data: any
  dialogRef: MatDialogRef<any>
  panelOpenState = false;
  preferences;
  preferred_path;
  add_enrollment = {} as enrollment;
  flag = false;
  photo:any
  degree_certificate:any
  adhaar_card:any
  inputValue: string = '';
  enrollment_submitted = false;
  enrollmentForm: FormGroup;
  minDate: Date;
  maxDate: Date;
  logo: any;
  qualification: any;
  formData = new FormData();
  constructor(private service: HttpclientService, public dialog: MatDialog,public http:HttpclientService) {
    // Set the minimum to January 1st 20 years in the past and December 31st a year in the future.
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 40, 0, 1);
    this.maxDate = new Date(currentYear + 1, 11, 31);
  }

  ngOnInit(): void {
    // this.service.getAllValues('configurations').subscribe((res) => {
    //   console.log('page', res)
    //   this.config = res
    //   this.config.map((i) => {
    //     if (i.content_key == 'config_email') {
    //       this.config_email = i.content_value
    //     } else if (i.content_key == 'config_phone') {
    //       this.phone = i.content_value
    //     }
    //   })
    //   this.flag = true
    // })

    // this.get_address()
    var element = document.getElementsByClassName('cdk-global-scrollblock')[0];
    if (element) {
      var htmlElement = element as HTMLElement;
      htmlElement.style.top = '0px';
      // Add more style properties as needed
    }


    this.enrollmentForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.pattern('[a-zA-Z ]{2,32}'),
      ]),
      phone: new FormControl('', [
        Validators.required,
        Validators.pattern('[0-9]{10}'),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),
      ]),
      fathername: new FormControl('', [
        Validators.required,
        Validators.pattern('[a-zA-Z ]{2,32}'),
      ]),
      mothername: new FormControl('', [
        Validators.required,
        Validators.pattern('[a-zA-Z ]{2,32}'),
      ]),
      address: new FormControl('', [Validators.required]),
      gender: new FormControl('', [Validators.required]),
      DOB: new FormControl('', [Validators.required]),
      degree: new FormControl('', [Validators.required]),
      institutionname: new FormControl('', [
        Validators.required,
        Validators.pattern('[a-zA-Z ]{2,32}'),
      ]),
      universityname: new FormControl('', [
        Validators.required,
        Validators.pattern('[a-zA-Z ]{2,32}'),
      ]),
      passing_year: new FormControl('', [
        Validators.required,
        Validators.pattern('[0-9]{4}'),
      ]),
      mark_grade : new FormControl('', [
        Validators.required,
        Validators.pattern(/^\d{1,2}(\.\d{1,2})?$/

        ),
      ]),
      Preferredcareer: new FormControl('', [Validators.required]),
      Preferredpath: new FormControl('', [Validators.required]),
      photo:new FormControl('', [Validators.required]),
      adhaarcard:new FormControl('', [Validators.required]),
      degree_certificate:new FormControl('', [Validators.required]),
         });
    this.get_preferences();
    this.get_scheme();
    this.get_keyvalues();
    this.logo = env.apiurl + localStorage.getItem('company_logo');
  }

  get errorControl() {

    return this.enrollmentForm.controls;
  }

  onKeyPress(event: KeyboardEvent) {
    const key = event.key;

    // Allow only alphabetic characters (A-Z, a-z) and prevent other characters
    if (!/^[a-zA-Z ]+$/.test(key)) {
      event.preventDefault();
    }
  }



  enroll() {
    let result;
    this.enrollment_submitted = true;

    if (!this.enrollmentForm.valid ) {
      this.enrollmentForm.markAllAsTouched();
      console.log('Please provide all the required values!');

      return false;

    } else {
      console.log(this.enrollmentForm.controls)
      this.formData.delete('data')
      this.formData.append('data', JSON.stringify({
        Name: this.enrollmentForm.value.name,
        Phone: this.enrollmentForm.value.phone,
        Email_Id: this.enrollmentForm.value.email,
        Fathername: this.enrollmentForm.value.fathername,
        Mothername: this.enrollmentForm.value.mothername,
        Address: this.enrollmentForm.value.address,
        Gender: this.enrollmentForm.value.gender,
        DOB: this.enrollmentForm.value.DOB,
        Degree: this.enrollmentForm.value.degree,
        Institutionname: this.enrollmentForm.value.institutionname,
        Universityname: this.enrollmentForm.value.universityname,
        Passing_year: this.enrollmentForm.value.passing_year,
        Mark_grade: this.enrollmentForm.value.mark_grade,
        Preferred_career_stream: this.enrollmentForm.value.Preferredcareer,
        Preferred_career_path: this.enrollmentForm.value.Preferredpath
      }));

           this.service.add_enrollment(this.formData).subscribe((res) => {
        console.log(res);
        if (res) {
          this.enrollmentForm.reset();
          this.openDialog('Enrolment form has been submitted successfully.',true,'');
          setTimeout(() => {
            this.closeDialog(false);
          }, 2000);
          this.service.send_mail(this.formData,'Enrol-form').subscribe(
            (res) => {

              console.log(res)
            },
            (error) => {
              console.log(error);
            }
          );
        }

      });
    }
  }

  get_preferences() {
    this.service.getAllValues('labs-services').subscribe((res) => {
      this.preferences = res;
      console.log(res);
      if (this.preferences.length > 0) {
        this.preferences.map((i) => {
          i.panelOpenState = false;
        });
        this.flag = true;
      }
    });
  }

  get_keyvalues() {
    this.service.getconfigValue('qualifications').subscribe((res) => {
      console.log(res);
      this.qualification = res[0].content_value.split(',');
      console.log(this.qualification);
    });

  }

  get_scheme() {
    this.service.getAllValues('schemes').subscribe((res) => {
      this.preferred_path = res;
      console.log(res);
      if (this.preferences.length > 0) {
        this.preferences.map((i) => {
          i.panelOpenState = false;
        });
        this.flag = true;
      }
    });
  }


  openDialog(message:string,icon_flag:boolean,warn_flag:any) {
    console.log(message,icon_flag,warn_flag)
  this.dialogRef =  this.dialog.open(DialogElementsExampleDialog, {
      width: '30rem',
      height: '11rem',

      data:{
        message:message,
        success:icon_flag,
        warn:warn_flag
      }
    });

  }

  closeDialog(successpopup) {

    if(successpopup){
      this.dialogRef.close()
    }else{
      this.dialog.closeAll();
    }

  }


  async onFileSelected(event: any,type:any) {

    const file = event.target.files[0];
    console.log(file)
      if (file) {
      try {
        let validate_file: any;
        validate_file = await this.http.validate_file(file);
        if (validate_file.flag == 'valid') {

          for (var i = 0; i < event.target.files.length; i++) {
            if(this.formData.get(`${type}`)){
              this.formData.delete(`${type}`)
            }
            this.formData.append(`files.${type}`,file,file.name);
                  }
                  if(type=='Aadhaarcard'){
                    type=='Aadhaar card'
                  }else if(type=='Degree_certificate'){
                    type='Degree Certificate'
                  }

      this.openDialog(`${type} upload completed successfully`,true,'')
          setTimeout(() => {
            this.closeDialog(true);
          }, 2000);
        } else if (validate_file.flag == 'type_mismatch') {
          this.openDialog('Invalid file format, Kindly upload any of JPEG, PNG and PDF',false,'warning')
          this.upload_changetrigger(type)

        } else if (validate_file.flag == 'size_exceed') {
          this.openDialog('Exceeded maximum file size, Kindly upload the file with size less than 5MB',false,'warning')
          this.upload_changetrigger(type)
        }
      } catch (error) {
        console.log(error);
      }
    }
  }


upload_changetrigger(type:any){
    if(type=='Photo'){
    this.photo=''
    console.log('photo_clear')
  }else if(type=='Aadhaar card'){
    console.log('adhar_clear')
    this.adhaar_card=''
  }else{
    this.degree_certificate=''
  }
    setTimeout(() => {
      this.closeDialog(true);
      if(type=='Photo'){
        console.log('photo triggered')
        document.getElementById('photo_upload').click()

      }else if(type=='Aadhaar card'){
        console.log('adhaarcard_upload triggered')
        document.getElementById('adhaarcard_upload').click()
      }else{
        console.log('degree_certificate_upload triggered')
        document.getElementById('degree_certificate_upload').click()
      }

    }, 2000)
}


}

@Component({
  selector: 'dialog-elements-example-dialog',
  template: `
    <div class="icon">
      <i class="{{data.success?'fa fa-check-circle':'fa fa-times-circle'}}" aria-hidden="true"></i>
    </div>
    <div mat-dialog-content style="text-align: center;
    margin: 1rem;">{{data.message}}</div>
    <div mat-dialog-actions></div>
  `,
  styles: [
    '.fa-check-circle{color:green; font-size:50px;}.icon,.mat-dialog-content{text-align:center}.fa-times-circle{color:red; font-size:50px;}',
  ],
})
export class DialogElementsExampleDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    console.log('Received data:', data);
  }

}
