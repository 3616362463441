import { Component, OnInit, ViewEncapsulation } from '@angular/core'
declare var TradeZone : any;
@Component({
  selector: 'app-servicedetailpage',
  templateUrl: './servicedetailpage.component.html',
  styleUrls: ['./servicedetailpage.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ServicedetailpageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    this.move_top()
    TradeZone.init();
  }
  move_top() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}
