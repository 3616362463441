import { Component } from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import { SEOService } from './apiservices/seo.service';
import { Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = '6Solve';
  service: any

  constructor(
    private router: Router,
    private _seoService: SEOService,
    private meta: Meta
    ) {
      this.meta.addTags([
        { name: 'description', content: '' },
        { name: 'title', content: '' },
        { name: 'keywords', content: '' },
        { charset: 'UTF-8' },
      ]);
     }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
      let seodetails
      seodetails = this.service
        .get_category(
          location.href.split('/')[location.href.split('/').length - 1]
        )
        .subscribe((data) => {
          seodetails = data
          this._seoService.updateTitle(seodetails.meta_title)
          this._seoService.updateKeywords(seodetails.meta_keywords)
          this._seoService.updateDescription(seodetails.meta_description)
        })
    });
  }
}
