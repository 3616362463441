<div class="section-full content-inner-1 bg-img-fix bg-white" id="projects">
	<div class="container">
		<div class="row">
			<div class="col-lg-12 section-head">
				<h5 class="title-small">HOW WE WORK?</h5>
				<div class="dlab-separator-outer out-center">
					<div class="dlab-separator bg-primary style-skew"></div>
				</div>
				<h2 class="title">Latest From Our Work</h2>
			</div>
		</div>
        <div class="row align-items-center serviceorder" *ngFor="let item of content; index as j">
			<div class="col-lg-6 col-md-12 m-b30 steel-about service-order align-self-start" *ngIf="flag">
						<img src={{url+item.image.url}} class="service-image img-fluid rounded control-width" alt="aboutus_work-{{j}}"/>
			</div>
			<div class="col-lg-6 m-b10 steel-about servicecontent" *ngIf="flag">
				<div class="section-head m-b20 text-center">
					<h2 class="title">{{item.title}}</h2>
				</div>
				<p>{{item.description}}</p>
			</div>
		</div>
	</div>
</div>

