<div class="section-full bg-white content-inner" id="team" style="background-image:url(assets/images/pattern/pt7.png);">
    <div class="container">
        <div class="col-lg-12 m-b30 content-about">
        <div class="section-head m-b20 text-center">
            <h5 class="title-small">{{subtitle}}</h5>
            <div class="dlab-separator-outer">
                <div class="dlab-separator bg-primary style-skew"></div>
            </div>
            <h2 class="title">{{title}}</h2>
        </div>  
    </div>
    <div class="row">
        <div class="col-md-12 col-12">
            <owl-carousel-o [options]="customOptions" class="testimonial-box-carousel2 owl-dots-black-full owl-theme owl-btn-center-lr owl-btn-3 btn-white">
                <ng-template carouselSlide *ngFor = "let slide of content; index as j">
                    <div class="slide p-a5">
                        <div class="testimonial-box1">
                            <div class="testimonial-top">
                                <div class="testimonial-pic radius">
                                    <img src={{url+slide.profile.url}} width="100" height="100" alt="aboutus_tesimonials_image-{{j}}">	
                                </div>
                                <div class="testimonial-detail"> 
                                    <strong class="testimonial-name">{{slide.customer_name}}</strong> 
                                    <span class="testimonial-position">{{slide.customer_designation}},{{slide.customer_company}}.</span>
                                    <span class="testimonial-position">{{slide.location}}.</span>  
                                    <ul class="star-review">
                                        <li><i class="fa fa-star"></i></li>
                                        <li><i class="fa fa-star"></i></li>
                                        <li><i class="fa fa-star"></i></li>
                                        <li><i class="fa fa-star"></i></li>
                                        <li><i class="fa fa-star"></i></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="testimonial-text">
                                <p>{{slide.testimonial_content}}</p>
                            </div>
                            <div class="testimonial-quote">
                                <i class="fa fa-quote-right"></i>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
    </div>
    </div>