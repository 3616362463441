
    <header class="site-header header center mo-left">
	
		<div class="top-bar">
			<div class="container">
				<div class="row d-flex justify-content-between align-items-center">
					<div class="dlab-topbar-left">
						<ul>
							<li class="m-r5"><i class="fa fa-phone m-r5"></i> +91 456 872 1230</li>
							<li><i class="fa fa-envelope-o m-r5"></i> exemple@gmail.com</li>
						</ul>
					</div>
					<div class="dlab-topbar-right topbar-social">
						<span class="m-r10">Follows Us :</span>
						<ul>
							<li class="m-l5"><a href="#" class="site-button sharp-sm sharp-sm"><i class="fa fa-facebook"></i></a></li>
							<li class="m-l5"><a href="#" class="site-button sharp-sm google-plus"><i class="fa fa-google-plus"></i></a></li>
							<li class="m-l5"><a href="#" class="site-button sharp-sm twitter"><i class="fa fa-twitter"></i></a></li>
							<li class="m-l5"><a href="#" class="site-button sharp-sm instagram"><i class="fa fa-instagram"></i></a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
        <div class="sticky-header main-bar-wraper navbar-expand-lg">
            <div class="main-bar clearfix ">
                <div class="container clearfix">
               
                    <div class="logo-header mostion">
						<a routerLink="/"><img src="assets/images/logo-center-light.png" class="logo1" alt=""><img src="assets/images/logo-center-light.png" class="logo2" alt=""></a>
					</div>
                   
                    <button class="navbar-toggler collapsed navicon justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
						<span></span>
						<span></span>
						<span></span>
					</button>
                 
                    <div class="header-nav navbar-collapse collapse justify-content-between onepage" id="navbarNavDropdown">
						<div class="logo-header mostion">
							<a  routerLink="/"><img src="assets/images/logo-4.png" alt=""></a>
						</div>
						<ul class="nav navbar-nav d-flex justify-content-end navbar">	
							<li><a href="#home" class="scroll nav-link active">Home <i class="fa fa-chevron-down"></i></a>
								<ul class="sub-menu">
									<li><a [routerLink] = "''">Home construction</a></li>
									<li><a [routerLink] = "'/index-food-factory'">Home Food Industry</a></li>
									<li><a [routerLink] = "'/index-agriculture'">Home Agriculture</a></li>
									<li><a [routerLink] = "'/index-steel-plant'">Home Steel Plant</a></li>
									<li><a [routerLink] = "'/index-solar-plant'">Home Solar Plant</a></li>
								</ul>
							</li>
							<li><a href="#about-us" class="scroll nav-link">About Us</a></li>
							<li><a href="#portfolio" class="scroll nav-link">Portfolio</a></li>
						</ul>
						<ul class="nav navbar-nav d-flex justify-content-start navbar">	
							<li><a href="#client" class="scroll nav-link">Client Says</a></li>
							<li><a href="#team" class="scroll nav-link">Team</a></li>
							<li><a href="#news" class="scroll nav-link">News</a></li>
						</ul>
						<div class="dlab-social-icon">
							<ul>
								<li><a class="site-button facebook circle-sm outline fa fa-facebook" href="javascript:void(0);"></a></li>
								<li><a class="site-button twitter circle-sm outline fa fa-twitter" href="javascript:void(0);"></a></li>
								<li><a class="site-button linkedin circle-sm outline fa fa-linkedin" href="javascript:void(0);"></a></li>
								<li><a class="site-button instagram circle-sm outline fa fa-instagram" href="javascript:void(0);"></a></li>
							</ul>
						</div>
                    </div>
                </div>
            </div>
        </div>
   
    </header>

