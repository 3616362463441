
<div class="container form-container">
<div class="row">
  <div class="col-lg-12 col-12 col-md-12 m-b30">

      <div mat-dialog-title class="logo-section m-b30 text-left">

            <div class="d-flex justify-content-between align-items-center">
              <div class="logo-div">
                <img src="{{logo}}" class="object-fit company-logo" />
              </div>

              <i class="fa fa-close" mat-dialog-close></i>
            </div>
      </div>


      <mat-dialog-content class="dialog-content">

        <form class="contact-box dzForm" [formGroup]="enrollmentForm">
          <div class="section-head  text-left">
            <h2  class="title">Enrollment form</h2>
          </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-form-field appearance="outline" class="first">
            <mat-label>Name</mat-label>
            <input
              matInput
              type="text"
              placeholder="Your Name"
              formControlName="name"
              maxlength="32"
              required

              (keypress)="onKeyPress($event)"
            />

            <mat-error
              class="error"
              *ngIf="
                enrollment_submitted && errorControl.name.errors?.required
              "
            >
              Name is required.
            </mat-error>
            <mat-error
              class="error"
              *ngIf="
                enrollment_submitted && errorControl.name.errors?.pattern
              "
            >
            Must contain only alphabets of minimum 2 characters to maximum 32 characters
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-lg-6 col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>Contact Number</mat-label>
             <input
              matInput
              type="tel"
              placeholder="Contact Number"
              formControlName="phone"
              required
              onkeypress="return event.charCode >= 48 && event.charCode <= 57 && this.value.length!=10"
            />

            <mat-error
              class="error"
              *ngIf="
                enrollment_submitted && errorControl.phone.errors?.required
              "
            >
            Contact number is required.
            </mat-error>
            <mat-error
              class="error"
              *ngIf="
                enrollment_submitted && errorControl.phone.errors?.pattern
              "
            >
              Please enter a valid 10 digit Contact number.
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-lg-6 col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>Email Address</mat-label>
            <input
              matInput
              placeholder="Email Address"
              formControlName="email"
              required
            />

            <mat-error
              class="error"
              *ngIf="
                enrollment_submitted && errorControl.email.errors?.required
              "
            >
            Email address is required.
            </mat-error>
            <mat-error
              class="error"
              *ngIf="
                enrollment_submitted && errorControl.email.errors?.pattern
              "
            >
              Please enter a valid Email address.
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-lg-6 col-md-12">
          <mat-form-field appearance="outline" class="first">
            <mat-label>Father's Name</mat-label>
            <input
              matInput
              type="text"
              placeholder="Father's Name"
              formControlName="fathername"
              maxlength="32"
              required

              (keypress)="onKeyPress($event)"
            />

            <mat-error
              class="error"
              *ngIf="
                enrollment_submitted && errorControl.fathername.errors?.required
              "
            >
            Father's name is required.
            </mat-error>
            <mat-error
              class="error"
              *ngIf="
                enrollment_submitted && errorControl.fathername.errors?.pattern
              "
            >
            Must contain only alphabets of minimum 2 characters to maximum 32 characters
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-lg-6 col-md-12">
          <mat-form-field appearance="outline" class="first">
            <mat-label>Mother's Name</mat-label>
            <input
              matInput
              type="text"
              placeholder="Mother's Name"
              formControlName="mothername"
              maxlength="32"
              required

              (keypress)="onKeyPress($event)"
            />

            <mat-error
              class="error"
              *ngIf="
                enrollment_submitted && errorControl.mothername.errors?.required
              "
            >
            Father's name is required.
            </mat-error>
            <mat-error
              class="error"
              *ngIf="
                enrollment_submitted && errorControl.mothername.errors?.pattern
              "
            >
            Must contain only alphabets of minimum 2 characters to maximum 32 characters
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-lg-6 col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>Address *</mat-label>
            <input
              matInput
              placeholder="Type Your Address here"
              formControlName="address"
            >
            <mat-error
            class="error"
            *ngIf="
              enrollment_submitted &&
              errorControl.address.errors?.required
            "
          >
          Address is required.
          </mat-error>
          </mat-form-field>
        </div>

        <div class="col-lg-6 col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>Gender *</mat-label>
            <mat-select formControlName="gender">
              <mat-option
                value="Male"

              >
              Male
              </mat-option>
              <mat-option
              value="Female"

            >
            Female
            </mat-option>
            <mat-option
            value="Transgender"

          >
          Transgender
          </mat-option>
            </mat-select>
            <mat-error
              class="error"
              *ngIf="
                enrollment_submitted &&
                errorControl.gender.errors?.required
              "
            >
            Gender is required.
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-lg-6 col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>Date of Birth</mat-label>
              <input id="datepicker" matInput [min]="minDate" readonly="true" [matDatepicker]="datepicker" formControlName="DOB" required="true"
              (click)="datepicker.open()"
              (focus)="datepicker.open()"
              (keydown)="datepicker.open()">
              <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
              <mat-datepicker #datepicker></mat-datepicker>
            <mat-error
              class="error"
              *ngIf="
                enrollment_submitted && errorControl.DOB.errors?.required
              "
            >
            Date of birth is required.
            </mat-error>

          </mat-form-field>
        </div>




<div class="col-md-12 col-lg-12">
<label>Details of Highest Educational Qualification</label>
<hr>
<div class="row">
  <div class="col-lg-6 col-md-12">
    <mat-form-field appearance="outline">
      <mat-label>Degree *</mat-label>
      <mat-select formControlName="degree">
        <mat-option
          [value]="i"
          *ngFor="let i of qualification"
        >
          {{ i }}
        </mat-option>
      </mat-select>
      <mat-error
        class="error"
        *ngIf="
          enrollment_submitted &&
          errorControl.degree.errors?.required
        "
      >
      Degree is required.
      </mat-error>
    </mat-form-field>
  </div>

  <div class="col-lg-6 col-md-12">
    <mat-form-field appearance="outline" class="first">
      <mat-label>Name of Institution</mat-label>
      <input
        matInput
        type="text"
        placeholder="Name of Institution"
        formControlName="institutionname"
        maxlength="90"
        required

        (keypress)="onKeyPress($event)"
      />

      <mat-error
        class="error"
        *ngIf="
          enrollment_submitted && errorControl.institutionname.errors?.required
        "
      >
      Name of institution is required.
      </mat-error>
      <mat-error
        class="error"
        *ngIf="
          enrollment_submitted && errorControl.institutionname.errors?.pattern
        "
      >
      Must contain only alphabets
      </mat-error>
    </mat-form-field>
  </div>

  <div class="col-lg-6 col-md-12">
    <mat-form-field appearance="outline" class="first">
      <mat-label>Name of University</mat-label>
      <input
        matInput
        type="text"
        placeholder="Name of University"
        formControlName="universityname"
        maxlength="90"
        required
        (keypress)="onKeyPress($event)"
      />

      <mat-error
        class="error"
        *ngIf="
          enrollment_submitted && errorControl.universityname.errors?.required
        "
      >
      Name of university is required.
      </mat-error>
      <mat-error
        class="error"
        *ngIf="
          enrollment_submitted && errorControl.universityname.errors?.pattern
        "
      >
      Must contain only alphabets
      </mat-error>
    </mat-form-field>
  </div>
  <div class="col-lg-6 col-md-12">
    <mat-form-field appearance="outline" class="first">
      <mat-label>Year of passing</mat-label>
      <input
       class="no-spin"
        matInput
        type="number"
        placeholder="Year of passing"
        formControlName="passing_year"
        maxlength="4"
        required
        onkeypress="return event.charCode >= 48 && event.charCode <= 57 && this.value.length!=4"
      />

      <mat-error
        class="error"
        *ngIf="
          enrollment_submitted && errorControl.passing_year.errors?.required
        "
      >
      Year of passing is required.
      </mat-error>
      <mat-error
        class="error"
        *ngIf="
          enrollment_submitted && errorControl.passing_year.errors?.pattern
        "
      >
      Please enter the year of passing in four digit format
      </mat-error>
    </mat-form-field>
  </div>
  <div class="col-lg-6 col-md-12">
    <mat-form-field appearance="outline" class="first">
      <mat-label>Mark/Grade</mat-label>
      <input
        matInput
        class="no-spin"
        type="number"
        placeholder="Mark/Grade"
        formControlName="mark_grade"
        maxlength="4"
        required
        onkeypress="return event.charCode >= 46 && event.charCode <= 57  && this.value.length!=4"
      />

      <mat-error
        class="error"
        *ngIf="
          enrollment_submitted && errorControl.mark_grade.errors?.required
        "
      >
      Mark/Grade is required.
      </mat-error>
      <mat-error
        class="error"
        *ngIf="
          enrollment_submitted && errorControl.mark_grade.errors?.pattern
        "
      >
      Please enter the valid mark/grade
      </mat-error>
    </mat-form-field>
  </div>
</div>
</div>

<div class="col-lg-6 col-md-12">
  <mat-form-field appearance="outline">
    <mat-label>Preferred Career Stream *</mat-label>
    <mat-select formControlName="Preferredcareer">
      <mat-option
        [value]="i.title"
        *ngFor="let i of preferences"
      >
        {{ i.title }}
      </mat-option>
    </mat-select>
    <mat-error
      class="error"
      *ngIf="
        enrollment_submitted &&
        errorControl.Preferredcareer.errors?.required
      "
    >
      Career stream is required.
    </mat-error>
  </mat-form-field>
</div>

<div class="col-lg-6 col-md-12">
  <mat-form-field appearance="outline">
    <mat-label>Preferred Career Path *</mat-label>
    <mat-select formControlName="Preferredpath">
      <mat-option
        [value]="i.title"
        *ngFor="let i of preferred_path"
      >
        {{ i.title }}
      </mat-option>
    </mat-select>
    <mat-error
      class="error"
      *ngIf="
        enrollment_submitted &&
        errorControl.Preferredpath.errors?.required
      "
    >
      Career Path is required.
    </mat-error>
  </mat-form-field>
</div>

<div class="col-lg-12 col-md-12">
<label>Uploads</label>
<hr>
<div class="notes">
<label>Note</label>
<ul>
  <li>Acceptable file types: PNG, PDF & JPG</li>
  <li>Maximum file size : 5MB</li>
</ul>
</div>
<div class="row m-t15">
  <div class="col-lg-4 col-md-12  col-sm-12 col-12 m-b10 m-lg-0">
    <!-- <mat-form-field appearance="outline" class="first">-->
      <!-- <td class="strong"><span class="m-r10">:</span></td> -->
      <div id="table-notes" class="d-flex">

            <div> <mat-label for="photo"   >Photo :</mat-label></div>
            <div>
              <input
              type="file"
              class="uploads"
              id="photo_upload"
              placeholder="Photo"
              formControlName="photo"
              (change)="onFileSelected($event,'Photo')"
              required
              [(ngModel)]="photo"
              name="photo"
            /></div>

            </div>

      <span
        class="error"
        *ngIf="
          enrollment_submitted && errorControl.photo.errors?.required
        "
      >
      Photo is required.
      </span>

    <!-- </mat-form-field> -->
  </div>

  <div class="col-lg-4 col-md-12  col-sm-12 col-12 m-b10 m-lg-0">
    <!-- <mat-form-field appearance="outline" class="first">-->
      <div  id="table-notes" class="d-flex">

            <div><mat-label   for="adhaarcard" >Aadhaar card :</mat-label></div>
            <div> <input
              type="file"
              class="uploads"
              id="adhaarcard_upload"
              (change)="onFileSelected($event,'Aadhaarcard')"
              placeholder="Aadhaar card"
              formControlName="adhaarcard"
              required
              [(ngModel)]="adhaar_card"
              name="adhaarcard"
            />
      </div>

          </div>

      <span
        class="error"
        *ngIf="
          enrollment_submitted && errorControl.adhaarcard.errors?.required
        "
      >
      Aadhaar card is required.
      </span>

    <!-- </mat-form-field> -->
  </div>

  <div class="col-lg-4 col-md-12  col-sm-12  col-12 m-b10 m-lg-0">
    <!-- <mat-form-field appearance="outline" class="first"> -->
      <div  id="table-notes">

            <div> <mat-label  for="degree_certificate" >Degree Certificate :</mat-label></div>
            <div> <input
              type="file"
              class="uploads"
              id="degree_certificate_upload"
              placeholder="Degree Certificate"
              formControlName="degree_certificate"
              (change)="onFileSelected($event,'Degree_certificate')"
              required
              [(ngModel)]="degree_certificate"
              name="degree_certificate"
            /></div>

      </div>



      <span
        class="error"
        *ngIf="
          enrollment_submitted && errorControl.degree_certificate.errors?.required
        "
      >
      Degree certificate is required.
      </span>

    <!-- </mat-form-field> -->
  </div>

</div>
</div>

      </div>
    </form>
    </mat-dialog-content>

    <mat-dialog-actions align="end" class="dialog-action">
      <div class="d-flex">
      <a class="site-button white " mat-dialog-close>Close<span  class="circle1"></span></a>
           <a
                name="submit"
                type="submit"
                value="Submit"
                class="site-button white"
                (click)="enroll()"
              >
                Submit<span  class="circle1"></span>
              </a>
              </div>
    </mat-dialog-actions>

  </div>
</div>


</div>
