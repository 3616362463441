
<div class="section-full content-inner content-inner-padding bg-white">
  <div class="container" id="services">
    <div class="section-head text-left">
      <!-- <h5 class="title-small">OUR SERVICES</h5> -->
      <!-- <div class="dlab-separator-outer">
        <div class="dlab-separator bg-primary style-skew"></div>
      </div> -->
      <h2 class="title">Career Streams</h2>
    </div>
    <div class="row justify-content-center" *ngIf="flag">
      <div
        *ngFor="let item of serviceList;  index as k"
        class="col-lg-4 col-md-6 col-sm-6 m-b30 wow fadeInUp cardd-con"
        data-wow-duration="2s"
        data-wow-delay="0.3s"
      >
      <!-- (click)="service_detail(item)" -->
        <div class="cardd-content" >
          <div class="text-center">
            <p class="textclap-new">{{ item.short_desc }}</p>
            <a *ngIf="item.Button_txt" class="site-button  white m-t40" (click)="openDialog()">{{ item.Button_txt }} <span _ngcontent-qtu-c105="" class="circle"></span></a>
          </div>
        </div>
        <div class="dlab-box service-box-6">
          <div class="dlab-media dlab-fixedhgt card">
            <a href="javascript:void(0);" class="anchor">
              <img
                src="{{ url + item.service_image.url }}"
                class="card-img-top rounded mx-auto img-fluid"
                alt="home_ourservice_image-{{k}}"
              />
            </a>
          </div>

          <div
            class="servicesdetail service maxwidth center_content"
            id="service"
            #service
            [ngClass]="set_height(service)"
          >
            <div class="cardd">
              <h4>{{ item.title }}</h4>
              <p class="textclap">{{ item.short_desc }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

