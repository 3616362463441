import { Component, OnInit } from '@angular/core';
import {HttpclientService} from '../../../../apiservices/httpclient.service'
import {env} from '../../../../config/config'
import {EnquiryFormComponent} from '../../contact/enquiry-form/enquiry-form.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-team-labs',
  templateUrl: './team-labs.component.html',
  styleUrls: ['./team-labs.component.scss']
})
export class TeamLabsComponent implements OnInit {
  content
  flag=false
    url=env.apiurl
  constructor(private service:HttpclientService, public dialog: MatDialog,) { }

  ngOnInit() {
    this.get_allsec()
  }

  openDialog() {
    this.dialog.open(EnquiryFormComponent,{width: '100%',maxWidth:'100%'});
  }
  // scrollTo(section) {
  //   document.querySelector('#' + section)
  //   .scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  // }
  get_allsec(){
    this.service.getAllValues('eligibilities').subscribe(res => {
      this.content=res[0]
      console.log(this.content)
      if(this.content.length>0){
        this.flag=true
      }
    })
  }
}
