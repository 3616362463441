<link
  class="skin"
  rel="stylesheet"
  type="text/css"
  href="/assets/css/skin/skin-4.css"
/>
<div class="page-wraper">
  <app-header-four></app-header-four>
  <div class="page-content bg-white">
    <app-slider-four></app-slider-four>
    <div class="content-block">
      <app-about-four></app-about-four>
      <app-service-four></app-service-four>
      <app-team-four></app-team-four>
    </div>
  </div>
  <app-footer-four></app-footer-four>
  <button class="scroltop style2 radius" type="button" (click)="move_top()">
    <i class="fa fa-arrow-up"></i>
  </button>
</div>

