import { Component, OnInit } from '@angular/core';
import { HttpclientService } from '../../../apiservices/httpclient.service';
import { env } from '../../../config/config';
import { EnquiryFormComponent } from '../contact/enquiry-form/enquiry-form.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-scheme',
  templateUrl: './scheme.component.html',
  styleUrls: ['./scheme.component.scss'],
})
export class SchemeComponent implements OnInit {
  content;
  flag = false;
  url = env.apiurl;

  constructor(private service: HttpclientService, public dialog: MatDialog) {}

  ngOnInit() {
    this.get_allsec();
  }
  // scrollTo(section) {
  //   document.querySelector('#' + section)
  //   .scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  // }
  openDialog() {
    this.dialog.open(EnquiryFormComponent, { width: '100%', maxWidth: '100%' });
  }
  get_allsec() {
    this.service.getAllValues('schemes').subscribe((res) => {
      this.content = res;
      console.log(this.content);
      if (this.content.length > 0) {
        this.flag = true;
      }
    });
  }
}
