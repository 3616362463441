
<header class="site-header mo-left construct-header header">
	
	<div class="top-bar">
		<div class="container">
			<div class="row d-flex justify-content-between align-items-center">
				<div class="dlab-topbar-left">
					<ul>
						<li><a href="javascript:void(0);"><i class="fa fa-envelope-o m-r5 tetx-primary"></i>contactus@6solve.com</a></li>
					</ul>
				</div>
				<div class="dlab-topbar-right">
					<ul>
						<li><a href="javascript:void(0);">About Us</a></li>
						<li><a href="javascript:void(0);">Refund Policy</a></li>
						<li><a href="javascript:void(0);">Help Desk</a></li>
					</ul>
				</div>
			</div>
		</div>
	</div>
	<div class="sticky-header main-bar-wraper navbar-expand-lg ">
		<div class="main-bar clearfix onepage">
			<div class="container clearfix">
		
				<div class="logo-header mostion">
					<a routerLink="/"><img src="assets/images/6solve_logo.png" alt=""></a>
				</div>
		
				<button class="navbar-toggler collapsed navicon justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
					<span></span>
					<span></span>
					<span></span>
				</button>
		
				<div class="extra-nav">
					<div class="extra-cell">
						<a routerLink="/contact-page" class="site-button align-self-center ml-auto button-style-2 primary">
							<span>Get in Touch</span>
							<i class="la la-long-arrow-alt-right"></i>
						</a>

					</div>
				</div>
		
				<div class="dlab-quik-search ">
					<form action="#">
						<input name="search" value="" type="text" class="form-control" placeholder="Type to search">
						<span id="quik-search-remove"><i class="ti-close"></i></span>
					</form>
				</div>
		
				<div class="header-nav navbar-collapse collapse justify-content-end onepage" id="navbarNavDropdown">
					<div class="logo-header d-md-block d-lg-none">
						<a href="index.html"><img src="assets/images/logo.png" alt=""></a>
					</div>
					<ul class="nav navbar-nav navbar">
						<li><a href="#home" class="scroll nav-link active">Home <i class="fa fa-chevron-down"></i></a>
							<ul class="sub-menu">
								<li><a [routerLink] = "''">Home construction</a></li>
								<li><a [routerLink] = "'/index-food-factory'">Home Food Industry</a></li>
								<li><a [routerLink] = "'/index-agriculture'">Home Agriculture</a></li>
								<li><a [routerLink] = "'/index-steel-plant'">Home Steel Plant</a></li>
								<li><a [routerLink] = "'/index-solar-plant'">Home Solar Plant</a></li>
							</ul>
						</li>
						<li><a href="#about-us" class="scroll nav-link">About Us</a></li>
						<li><a href="#services" class="scroll nav-link">Services</a></li>
						<li><a href="#projects" class="scroll nav-link">Projects</a></li>
						<li><a href="#team" class="scroll nav-link">Team</a></li>
						<li><a href="#client" class="scroll nav-link">Client Says</a></li>
						<li><a href="#blog" class="scroll nav-link">Blog</a></li>
					</ul>
					<div class="dlab-social-icon ind-social">
						<ul>
							<li *ngFor="let list of socialList">
								<a [ngClass]="['site-button-link', list.icons]" href="javascript:void(0);">
								</a>
							</li>
						</ul>
						<p>2019 Tradezone</p>
					</div>
				</div>
			</div>
		</div>
	</div>

</header>

