<div class="section-full content-inner bg-white" id="projects" style="background-image:url(assets/images/background/food/bg2.jpg);">
	<div class="setResizeMargin food-project">
		<div class="section-head text-left">
			<h5 class="title-small">Our Project</h5>
			<div class="dlab-separator-outer">
				<div class="dlab-separator bg-primary style-skew"></div>
			</div>
			<h2 class="title">Latest From Our Gallery</h2>
		</div>
		<div class="content-section">
			<owl-carousel-o [options]="customOptions" class="owl-carousel project-carousel-bx1 owl-loaded owl-theme owl-dots-black-full">
				<ng-template carouselSlide *ngFor = "let slide of slideStore">
					<div class="slide">
						<div class="dlab-box dlab-gallery-box">
							<div class="dlab-media"> 
								<a href="javascript:void(0);"><img src="assets/images/gallery/food/{{slide.image}}" alt=""></a>
								<div class="product-info">
									<div class="title">
										<h2><a href="javascript:void(0);">{{slide.name}}</a></h2>
									</div>
								</div>
							</div>
						</div>
					</div>			
				</ng-template>
			</owl-carousel-o>
		</div>
	</div>
</div>

