import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { HttpclientService } from '../apiservices/httpclient.service';
import { HistoryService } from 'src/app/apiservices/history.service';
import { env } from '../config/config';

declare var TradeZone: any;
import { FormControl } from '@angular/forms';
interface Pokemon {
  value: string;
  viewValue: string;
}

interface PokemonGroup {
  disabled?: boolean;
  name: string;
  pokemon: Pokemon[];
}

@Component({
  selector: 'app-whitepaper',
  templateUrl: './whitepaper.component.html',
  styleUrls: ['./whitepaper.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class WhitepaperComponent implements OnInit {
  content;
  section;
  url;
  sec_flag = false;
  flag = false;
  selected;
  current_service = 'all';
  subtitle = '';
  title = 'Whitepapers';
  description =
    'Demystifying a complex technology landscape Are you facing tough IT decisions? Get insights from our White papers';
  whitepaper = [];
  list = [];
  buttontext = 'Read More';
  pokemons = [];
  filtering;
  constructor(
    private service: HttpclientService,
    private router: Router,
    private history: HistoryService
  ) {
    this.pokemons = [
      { value: 'bulbasaur-0', viewValue: 'Bulbasaur' },
      { value: 'oddish-1', viewValue: 'Oddish' },
      { value: 'bellsprout-2', viewValue: 'Bellsprout' },
    ];
  }

  ngOnInit(): void {
    this.move_top();
    this.url = env.apiurl;
    this.service.getAllValues('whitepaper-2nd-sections').subscribe((res) => {
      this.section = res;
      if (this.section.length > 0) {
        this.sec_flag = true;
      }
    });
    let arr1 = [];
    let arr2 = [];
    let id_arr = [];
    let paper_arr: any = [];
    this.service.getAllValues('services').subscribe((res) => {
      // const services = res
      // this.content = res;
      // console.log(this.content, 'services');
      // if (Array.isArray(services) && services.length > 0) {
      // }
    });
    this.service.get_papers().subscribe((res) => {
      paper_arr = res;
      const avilableServices = [];
      if (paper_arr.length > 0) {
        paper_arr = paper_arr.sort((a, b) => b.id - a.id);
        this.list = paper_arr;
        this.whitepaper = [];
        for (let p in paper_arr) {
          if (paper_arr[p].service == null) {
            paper_arr[p].service_name = 'Generic';
            paper_arr[p].service = { title: 'Generic' };
            this.whitepaper.push(paper_arr[p]);
          } else {
            paper_arr[p].service_name = paper_arr[p].service.title;
            this.whitepaper.push(paper_arr[p]);
          }
          avilableServices.push({ title: paper_arr[p].service_name });
          this.content = [
            ...new Set(avilableServices.map((item) => JSON.stringify(item))),
          ].map((item) => JSON.parse(item));
        }

        if (this.whitepaper.length > 0) {
          this.flag = true;
        }
      }
    });
  }

  get_paper(e) {
    this.history.white_paper(e);
    this.router.navigateByUrl(`/whitepaper/${e.slug}/${e.id}`);
  }

  filter() {
    this.filtering = this.list.filter((i) => {
      if (i.service != null) {
        return i.service.title == this.selected;
      }
    });
    console.log(this.filtering);
    this.whitepaper = this.filtering;
  }
  all_services() {
    this.whitepaper = this.list;
  }

  move_top() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
