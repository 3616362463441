import { Component, OnInit } from '@angular/core';
import { HttpclientService } from '../../../../apiservices/httpclient.service';
import { env } from '../../../../config/config';

@Component({
  selector: 'app-footer-four',
  templateUrl: './footer-four.component.html',
  styleUrls: ['./footer-four.component.css'],
})
export class FooterFourComponent implements OnInit {
  addresslist;
  config;
  pages;
  logo;
  button_text;
  config_email;
  footer_text;
  phone;
  our_services;
  flag = false;
  labs_email:any
  labs_phone:any
  address_flag = false;
  socialmedia_title = 'Social Media';

  // contactList: any = [
  //   {
  //     icon: 'ti ti-location-pin',
  //     address: 'Address20 , New York 10010',
  //   },
  //   {
  //     icon: 'ti ti-mobile',
  //     address: 'Phone 0800-123456',
  //   },
  //   {
  //     icon: 'ti ti-email',
  //     address: 'Email info@example.com',
  //   },
  // ]

  post_title = 'Contact Us';
  // latestPost: any = [
  //   {
  //     image: 'pic1.jpg',
  //     title: 'Helping you and your house become better.',
  //     author_name: "By <a href='javascript:void(0);'>Jone</a>",
  //     comment: '28',
  //   },
  //   {
  //     image: 'pic1.jpg',
  //     title: 'Creating quality urban lifestyles.',
  //     author_name: "By <a href='javascript:void(0);'>Jone</a>",
  //     comment: '28',
  //   },
  // ]

  company_title = 'Company';
  serviceList: any = [
    { title: 'About Us', url: '/about-us' },
    { title: 'Careers', url: '/careers-in-6solve' },
    { title: 'LABs', url: '/labs' },
    { title: 'Contact Us', url: '/contact-us' },

  ];

  constructor(private service: HttpclientService) {}

  ngOnInit(): void {
    this.get_config();
    this.get_address();
  }

  get_config() {
    this.service.getAllValues('configurations').subscribe((res) => {
      console.log('page', res);
      this.config = res;
      this.config.map((i) => {
        if (i.content_key == 'pages') {
          this.pages = i.content_value.split(',');
        } else if (i.content_key == 'company_logo') {
          this.logo = env.apiurl + i.logos[0].url;
          localStorage.removeItem('company_logo')
          localStorage.setItem('company_logo', i.logos[0].url);
          console.log(this.logo);
        } else if (i.content_key == 'header_button_text') {
          this.button_text = i.content_value;
        } else if (i.content_key == 'config_email') {
          this.config_email = i.content_value;
        } else if (i.content_key == 'config_phone') {
          this.phone = i.content_value;
        } else if (i.content_key == 'footer_text') {
          this.footer_text = i.content_value;
        } else if (i.content_key == 'whatsapp_number') {
          localStorage.removeItem('whatsapp_number')
          localStorage.setItem('whatsapp_number', i.content_value);
          this.labs_phone=i.content_value
        }else if(i.content_key == 'labs_email_id'){
this.labs_email= i.content_value
        }
      });
      this.flag = true;
    });
  }

  get_address() {
    this.service.get_main_address().subscribe((res) => {
      this.addresslist = res;
      if (this.addresslist.length > 0) {
        this.address_flag = true;
      }
    });
  }
}
