<div class="section-full content-inner-2 bg-gray" id="client" >
	<div class="container">
		<div class="row" *ngIf="flag || double_flag">
			<div class="col-lg-12 section-head  text-black">
				<h5 class="title-small">WHITE PAPERS</h5>
				<div class="dlab-separator-outer service-white">
					<div class="dlab-separator bg-white style-skew"></div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12 whiteroll" *ngIf="double_flag">
				<div class="card-body" *ngFor = "let slide of servicelist.white_papers">
				<a class="text-decoration-none" (click)="get_paper(slide)" target="_blank">
					<div class="hover-resource singles">
					<img class="rounded mx-auto img-fluid" src={{url+slide.image.url}} alt="Two">
					<div>
					<p class="font-weight-bold text-white-75">{{slide.title}}</p>
					<p>{{slide.short_desc}}</p>
					<i _ngcontent-iba-c168="" aria-hidden="true" class="fa fa-chevron-right icon-arrow fa-gradient"></i>
					</div>
					</div>
					</a>
				</div>
		</div>
			<div class="col-lg-12" *ngIf="flag">
				<owl-carousel-o [options]="customOptions" class="testimonial-box-carousel2 owl-dots-black-full owl-theme owl-btn-center-lr owl-btn-3 btn-white">
					<ng-template carouselSlide *ngFor = "let slide of servicelist.white_papers">
						<a class="text-decoration-none" (click)="get_paper(slide)" target="_blank">
							<div class="hover-resource">
							<img class="rounded mx-auto img-fluid" src={{url+slide.image.url}} alt="Two">
							<div>
							<p class="font-weight-bold text-white-75">{{slide.title}}</p>
							<p>{{slide.short_desc}}</p>
							<i _ngcontent-iba-c168="" aria-hidden="true" class="fa fa-chevron-right icon-arrow fa-gradient"></i>
							</div>
							</div>
						</a>
					</ng-template>
				</owl-carousel-o>
			</div>
		</div>
	</div>
</div>
