import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  sub_services
  white_papers

  constructor() { }

  set_services(e){
    this.sub_services=e
    localStorage.setItem("current_service",JSON.stringify(e))
  }


  get_services(){
    // return this.sub_services
    return JSON.parse(localStorage.getItem("current_service"));
  }

  white_paper(e){
    this.white_papers=e
    localStorage.setItem("current_paper",JSON.stringify(e))
  }

  get_paper(){
    return JSON.parse(localStorage.getItem("current_paper"))
  }
}
