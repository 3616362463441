<div class="widget">
	<h5 class="widget-title style-1">{{title}}</h5>
	<div class="search-bx">
		<form role="search" method="post">
			<div class="input-group">
				<input name="text" type="text" class="form-control" placeholder="Write your text">
				<span class="input-group-btn">
					<button type="submit" class="site-button">
						<i class="{{icon}}"></i>
					</button>
				</span> 
			</div>
		</form>
	</div>
</div>
