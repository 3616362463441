
<div class="section-full content-service bg-gray" id="about-us" style="background-image: url(assets/images/pattern/pt13.png);" *ngIf="flag">
	<div class="container">
        <div class="section-head text-center">
			<h5 class="title-small">OUR SERVICES</h5>
			<div class="dlab-separator-outer">
				<div class="dlab-separator bg-primary style-skew"></div>
			</div>
		
		</div>
		<div class="row align-items-center serviceorder" *ngFor="let item of servicelist; index as j">
			<div class="col-lg-6 col-md-12 m-b30 steel-about center imgmaxmin  service-order align-self-start" >
				
					
						<img src={{url+item.service_image.url}} class="service-image  img-fluid rounded" alt="service_ourservice_image-{{j}}"/>
					
					
			
			</div>
			<div class="col-lg-6 m-b30 steel-about servicecontent">
				<div class="section-head m-b10 text-center">
				
					
					<h2 class="title">{{item.title}}</h2>
				</div>
				<div class="service-bullet">
					<ul>
						<li *ngFor="let i of item.sub_services">{{i.title}}</li>
		
						</ul>
				</div>
			
				<div class="float-right"> 
				<a  class="site-button" (click)="service_detail(item)">Read More <i class="fa fa-chevron-right" aria-hidden="true"></i> <span class="circle"></span></a>
  
				</div>
				
			</div>
		</div>
	</div>
</div>
