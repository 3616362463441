import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slider-one',
  templateUrl: './slider-one.component.html',
  styleUrls: ['./slider-one.component.css']
})
export class SliderOneComponent implements OnInit {
	

  constructor() { 
  }

  ngOnInit(): void {
  }

}
