<div class="section-full content-inner bg-gray" >
	<div class="container">
		<div class="row align-items-center">
			<div class="col-lg-12 m-b30 content-about">
				<div class="section-head m-b20 text-center">
					<h5 class="title-small">{{subtitle}}</h5>
					<div class="dlab-separator-outer ">
						<div class="dlab-separator bg-primary style-skew"></div>
					</div>
				</div>
                <span *ngFor="let i of content" >
                    <div class="section-head m-b20 text-center">
                      <h1 class="title">{{i.title}}</h1>
                    </div>
				    <p>{{i.long_desc}}</p>
                </span>
				
			</div>
		</div>
	</div>
</div>
