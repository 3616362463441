import { Component, ElementRef, HostListener, OnInit,Inject } from '@angular/core';
import { HttpclientService } from '../../../../apiservices/httpclient.service';
import { env } from '../../../../config/config';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { MatDialog,MatDialogRef,
  MAT_DIALOG_DATA, } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
interface enquiry {
  Student_name: string;
  Phone: number;
  Email_Id: string;
  Intended_date: Date;
  Preferred_career_stream: string;
  Preferred_career_path: string;
  Education_qualification: string;
  Specific_information: string;
  Message: string;
  Address: string;
  Heared_about_us: string;
  Resume:any
}

@Component({
  selector: 'app-enquiry-form',
  templateUrl: './enquiry-form.component.html',
  styleUrls: ['./enquiry-form.component.css'],
})
export class EnquiryFormComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  public innerWidth: any;
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
  formData = new FormData();
  @Inject(MAT_DIALOG_DATA) public data: any
  dialogRef: MatDialogRef<any>
  public datepicker: ElementRef
  panelOpenState = false;
  preferences;
  preferred_path;
  add_enquiry = {} as enquiry;
  flag = false;
  inputValue: string = '';
  enquiry_submitted = false;
  enquiryForm: FormGroup;
  minDate: Date;
  maxDate: Date;
  logo: any;
  qualification: any;
  Specific_information: any;
  Heared_about_us: any;
  flag_other_specify: boolean = false;
  flag_other_about:boolean=false
  resume:any
  constructor(private service: HttpclientService, public dialog: MatDialog,private datePipe: DatePipe) {
    // Set the minimum to January 1st 20 years in the past and December 31st a year in the future.
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 20, 0, 1);
    this.maxDate = new Date(currentYear + 1, 11, 31);
  }

  ngOnInit(): void {
    // this.service.getAllValues('configurations').subscribe((res) => {
    //   console.log('page', res)
    //   this.config = res
    //   this.config.map((i) => {
    //     if (i.content_key == 'config_email') {
    //       this.config_email = i.content_value
    //     } else if (i.content_key == 'config_phone') {
    //       this.phone = i.content_value
    //     }
    //   })
    //   this.flag = true
    // })

    // this.get_address()
    var element = document.getElementsByClassName('cdk-global-scrollblock')[0];
    if (element) {
      var htmlElement = element as HTMLElement;
      htmlElement.style.top = '0px';
      // Add more style properties as needed
    }
    this.enquiryForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.pattern('[a-zA-Z ]{2,32}'),
      ]),
      phone: new FormControl('', [
        Validators.required,
        Validators.pattern('[0-9]{10}'),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),
      ]),

      Preferredcareer: new FormControl('', [Validators.required]),
      Preferredpath: new FormControl('', [Validators.required]),
      message: new FormControl(),
      intended_date: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      qualification: new FormControl('', [Validators.required]),
      Specific_information: new FormControl(),
      Heared_about_us: new FormControl(),
      other_specific:this.flag_other_specify? new FormControl('', [Validators.required]):new FormControl(),
      other_about: this.flag_other_about? new FormControl('', [Validators.required]):new FormControl(),
      Resume: new FormControl(),
    });
    this.get_preferences();
    this.get_scheme();
    this.get_keyvalues();
    this.logo = env.apiurl + localStorage.getItem('company_logo');
  }

  get errorControl() {
    // if (this.enquiryForm.valid) {
    //   document
    //     .getElementById('enquire-submit')
    //     .setAttribute('data-dismiss', 'modal');
    // }



    return this.enquiryForm.controls;
  }

  onKeyPress(event: KeyboardEvent) {
    const key = event.key;

    // Allow only alphabetic characters (A-Z, a-z) and prevent other characters
    if (!/^[a-zA-Z ]+$/.test(key)) {
      event.preventDefault();
    }
  }


  changedetect(key:any){
    if(key=='specific'){

    if (this.enquiryForm.value.Specific_information == 'Others') {
      this.flag_other_specify = true;
    }else{
      this.flag_other_specify = false;
    }
  }else{
    if (this.enquiryForm.value.Heared_about_us == 'Others') {
      this.flag_other_about = true;
    }else{
      this.flag_other_about = false;
    }
  }
  }

  enquiries() {
    let result;
    this.enquiry_submitted = true;

    if (!this.enquiryForm.valid) {
      this.enquiryForm.markAllAsTouched();
      console.log('Please provide all the required values!');
      console.log( this.enquiryForm.value.intended_date,)
      return false;
    } else {
      this.formData.delete('data')
      this.formData.append('data', JSON.stringify({
        Student_name :this.enquiryForm.value.name,
        Phone :this.enquiryForm.value.phone,
        Email_Id :this.enquiryForm.value.email,
        Preferred_career_stream :
          this.enquiryForm.value.Preferredcareer,
        Preferred_career_path :
          this.enquiryForm.value.Preferredpath,
        Education_qualification :
          this.enquiryForm.value.qualification,
        Intended_date :this.enquiryForm.value.intended_date,
        Message :this.enquiryForm.value.message,
        Specific_information :this.flag_other_specify?this.enquiryForm.value.other_specific:
          this.enquiryForm.value.Specific_information,
        Heared_about_us :this.flag_other_about?this.enquiryForm.value.other_about:this.enquiryForm.value.Heared_about_us,
        Address :this.enquiryForm.value.address,
        Resume: this.enquiryForm.value.Resume
      }))

      this.service.add_enquiry(this.formData).subscribe((res) => {
        console.log(res);

        if (res) {
          this.service.send_mail(this.formData,'Enquiry-form').subscribe(
            (res) => {},
            (error) => {
              console.log(error);
            }
          );
        }
        this.enquiryForm.reset();
        this.openDialog(` Enquiry form submitted successfully`,true,'');
        setTimeout(() => {
          this.closeDialog(false);
        }, 2000);
      });
    }
  }

  get_preferences() {
    this.service.getAllValues('labs-services').subscribe((res) => {
      this.preferences = res;
      console.log(res);
      if (this.preferences.length > 0) {
        this.preferences.map((i) => {
          i.panelOpenState = false;
        });
        this.flag = true;
      }
    });
  }

  get_keyvalues() {
    this.service.getconfigValue('qualifications').subscribe((res) => {
      console.log(res);
      this.qualification = res[0].content_value.split(',');
      console.log(this.qualification);
    });

    this.service.getconfigValue('Heared_about_us').subscribe((res) => {
      console.log(res);
      this.Heared_about_us = res[0].content_value.split('/');
      console.log(this.qualification);
    });

    this.service.getconfigValue('Specific_information').subscribe((res) => {
      console.log(res);
      this.Specific_information = res[0].content_value.split('/');
      console.log(this.qualification);
    });
  }

  get_scheme() {
    this.service.getAllValues('schemes').subscribe((res) => {
      this.preferred_path = res;
      console.log(res);
      if (this.preferences.length > 0) {
        this.preferences.map((i) => {
          i.panelOpenState = false;
        });
        this.flag = true;
      }
    });
  }
  // get_address() {
  //   this.service.get_address().subscribe((res) => {

  //     this.addresslist = res
  //     console.log(this.addresslist)
  //     if (this.addresslist.length > 0) {
  //       this.address_flag = true

  //     }
  //   })
  // }

  async onFileSelected(event: any,type:any) {

    const file = event.target.files[0];
    console.log(file)
      if (file) {
      try {
        let validate_file: any;
        validate_file = await this.service.validate_file(file);
        if (validate_file.flag == 'valid') {

          for (var i = 0; i < event.target.files.length; i++) {
            if(this.formData.get(`${type}`)){
              this.formData.delete(`${type}`)
            }
            this.formData.append(`files.${type}`,file,file.name);
                  }

      this.openDialog(`${type} upload completed successfully`,true,'')
          setTimeout(() => {
            this.closeDialog(true);
          }, 2000);
        } else if (validate_file.flag == 'type_mismatch') {
          this.openDialog('Invalid file format, Kindly upload any of JPEG, PNG and PDF',false,'warning')
          this.upload_changetrigger(type)

        } else if (validate_file.flag == 'size_exceed') {
          this.openDialog('Exceeded maximum file size, Kindly upload the file with size less than 5MB',false,'warning')
          this.upload_changetrigger(type)
        }
      } catch (error) {
        console.log(error);
      }
    }
  }


upload_changetrigger(type:any){

    this.resume=''

    setTimeout(() => {
      this.closeDialog(true);

        document.getElementById('resume').click()


    }, 2000)
}

openDialog(message:string,icon_flag:boolean,warn_flag:any) {
  console.log(message,icon_flag,warn_flag)
this.dialogRef =  this.dialog.open(DialogElementsExampleDialog, {
    width: '30rem',
    height: '11rem',

    data:{
      message:message,
      success:icon_flag,
      warn:warn_flag
    }
  });

}

closeDialog(successpopup) {

  if(successpopup){
    this.dialogRef.close()
  }else{
    this.dialog.closeAll();
  }

}
}

@Component({
  selector: 'dialog-elements-example-dialog',
  template: `
    <div class="icon">
      <i class="{{data.success?'fa fa-check-circle':'fa fa-times-circle'}}" aria-hidden="true"></i>
    </div>
    <div mat-dialog-content style="text-align: center;
    margin: 1rem;">{{data.message}}</div>
    <div mat-dialog-actions></div>
  `,
  styles: [
    '.fa-check-circle{color:green; font-size:50px;}.icon,.mat-dialog-content{text-align:center}.fa-times-circle{color:red; font-size:50px;}',
  ],
})
export class DialogElementsExampleDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    console.log('Received data:', data);
  }

}
