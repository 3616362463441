import { Component, OnInit } from '@angular/core';
import {HttpclientService} from '../../../../apiservices/httpclient.service'
import { HistoryService } from 'src/app/apiservices/history.service';
import {env} from '../../../../config/config'

@Component({
  selector: 'app-service-detail-slider',
  templateUrl: './service-detail-slider.component.html',
  styleUrls: ['./service-detail-slider.component.css']
})
export class ServiceDetailSliderComponent implements OnInit {

  sub_service
  url
  flag=false
  hide_flag=false

  constructor(private service:HttpclientService,private history:HistoryService) { }

  ngOnInit(): void {
    this.url=env.apiurl
    this.sub_service=this.history.get_services()
    console.log(this.sub_service)
    if(this.sub_service.banner_image.length>0){
      this.flag=true
    }

    if(this.sub_service.banner_image.length==1){
      this.hide_flag=true
    }else{
      this.hide_flag=false
    }
    
  }

}
