import { Component, OnInit } from '@angular/core'
import { HttpclientService } from '../../../../apiservices/httpclient.service'
import { env } from '../../../../config/config'
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-about-four',
  templateUrl: './about-four.component.html',
  styleUrls: ['./about-four.component.css'],
})
export class AboutFourComponent implements OnInit {
  iscale = []
  currentActive = -1
  content
  flag = false

  subtitle = 'Welcome to 6Solve IT Services Private Ltd'
  title = 'Enabling a dynamic future'
  description =
    'We are an IT solution provider based out of South India.  We are an 100% agile company providing IT solutions using new age technologies. We are successful in providing cutting  edge solution for business problems using our 6 core themes.'

  itemList: any[] = [
    { list: 'This Is How Steel Industry Will Look Like In 10 Years Time.' },
    { list: "The Rank Of Steel Industry In Consumer's Market." },
    { list: 'You Will Never Believe These Bizarre Truth Of Steel Industry.' },
  ]


  constructor(
    private service: HttpclientService,
    private router: Router,
   private snap_route: ActivatedRoute,) {
    this.iscale = [
      {
        image: 'assets/images/iscale/img 1.png',
        scale: 'i',
        title: 'Intelligent',
        content: 'Make a decision about the cooperation',
      },
      {
        image: 'assets/images/iscale/img 2.png',
        scale: 'S',
        title: 'Simple',
        content: 'Make a decision about the cooperation',
      },
      {
        image: 'assets/images/iscale/img 3.png',
        scale: 'C',
        title: 'Cognitive',
        content: 'Make a decision about the cooperation',
      },
      {
        image: 'assets/images/iscale/img 4.png',
        scale: 'A',
        title: 'Agile',
        content: 'Make a decision about the cooperation',
      },
      {
        image: 'assets/images/iscale/img 5.png',
        scale: 'L',
        title: 'Lean',
        content: 'Make a decision about the cooperation',
      },
      {
        image: 'assets/images/iscale/img 6.png',
        scale: 'E',
        title: 'Educate',
        content: 'Make a decision about the cooperation',
      },
    ]
  }

  ngOnInit(): void {
    this.service.getAllValues('home-page-section-2-s').subscribe((res) => {
      this.content = res
      if (this.content.length > 0) {
        this.flag = true
      }
    })
  }
  scrollTo(section) {
    document.querySelector('#' + section)
    .scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }
navigateTo(){
  this.router.navigate(['/contact-us']).then(() => {
    // Ensure that the element is available

    setTimeout(()=>{
      this.scrollTo('contactus');
    },500);

  });
}

  active(e) {
    this.currentActive = e
  }
}
