import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexSteelPlantComponent } from './index-steel-plant/index-steel-plant.component';
import { ContactPageComponent } from './inner-pages/contact-page/contact-page.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ComponentModule } from './component/component.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ServicepageComponent } from './servicepage/servicepage.component';
import { ServicedetailpageComponent } from './servicedetailpage/servicedetailpage.component';
import { AboutuspageComponent } from './aboutuspage/aboutuspage.component';
import { HttpClientModule } from '@angular/common/http';
import { CareerpageComponent } from './careerpage/careerpage.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ContactuspageComponent } from './contactuspage/contactuspage.component';
import { WhitepaperComponent } from './whitepaper/whitepaper.component';
import { WhitepaperdetailComponent } from './whitepaperdetail/whitepaperdetail.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { Meta } from '@angular/platform-browser';
import { LabsComponent } from './labs/labs.component';
import { ScrollSpyDirective } from './scroll-spy.directive';
import { FormsPageComponent } from './forms-page/forms-page.component';

@NgModule({
  declarations: [
    AppComponent,
    IndexSteelPlantComponent,
    ContactPageComponent,
    ServicepageComponent,
    ServicedetailpageComponent,
    AboutuspageComponent,
    CareerpageComponent,
    ContactuspageComponent,
    WhitepaperComponent,
    WhitepaperdetailComponent,
    LabsComponent,
    ScrollSpyDirective,
    FormsPageComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ComponentModule,
    CarouselModule,
    CommonModule,
    NgbModule,
    NgxPageScrollModule,
    FontAwesomeModule,
    HttpClientModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSelectModule,
    MatDialogModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private meta: Meta) {}
}
