<header class="site-header mo-left header-transparent header agro-header">
  <div class="sticky-header main-bar-wraper navbar-expand-lg">
    <div class="main-bar clearfix">
      <div class="container clearfix">
        <div class="logo-header mostion">
          <a routerLink="/"><img src="{{ logo }}" alt="logo" /></a>
        </div>
        <button
          class="navbar-toggler  navicon justify-content-end {{
            openhumberger ? 'collapsed' : ''
          }}"
          type="button"
          data-toggle="collapse"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
          (click)="open_humberger()"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div class="extra-nav">
          <div class="extra-cell d-flex">
            <a
              (click)="navigateTo()"
              class="header-qoute-btn site-button white align-self-center"
            >
              {{ button_text }}
              <span class="circle"></span>
            </a>
            <div
              class="contactdropdown"
              (mouseover)="showcontact()"
              (mouseout)="showcontact()"
            >
              <a class="scroll nav-link bacgroundcolor">
                <i
                  _ngcontent-aba-c137=""
                  aria-hidden="true"
                  class="fa fa-phone"
                ></i>
              </a>
              <ul class="sub-menu {{ contact_show ? 'show' : '' }}">
                <li>
                  <a href="tel:{{ +971521123221 }}" class="emaildrop">
                    <div class="icon_div">
                      <img
                        src="/assets/images/flags/UAE_flag.png"
                        alt="India Office"
                        class="flags-size"
                      />
                    </div>

                    UAE: +971-521123221
                  </a>
                </li>
                <li>
                  <a href="tel:{{ phone }}" class="emaildrop">
                    <div class="icon_div">
                      <img
                        src="/assets/images/flags/in.svg"
                        alt="India Office"
                        class="flags-size"
                      />
                    </div>

                    India: {{ phone }}
                  </a>
                </li>
                <li>
                  <a href="mailto:{{ config_email }}" class="emaildrop">
                    <div class="icon_div">
                      <i
                        _ngcontent-xsu-c137=""
                        aria-hidden="true"
                        class="fa fa-envelope"
                      ></i>
                    </div>

                    {{ config_email }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div
          class="header-nav navbar-collapse  justify-content-end onepage  {{
            openhumberger ? 'show' : ''
          }}"
        >
          <div class="logo-header d-md-block d-lg-none">
            <a routerLink="/"><img src="{{ logo }}" alt="logo" /></a>
          </div>
          <ul class="nav navbar-nav navbar">
            <li>
              <a
                [routerLink]="'/'"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                class="scroll nav-link"
                id="home"
                (click)="move_top()"
              >
                Home
              </a>
            </li>
            <li [ngClass]="open_flag ? 'open' : ''">
              <a
                id="service_link"
                (click)="service_page($event)"
                [routerLink]="screenWidth >= 992 ? ['/services'] : []"
                routerLinkActive="active"
                class="scroll nav-link"
              >
                Services
                <i class="fa fa-chevron-down" id="arrow"></i>
              </a>

              <ul class="sub-menu menu_width" *ngIf="flag">
                <li
                  (click)="service_detail(i)"
                  [ngClass]="current_title == i.title ? 'active' : ''"
                  *ngFor="let i of servicelist"
                >
                  <a>{{ i.title }}</a>
                </li>
              </ul>
            </li>
            <li>
              <a
                [routerLink]="'/about-us'"
                [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive="active"
                class="scroll nav-link"
                (click)="move_top()"
              >
                About Us
              </a>
            </li>
            <li>
              <a
                [routerLink]="'/careers-in-6solve'"
                [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive="active"
                class="scroll nav-link"
                (click)="move_top()"
              >
                Careers
              </a>
            </li>
            <li>
              <a
                [routerLink]="'/whitepapers'"
                [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive="active"
                class="scroll nav-link"
              >
                Whitepapers
              </a>
            </li>
            <li>
              <a
                [routerLink]="'/labs'"
                [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive="active"
                class="scroll nav-link"
                (click)="move_top()"
              >
                LABs
              </a>
            </li>
            <li>
              <a
                [routerLink]="'/contact-us'"
                [routerLinkActiveOptions]="{ exact: true }"
                routerLinkActive="active"
                class="scroll nav-link"
                (click)="move_top()"
              >
                Contact Us
              </a>
            </li>
          </ul>
          <div class="dlab-social-icon">
            <ul>
              <li>
                <a href="https://www.facebook.com/6Solve" target="_blank">
                  <div class="">
                    <i class="fab fa-facebook-f"></i>
                    <span class="circle"></span>
                  </div>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/6solve" target="_blank">
                  <div class="">
                    <i class="fab fa-twitter"></i>
                    <span class="circle"></span>
                  </div>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/6solve/" target="_blank">
                  <div>
                    <i class="fab fa-instagram"></i>
                    <span class="circle"></span>
                  </div>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/6solve-it-services-pvt-ltd-559b09197/"
                  target="_blank"
                >
                  <div>
                    <i class="fa fa-linkedin" aria-hidden="true"></i>
                    <span class="circle"></span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
