
<div class="section-full content-inner-1 bg-img-fix bg-white" id="projects">
	<div class="container">
		<div class="row">
			<div class="col-lg-12 section-head">
				<h5 class="title-small">CREATIVE PROJECTS</h5>
				<div class="dlab-separator-outer">
					<div class="dlab-separator bg-primary style-skew"></div>
				</div>
				<h2 class="title">Latest From Our Work</h2>
			</div>
		</div>
	</div>
	<div class="row spno">
		<div class="col-lg-3 col-md-4 col-sm-5 col-6">
			<div class="dlab-box dlab-portfolio-bx">
				<div class="dlab-media"> 
					<a href="javascript:void(0);"> <img src="assets/images/our-work/construct/pic1.jpg" alt=""> </a>
					<div class="overlay-bx">
						<div class="port-box align-b">
							<h5 class="title"><a href="javascript:void(0)">Rooms & halls</a></h5>
							<p>Branding and Brochure</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-lg-6 col-md-8 col-sm-7 col-6">
			<div class="dlab-box dlab-portfolio-bx">
				<div class="dlab-media"> 
					<a href="javascript:void(0);"> <img src="assets/images/our-work/construct/pic2.jpg" alt=""> </a>
					<div class="overlay-bx">
						<div class="port-box align-b">
							<h5 class="title"><a href="javascript:void(0)">Renovation</a></h5>
							<p>Branding and Brochure</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-lg-3 col-md-6 col-sm-6 col-6">
			<div class="dlab-box dlab-portfolio-bx">
				<div class="dlab-media"> 
					<a href="javascript:void(0);"> <img src="assets/images/our-work/construct/pic3.jpg"  alt=""> </a>
					<div class="overlay-bx"> 
						<div class="port-box align-b">
							<h5 class="title"><a href="javascript:void(0)">Construction</a></h5>
							<p>Branding and Brochure</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-lg-3 col-md-6 col-sm-6 col-6">
			<div class="dlab-box dlab-portfolio-bx">
				<div class="dlab-media"> 
					<a href="javascript:void(0);"> <img src="assets/images/our-work/construct/pic4.jpg"  alt=""> </a>
					<div class="overlay-bx"> 
						<div class="port-box align-b">
							<h5 class="title"><a href="javascript:void(0)">Tailoring Interior</a></h5>
							<p>Branding and Brochure</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-lg-3 col-md-4 col-sm-4 col-12">
			<div class="dlab-box dlab-portfolio-bx">
				<div class="dlab-media"> 
					<a href="javascript:void(0);"> <img src="assets/images/our-work/construct/pic5.jpg"  alt=""> </a>
					<div class="overlay-bx">
						<div class="port-box align-b">
							<h5 class="title"><a href="javascript:void(0)">Professional opinion</a></h5>
							<p>Branding and Brochure</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-lg-3 col-md-4 col-sm-4 col-6">
			<div class="dlab-box dlab-portfolio-bx">
				<div class="dlab-media"> 
					<a href="javascript:void(0);"> <img src="assets/images/our-work/construct/pic6.jpg"  alt=""> </a>
					<div class="overlay-bx">
						<div class="port-box align-b">
							<h5 class="title"><a href="javascript:void(0)">Accurate Engineering</a></h5>
							<p>Branding and Brochure</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-lg-3 col-md-4 col-sm-4 col-6">
			<div class="dlab-box dlab-portfolio-bx">
				<div class="dlab-media"> 
					<a href="javascript:void(0);"> <img src="assets/images/our-work/construct/pic7.jpg"  alt=""> </a>
					<div class="overlay-bx">
						<div class="port-box align-b">
							<h5 class="title"><a href="javascript:void(0)">General Builder</a></h5>
							<p>Branding and Brochure</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

