import { Component, OnInit } from '@angular/core';
import {HttpclientService} from '../../../../../apiservices/httpclient.service'
import {env} from '../../../../../config/config'

@Component({
  selector: 'app-slider-labs',
  templateUrl: './slider-labs.component.html',
  styleUrls: ['./slider-labs.component.scss']
})
export class SliderLabsComponent implements OnInit {

  slider:any
  env:any
  flag=false
  hide_flag=false
  constructor(private service:HttpclientService) { }

  ngOnInit() {
    this.get_banner()
    this.env=env.apiurl
  }
  get_banner(){
    let arr
    this.service.getBanner("labs").subscribe(res => {

          arr=res

          this.slider=arr[0].image
          console.log(this.slider)
          console.log(env+this.slider[0].url)
          if(this.slider.length>0){
            this.flag=true
          }
          if(this.slider.length==1){
            this.hide_flag=true
          }else{
            this.hide_flag=false
          }
        })
  }

}
