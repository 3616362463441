<div class="section-full contactus bg-gray">
  <div class="container" id="contactus">
    <div class="section-head m-b30 text-center">

      <h1 class="title-small">Contact Us</h1>
      <div class="dlab-separator-outer">
        <div class="dlab-separator bg-primary style-skew"></div>
      </div>

    </div>
    <div class="row">
      <div class="col-lg-8 col-md-12 m-b30">
        <form class="contact-box dzForm" [formGroup]="contactForm">

          <div class="dzFormMsg m-b20"></div>
          <input type="hidden" value="Contact" name="dzToDo" />
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <mat-form-field appearance="outline" class="first">
                <mat-label>Your Name</mat-label>
                <input matInput placeholder="Your Name" formControlName="name" required />

                <mat-error class="error" *ngIf="
                    contact_submitted && errorControl.name.errors?.required
                  ">
                  Name is required.
                </mat-error>
                <mat-error class="error" *ngIf="contact_submitted && errorControl.name.errors?.pattern">
                  Name must be between 2 and 32 characters.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-lg-6 col-md-12">
              <mat-form-field appearance="outline">
                <mat-label>Phone/Mobile</mat-label>
                <input matInput placeholder="Phone/Mobile" formControlName="phone" required />

                <mat-error class="error" *ngIf="
                    contact_submitted && errorControl.phone.errors?.required
                  ">
                  Mobile number is required.
                </mat-error>
                <mat-error class="error" *ngIf="
                    contact_submitted && errorControl.phone.errors?.pattern
                  ">
                  Please enter a valid 10 digit mobile number.
                </mat-error>
              </mat-form-field>

            </div>
            <div class="col-lg-6 col-md-12">
              <mat-form-field appearance="outline">
                <mat-label>Email address</mat-label>
                <input matInput placeholder="Email address" formControlName="email" required />

                <mat-error class="error" *ngIf="
                    contact_submitted && errorControl.email.errors?.required
                  ">
                  Email address is required.
                </mat-error>
                <mat-error class="error" *ngIf="
                    contact_submitted && errorControl.email.errors?.pattern
                  ">
                  Please enter a valid email address.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-12 col-md-12">
              <mat-form-field appearance="outline">
                <mat-label>Message</mat-label>
                <textarea matInput placeholder="Type Your Message here" formControlName="message"></textarea>
              </mat-form-field>

            </div>

            <div class="col-lg-12 col-md-12">
              <a name="submit" type="submit" value="Submit" class="site-button align-self-center ml-auto"
                (click)="contact()">
                <span>Submit</span>
                <span class="circle"></span>
              </a>


            </div>
          </div>
        </form>
      </div>
      <div class="col-lg-4 col-md-12">
        <ul class="contact-question">
          <li>
            <i ngClass="fa fa-envelope-o"></i>
            <h4 class="title">Email</h4>
            <p>
              <a href="mailto:{{ config_email }}">{{ config_email }}</a>
            </p>

          </li>
          <li>
            <i ngClass="fa fa-phone"></i>
            <h4 class="title">Phone</h4>
            <p>
              <span>UAE : </span> <a href="tel: +971-521123221 ">+971-521123221</a>
            </p>
            <p>
             <span>India : </span> <a href="tel:{{ phone }}">{{ phone }}</a>
            </p>

          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="our_office">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div _ngcontent-ngc-c151="" class="section-head m-b30 text-center">
                <h5 _ngcontent-ngc-c151="" class="title-small">Our Office</h5>
                <div _ngcontent-ngc-c151="" class="dlab-separator-outer">
                  <div _ngcontent-ngc-c151="" class="dlab-separator bg-primary style-skew"></div>
                </div>
              </div>
            </div>

            <div class="col-md-3 mt-3 mb-3 mb-md-0" *ngFor="let item of addresslist">
              <p class="locat_title">
                <b>{{ item.country=='India'? item.district:item.country}}</b>
              </p>
              <div class="address">
                <p>

                  <span>{{ item.address_line_1 + ", " }}</span>

                  <span *ngIf="
                      item.address_line_2 != null && item.address_line_2 != ''
                    ">
                    {{ item.address_line_2 + ", " }}
                  </span><br>
                  <span *ngIf="item.district != null && item.district != ''">
                    {{ item.district }}
                  </span>
                  <span *ngIf="item.pincode != null && item.pincode != '' && item.pincode>0 ">
                    - {{ item.pincode + ", " }}
                  </span>
                  <span *ngIf="item.state != null && item.state != ''">
                    {{ item.state + ", " }}
                  </span>
                  <span *ngIf="item.country != null && item.country != ''">
                    {{ item.country+"." }}
                  </span>
                </p>
              </div>
            <!-- <div class="mail">
              <table class="table-one">
                <tr>
                  <p>
                    <td><i class="fa-solid fa-envelope fa-icon"></i> </td>
                    <td>:</td>
                    <td> <a href="mailto:{{ item.contact_email }}">
                        {{ item.contact_email }}
                      </a>
                    </td>
                  </p>
                </tr>
              </table>

              <table class="table-two">
                <tr>
                  <p>
                    <td><i class="fa-solid fa-phone fa-icon"></i></td>
                    <td>:</td>
                    <td>
                      <a href="telto:{{ item.contact_number }}">
                        {{ item.contact_number }}
                      </a>
                    </td>
                  </p>
                </tr>
              </table>
            </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="widget">
          <ul class="thsn-timelist-list mm-t5">
            <li>
              <a href="https://www.facebook.com/6Solve" target="_blank">
                <div class="site-button social">
                  <i class="fab fa-facebook-f face"></i>
                  <span class="circle"></span>
                </div>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/6solve" target="_blank">
                <div class="site-button social">
                  <i class="fab fa-twitter"></i>
                  <span class="circle"></span>
                </div>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/6solve/" target="_blank">
                <div class="site-button social">
                  <i class="fab fa-instagram"></i>
                  <span class="circle"></span>
                </div>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/6solve-it-services-pvt-ltd/mycompany/" target="_blank">
                <div class="site-button social">
                  <i class="fa fa-linkedin" aria-hidden="true"></i>
                  <span class="circle"></span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
