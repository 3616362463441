<div class="section-full" id="services">
	<div class="row spno about-industry">
		<div class="col-lg-3 col-md-6 col-sm-6 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.6s">
			<div class="dlab-post-media dlab-img-effect zoom"> 
				<img src="assets/images/gallery/food/pic1.jpg" alt="" class="img-cover">
			</div>
		</div>
		<div class="col-lg-3 col-md-6 col-sm-6 bg-secondry text-white wow fadeIn" data-wow-duration="2s" data-wow-delay="0.8s">
			<div class="service-box style2">
				<div>
					<h2 class="title">Cookies</h2>
					<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
					<a href="javascript:void(0);" class="site-button outline white outline-2 btnhover11 radius-no">ABOUT US</a>
				</div>
			</div>
		</div>
		<div class="col-lg-3 col-md-6 col-sm-6 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.2s">
			<div class="dlab-post-media dlab-img-effect zoom"> 
				<img src="assets/images/gallery/food/pic2.jpg" alt="" class="img-cover">
			</div>
		</div>
		<div class="col-lg-3 col-md-6 col-sm-6 bg-primary text-white wow fadeIn" data-wow-duration="2s" data-wow-delay="0.4s">
			<div class="service-box style2">
				<div>
					<h2 class="title">Bear cup</h2>
					<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
					<a href="javascript:void(0);" class="site-button outline white outline-2 btnhover11 radius-no">ABOUT US</a>
				</div>
			</div>
		</div>
		<div class="col-lg-3 col-md-6 col-sm-6 bg-primary text-white wow fadeIn" data-wow-duration="2s" data-wow-delay="0.8s">
			<div class="service-box style2 right">
				<div>
					<h2 class="title text-black">Chikan</h2>
					<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
					<a href="javascript:void(0);" class="site-button outline white outline-2 btnhover11 radius-no">ABOUT US</a>
				</div>
			</div>
		</div>
		<div class="col-lg-3 col-md-6 col-sm-6 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.6s">
			<div class="dlab-post-media dlab-img-effect zoom"> 
				<img src="assets/images/gallery/food/pic3.jpg" alt="" class="img-cover">
			</div>
		</div>
		<div class="col-lg-3 col-md-6 col-sm-6 bg-gray wow fadeIn" data-wow-duration="2s" data-wow-delay="0.2s">
			<div class="service-box style2 right">
				<div>
					<h2 class="title text-black">Burger</h2>
					<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
					<a href="javascript:void(0);" class="site-button outline outline-2 btnhover11 radius-no">ABOUT US</a>
				</div>
			</div>
		</div>
		<div class="col-lg-3 col-md-6 col-sm-6 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.4s">
			<div class="dlab-post-media dlab-img-effect zoom"> 
				<img src="assets/images/gallery/food/pic4.jpg" alt="" class="img-cover">
			</div>
		</div>
	</div>
</div>
