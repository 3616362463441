<div id="demo" class="carousel slide" data-ride="carousel">
	<ul class="carousel-indicators">
		<li data-target="#demo" data-slide-to="0" class="active"></li>
	</ul>
	<div class="carousel-inner" *ngIf="flag">
		<div class="carousel-item " *ngFor="let i of slider;index as j;index as k" [ngClass]="[j==0?'active':'']">
			<img  src={{env+i.url}} class="bannerhgt"    alt="home_banner-{{k}}">

		</div>
	</div>
	<a class="carousel-control-prev" href="#demo" data-slide="prev"  *ngIf="flag && !hide_flag">
		<span class="carousel-control-prev-icon"></span>
	</a>
	<a class="carousel-control-next" href="#demo" data-slide="next"  *ngIf="flag && !hide_flag">
		<span class="carousel-control-next-icon"></span>
	</a>
</div>
