
<div class="section-full content-inner-1 services-box-area bg-white" id="services" style="background-image: url(assets/images/background/construct/bg1.jpg); background-position: top center; background-size: cover; background-repeat: no-repeat;">
	<div class="container">
		<div class="row">
			<div class="col-lg-12 section-head text-white text-center">
				<h5 class="title-small">WHAT WE DO FOR YOU</h5>
				<div class="dlab-separator-outer">
					<div class="dlab-separator bg-primary style-skew"></div>
				</div>
				<h2 class="title">OUR SERVICES</h2>
			</div>
		</div>
		<div class="row m-lr0">
			<div *ngFor = "let item of serviceList let key = index" class="col-lg-4 col-md-4 p-lr0">
				<div class="icon-bx-wraper services-box">
					<div class="icon-content">
						<div class="icon-lg m-b30"> 
							<a href="#" class="icon-cell icon-up"><img src="assets/images/icon/construct/{{item.image}}" alt=""/></a> 
						</div>
						<h3 class="dlab-tilte">{{item.title}}</h3>
						<p>{{item.description}}</p>
					</div>
					<a href="javascript:void(0);" class="site-button white align-self-center ml-auto half-btn">
						<span>Read More</span>
					</a>
				</div>
			</div>
		</div>
	</div>
</div>

