<div class="section-full bg-gray content-inner" id="team" style="background-image:url(assets/images/pattern/pt7.png);" *ngIf="flag">
	<div class="container">
		<div class="section-head text-center">
			<h5 class="title-small">OUR HAPPY CLIENTS</h5>
			<div class="dlab-separator-outer">
				<div class="dlab-separator bg-primary style-skew"></div>
			</div>
			<h2 class="title">Some of Our Happy Customers</h2>
		</div>
		<div class="row justify-content-center" >
			<div class="col-lg-3 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s" *ngFor="let item of clients; index as j">
				<div class="dlab-box m-b30 dlab-team5">
					<div class="dlab-media ">
						<a href="javascript:;">
							<img width="358" height="460" alt="home_ourclients_image-{{j}}" src="{{url+item.client_icon.url}}">
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
