import { Component, OnInit } from '@angular/core';
import { HttpclientService } from '../../../../apiservices/httpclient.service';
import { env } from '../../../../config/config';
import { HistoryService } from 'src/app/apiservices/history.service';
import {EnquiryFormComponent} from '../../contact/enquiry-form/enquiry-form.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterEvent,
} from '@angular/router';

@Component({
  selector: 'app-service-labs',
  templateUrl: './service-labs.component.html',
  styleUrls: ['./service-labs.component.css'],
})
export class ServiceLabsComponent implements OnInit {
  serviceList;
  flag = false;
  url;
  set_flag = false;
  height_arr: any = [];
  max_height = 118;


  constructor(
    private service: HttpclientService,
    private history: HistoryService,
    private router: Router,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.url = env.apiurl;

    this.service.getAllValues('labs-services').subscribe((res) => {
      this.serviceList = res;
      if (this.serviceList.length > 0) {
        this.flag = true;
      }
      console.log(this.url + this.serviceList[0].service_image.url);
    });
  }

  service_detail(e) {
    console.log(e);
    this.history.set_services(e);
    this.router.navigateByUrl(`/services/${e.service_slug}`);
  }

  // scrollTo(section) {
  //   document.querySelector('#' + section)
  //   .scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  // }
  openDialog() {
    this.dialog.open(EnquiryFormComponent,{width: '100%',maxWidth:'100%'});
  }
  set_height(elmt) {
    let elements;
    this.height_arr.push(elmt.offsetHeight);

    this.height_arr.sort(function (a, b) {
      return b - a;
    });

    if (this.flag) {

      elements = document.querySelectorAll('#service');
      for (let i in elements) {

        if (elements[i].style) {
          if (parseInt(this.serviceList.length) * 2 == this.height_arr.length) {
            elements[i].style.height = this.height_arr[0] + 'px';
          }
        }

      }



    }
  }
}
