
<div class="section-full content-inner wow fadeIn solar-client" id="client" data-wow-duration="2s" data-wow-delay="0.3s" style="background-image:url(assets/images/background/solar/bg-3.jpg); background-repeat:no-repeat;
background-position:center">
	<div class="row">
		<div class="col-md-5 col-xl-4 m-b20">
			<div class="box-client-solar">
				<div class="section-head m-b20 text-white">
					<h5 class="title-small">Client Says</h5>
					<div class="dlab-separator-outer">
						<div class="dlab-separator bg-white style-skew"></div>
					</div>
					<h2 class="title">Happy Customers</h2>
					<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
				</div>
				<div class="testimonial-solar-navigation owl-clienr-btn">
					<span id="next-slide" class="btn prev"><i class="ti ti-arrow-left"></i></span>
					<span id="prev-slide" class="btn next"><i class="ti ti-arrow-right"></i></span>
				</div>
			</div>
		</div>
		<div class="col-md-7 col-xl-8">
			<owl-carousel-o [options]="customOptions" class="testimonial-solar owl-loaded owl-theme owl-carousel owl-btn-center-lr agro-client-says owl-none">
				<ng-template carouselSlide *ngFor = "let slide of slideStore">
					<div class="slide">
						<div class="testimonial-1">
							<div class="testimonial-text">
								<p>{{slide.description}}</p>
							</div>
							<div class="testimonial-detail clearfix">
								<div class="testimonial-pic radius"><img src="assets/images/testimonials/{{slide.image}}" width="100" height="100" alt=""></div>
								<div class="info"><strong class="testimonial-name">{{slide.name}}</strong> <span class="testimonial-position">{{slide.position}}</span></div> 
							</div>
						</div>
					</div>
				</ng-template>
			</owl-carousel-o>
		</div>
	</div>
</div>

