// import { Injectable } from '@angular/core';

// @Injectable({
//   providedIn: 'root'
// })
// export class HttpclientService {

//   constructor() { }
// }

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { env } from '../config/config';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HttpclientService {
  constructor(private http: HttpClient) {}

  getAllValues(page) {
    // console.log(page)
    return this.http.get(`${env.apiurl}/${page}`).pipe(map((res) => res));
  }

  getconfigValue(key) {
    console.log(key);
    return this.http
      .get(`${env.apiurl}/configurations?content_key=${key}`)
      .pipe(map((res) => res));
  }

  getBanner(page_name) {
    return this.http
      .get(`${env.apiurl}/banners?page_name=${page_name}`)
      .pipe(map((res) => res));
  }

  contact_us(detail) {
    return this.http
      .post(`${env.apiurl}/career-resumes`, detail)
      .pipe(map((res) => res));
  }

  add_contact(detail) {
    return this.http
      .post(`${env.apiurl}/contacts`, detail)
      .pipe(map((res) => res));
  }

  add_enquiry(detail) {
    return this.http
      .post(`${env.apiurl}/enquiry-forms`, detail)
      .pipe(map((res) => res));
  }

  add_enrollment(detail) {
    return this.http
      .post(`${env.apiurl}/enrollment-forms`, detail)
      .pipe(map((res) => res));
  }

  add_general_feedback(detail) {
    return this.http
      .post(`${env.apiurl}/general-feedbacks`, detail)
      .pipe(map((res) => res));
  }

  add_Wrap_Up_feedback(detail) {
    return this.http
      .post(`${env.apiurl}/wrap-up-forms`, detail)
      .pipe(map((res) => res));
  }
  add_onboard_feedback(detail) {
    return this.http
      .post(`${env.apiurl}/onboarding-forms`, detail)
      .pipe(map((res) => res));
  }

  send_mail(detail, form_type) {
    return this.http
      .post(`${env.apiurl}/send-email/${form_type}`, detail)
      .pipe(map((res) => res));
  }

  upload(file) {
    console.log(file);
    return this.http.post(`${env.apiurl}/upload`, file).pipe(map((res) => res));
  }

  get_service(id) {
    return this.http
      .get(`${env.apiurl}/services?white_papers.id=${id}`)
      .pipe(map((res) => res));
  }

  get_whitepaper(id) {
    return this.http
      .get(`${env.apiurl}/white-papers/${id}`)
      .pipe(map((res) => res));
  }

  get_address() {
    return this.http
      .get(`${env.apiurl}/address-sections`)
      .pipe(map((res) => res));
  }

  get_main_address() {
    return this.http
      .get(`${env.apiurl}/address-sections?is_head_branch=true`)
      .pipe(map((res) => res));
  }

  get_papers() {
    return this.http.get(`${env.apiurl}/white-papers`).pipe(map((res) => res));
  }

  get_seo() {
    return this.http
      .get(`${env.apiurl}/seo-collections`)
      .pipe(map((res) => res));
  }
  get_menulist() {
    return this.http.get(`${env.apiurl}/menulists`).pipe(map((res) => res));
  }
  // get_labs_aboutus(){
  //   return this.http.get(`${env.apiurl}/about-us-labs`).pipe(map((res) => res))
  // }

  validate_file(file: any) {
    const match = [
      'image/png',
      'image/jpeg',
      'image/PNG',
      'image/JPEG',
      'image/JPG',
      'image/jpg',
      'application/pdf',
      'application/PDF',
    ];
    let file_size = file.size;
    let file_type = file.type;
    let max_file_size = 5 * 1024 * 1024;

    return new Promise((resolve, reject) => {
      if (match.includes(file_type) && file_size < max_file_size) {
        resolve({ flag: 'valid' });
      } else if (!match.includes(file_type)) {
        resolve({ flag: 'type_mismatch' });
      } else {
        resolve({ flag: 'size_exceed' });
      }
    });
  }
}
