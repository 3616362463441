
<div class="section-full content-inner bg-white" id="news" style="background-image:url(assets/images/background/agriculture/bg1.jpg);">
	<div class="container">
		<div class="row">
			<div class="col-lg-12 section-head text-center">
				<h5 class="title-small">NEWS AND UPDATES</h5>
				<div class="dlab-separator-outer">
					<div class="dlab-separator bg-primary style-skew"></div>
				</div>
				<h2 class="title">Trending News & Thoughts</h2>
			</div>
		</div>
		<div class="row">
			<div *ngFor="let item of blogList" class="col-lg-4 col-md-6 col-sm-6">
				<div class="blog-post trending-post text-center text-white">
					<div class="dlab-post-media dlab-img-effect"> 
						<a href="blog-single.html"><img src="assets/images/blog/grid/agriculture/{{item.image}}" alt=""></a> 
					</div>
					<div class="dlab-info">
						<div class="dlab-post-meta">
							<ul>
								<li class="post-date"> <i class="fa fa-calendar"></i>{{item.date}}</li>
								<li class="post-comment"><i class="fa fa-comments"></i> <a href="javascript:void(0);">{{item.comment}}</a> </li>
							</ul>
						</div>
						<div class="dlab-post-title">
							<h5 class="post-tag font-weight-500"><a class="text-white" [routerLink] = "'/blog-list-two'">{{item.title}}</a></h5>
							<h5 class="post-title font-weight-500"><a [routerLink] = "'/blog-single'">{{item.category}}</a></h5>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

