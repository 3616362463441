
<footer class="site-footer">
  <div class="footer-top">

    <div class="container">
      <div class="row">
        <div
          class="mt-4 col-xl-3 col-lg-3 col-md-6 col-5 col-sm-6 footer-col-4 col-12 logo-section"
        >
          <div class="widget widget_about border-0">
            <img src="{{ logo }}" alt="logo" />
            <p>{{ footer_text }}</p>
          </div>
        </div>
        <div
          class="col-xl-3 col-lg-4 col-md-6 col-7 col-sm-6 footer-col-4 col-12"
        >
          <div class="widget border-0 recent-posts-entry">
            <div class="footer-title">
              <h5 class="title">{{ post_title }}</h5>
              <div class="dlab-separator-outer">
                <div class="dlab-separator bg-primary style-skew"></div>
              </div>
            </div>
            <ul class="footer-address-list ftr-details">
              <li>
                <span class="bold info">
                  <i class="fa fa-envelope" aria-hidden="true"></i>
                </span>
                <div>
                  <span class="bold">Email</span>
                  <ul>
                    <li class="li-list"><span class="sub-title">IT Services</span><span>:</span><span><a href="mailto:{{ config_email }}">{{ config_email }}</a></span></li>
                    <li class="li-list"><span class="sub-title">LABs</span><span>:</span><span><a href="mailto:{{ labs_email }}">{{ labs_email }}</a></span></li>
                  </ul>
                </div>
              </li>
              <li>
                <span class="bold info">
                  <i class="fa fa-phone" aria-hidden="true"></i>
                </span>
                <div>
                  <span class="bold">Phone</span>
                  <ul>
                    <li class="li-list">
                      <span class="sub-title">IT Services</span><span>:</span>
                      <span>
                        <a href="tel:+971521123221">UAE: +971-521123221</a>
                      </span>
                    </li>
                    <li class="li-list">
                      <span class="sub-title"></span><span> </span>
                      <span>
                        <a href="tel:{{ phone }}">India: {{ phone }}</a>
                      </span>
                    </li>

                    <li class="li-list">
                      <span class="sub-title">LABs</span><span>:</span>
                     <span>
                        <a href="tel:{{ labs_phone }}">India: +91{{ labs_phone }}</a>
                      </span>
                    </li>
                  </ul>

                </div>
              </li>
              <li *ngIf="address_flag">
                <span class="bold info">
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                </span>
                <p>
                  <span class="bold">Address</span>
                  <span *ngFor="let item of addresslist" class="mb-1">
                    <span *ngIf="item.district != null && item.district != ''">
                      {{ item.district }}
                    </span>
                    <br />
                    <span>{{ item.address_line_1 + ", " }}</span>
                    <span
                      *ngIf="
                        item.address_line_2 != null && item.address_line_2 != ''
                      "
                    >
                      {{ item.address_line_2 + ", " }}
                    </span>
                    <span *ngIf="item.district != null && item.district != ''">
                      {{ item.district }}
                    </span>
                    <span *ngIf="item.pincode != null && item.pincode != '' && item.pincode>0">
                      - {{ item.pincode + ", " }}
                    </span>
                    <span *ngIf="item.state != null && item.state != ''">
                      {{ item.state + ", " }}
                    </span>
                    <span *ngIf="item.country != null && item.country != ''">
                      {{ item.country }}
                    </span>
                  </span>
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xl-3 col-lg-2 col-md-6 col-sm-6 footer-col-4 col-12">
          <div class="widget widget_services border-0">
            <div class="footer-title">
              <h5 class="title">{{ company_title }}</h5>
              <div class="dlab-separator-outer">
                <div class="dlab-separator bg-primary style-skew"></div>
              </div>
            </div>
            <ul class="mm-t10">
              <li *ngFor="let item of serviceList">
                <a href="{{ item.url }}">{{ item.title }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 footer-col-4 col-12">
          <div class="widget">
            <div class="footer-title">
              <h5 class="title">{{ socialmedia_title }}</h5>
              <div class="dlab-separator-outer">
                <div class="dlab-separator bg-primary style-skew"></div>
              </div>
            </div>
            <div class="ff-social-icons mt30">
              <a href="https://www.facebook.com/6Solve" target="blank">
                <i class="fab fa-facebook facebook"></i>
              </a>
              <a href="https://twitter.com/6solve" target="blank">
                <i class="fab fa-twitter twiter"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/6solve-it-services-pvt-ltd/mycompany/"
                target="blank"
              >
                <i class="fab fa-linkedin linkedin"></i>
              </a>
              <a href="https://www.instagram.com/6solve/" target="blank">
                <i class="fab fa-instagram instagram"></i>
              </a>
            </div>
            <iframe
              src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F6Solve&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
              width="340"
              height="300"
              style="border: none; overflow: hidden"
              scrolling="no"
              frameborder="0"
              allowfullscreen="true"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-sm-6 text-left">
          <span class="footerfont">
            Copyright © 2024 6Solve IT Services. All rights reserved.
          </span>
        </div>
        <div class="col-md-6 col-sm-6 text-right">
          <span class="footerfont"> Designed by 6Solve IT Services. </span>
        </div>
      </div>
    </div>
  </div>
</footer>

