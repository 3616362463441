<div class="section-full content-inner-2 bg-white about-bx4" id="about-us" style="background-image:url(assets/images/background/food/bg1.jpg);">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-lg-7 col-md-12 m-b30">
				<div class="about-img-box">
					<div class="about-shape-box">
						<div class="about-shape2"></div>
						<div class="about-shape1"></div>
						<div class="about-shape4"></div>
					</div>
					<div class="about-img-one">
						<div class="video-bx">
							<img src="assets/images/about/food/about-2.jpg" alt="">
							<div class="video-play-icon">

							</div>
						</div>
					</div>
					<div class="about-img-two">
						<img src="assets/images/about/food/about-1.jpg" alt="">
					</div>
				</div>
			</div>
			<div class="col-lg-5">
				<div class="about-contact-box">
					<div class="dlab-tabs tabs-site-button">
						<div class="about-inner-content">
							<div class="section-head">
								<h5 class="title-small">{{subtitle}}</h5>
								<div class="dlab-separator-outer">
									<div class="dlab-separator bg-primary style-skew"></div>
								</div>
								<h2 class="title">{{title}}</h2>
							</div>
							<p class="m-b20" [innerHTML]="description"></p>
							<a href="javascript:void(0);" class="site-button outline outline-2 btnhover11 radius-no">Read More</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
