import { Component, OnInit } from '@angular/core';
import {HttpclientService} from '../../../../apiservices/httpclient.service'
import {env} from '../../../../config/config'

@Component({
  selector: 'app-about-content',
  templateUrl: './about-content.component.html',
  styleUrls: ['./about-content.component.css']
})
export class AboutContentComponent implements OnInit {
  content
  flag=false
  subtitle= "OVERVIEW";
  title= "6Solve IT services Private Ltd";
  description= "What started with just two members in 2015 is now a full-fledged company providing cutting-edge innovative solutions in various spectrums like Software Development, Mobile App Development, Website Design and Development, Business Process Outsourcing and Digital Marketing with a team of 50+ staffs. 3MBK Technologies guide its clients through the ever-changing business landscapes.";

  constructor(private service:HttpclientService) { }

  ngOnInit(): void {
    this.service.getAllValues('about-us-overviews').subscribe(res => {
      this.content=res
      if(this.content.length>0){
        this.flag=true
      }
    })
  }

}
