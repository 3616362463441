
    <header class="site-header mo-left header-transparent header agro-header">
	
        <div class="sticky-header main-bar-wraper navbar-expand-lg">
            <div class="main-bar clearfix ">
                <div class="container clearfix">
                 
                    <div class="logo-header mostion">
						<a routerLink="/"><img src="assets/images/logo-white-3.png" alt=""></a>
					</div>
                 
                    <button class="navbar-toggler collapsed navicon justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
						<span></span>
						<span></span>
						<span></span>
					</button>
                
                    <div class="extra-nav">
                        <div class="extra-cell">
							<a  routerLink="/contact-page" class="site-button white align-self-center outline ml-auto outline-2 btnhover14">Get In Touch</a>
						</div>
                    </div>
        
                    <div class="header-nav navbar-collapse collapse justify-content-center onepage" id="navbarNavDropdown">
						<div class="logo-header d-md-block d-lg-none">
							<a  routerLink="/"><img src="assets/images/logo-3.png" alt=""></a>
						</div>
                        <ul class="nav navbar-nav navbar">	
							<li><a href="#home" class="scroll nav-link active">Home <i class="fa fa-chevron-down"></i></a>
								<ul class="sub-menu">
									<li><a [routerLink] = "''">Home construction</a></li>
									<li><a [routerLink] = "'/index-food-factory'">Home Food Industry</a></li>
									<li><a [routerLink] = "'/index-agriculture'">Home Agriculture</a></li>
									<li><a [routerLink] = "'/index-steel-plant'">Home Steel Plant</a></li>
									<li><a [routerLink] = "'/index-solar-plant'">Home Solar Plant</a></li>
								</ul>
							</li>
							<li><a href="#services" class="scroll nav-link">Services</a></li>
							<li><a href="#about-us" class="scroll nav-link">About Us</a></li>
							<li><a href="#client" class="scroll nav-link">Client Says</a></li>
							<li><a href="#team" class="scroll nav-link">Team</a></li>
							<li><a href="#news" class="scroll nav-link">News</a></li>
						</ul>
						<div class="dlab-social-icon">
							<ul>
								<li><a class="site-button facebook sharp-sm fa fa-facebook" href="javascript:void(0);"></a></li>
								<li><a class="site-button twitter sharp-sm fa fa-twitter" href="javascript:void(0);"></a></li>
								<li><a class="site-button linkedin sharp-sm fa fa-linkedin" href="javascript:void(0);"></a></li>
								<li><a class="site-button instagram sharp-sm fa fa-instagram" href="javascript:void(0);"></a></li>
							</ul>
						</div>			
                    </div>
                </div>
            </div>
        </div>
      
    </header>
  
