import { Component, OnInit } from '@angular/core';
import {HttpclientService} from '../../../../apiservices/httpclient.service'
import {env} from '../../../../config/config'

@Component({
  selector: 'app-team-four',
  templateUrl: './team-four.component.html',
  styleUrls: ['./team-four.component.css']
})
export class TeamFourComponent implements OnInit {
clients
flag=false
	url=env.apiurl

  constructor(private service:HttpclientService) {

  
   }

  ngOnInit(): void {
    
    this.service.getAllValues('home-page-clients').subscribe(res => {
      this.clients=res
      // console.log(this.clients)
      if(this.clients.length>0){
        this.flag=true
      }
    })
  }

}
