
    <footer class="site-footer style1">
  
        <div class="footer-top" style="background-image:url(assets/images/pattern/pt9.png); background-size: auto;">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 col-sm-12">
                        <div class="widget widget_about">
							<div class="footer-title">
								<h4 class="title text-white">{{title_about}}</h4>
								<div class="dlab-separator-outer">
									<div class="dlab-separator bg-white style-skew"></div>
								</div>
							</div>
                            <p>{{description}}</p>
							<a href="javascript:void(0);" class="site-button">Read More</a>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <div class="widget">
                            <div class="footer-title">
								<h4 class="title text-white">{{link_title}}</h4>
								<div class="dlab-separator-outer">
									<div class="dlab-separator bg-white style-skew"></div>
								</div>
							</div>
                            <ul class="list-2">
                                <li *ngFor="let item of itemList"><a href="javascript:void(0);">{{item.list}}</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <div class="widget widget_subscribe">
                            <div class="footer-title">
								<h4 class="title text-white">{{form_title}}</h4>
								<div class="dlab-separator-outer">
									<div class="dlab-separator bg-white style-skew"></div>
								</div>
							</div>
							<p>{{form_description}}</p>
                            <form class="dzSubscribe" action="script/mailchamp.php" method="post">
								<div class="dzSubscribeMsg"></div>
								<div class="form-group">
									<div class="input-group">
										<input name="dzEmail" required="required" type="email" class="form-control" placeholder="Your Email Address">
										<div class="input-group-addon">
											<button name="submit" value="Submit" type="submit" class="site-button fa fa-paper-plane-o"></button>
										</div>
									</div>
								</div>
							</form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   
        <div class="footer-bottom footer-line" style="background-image:url(assets/images/pattern/pt9.png); background-size: auto;">
            <div class="container">
                <div class="footer-bottom-in">
                    <div class="footer-bottom-logo"><a href="index.html"><img src="assets/images/logo-light.png" alt=""/></a></div>
					<div class="footer-bottom-social">
						<ul class="dlab-social-icon dez-border">
							<li><a class="fa fa-facebook" href="javascript:void(0);"></a></li>
							<li><a class="fa fa-twitter" href="javascript:void(0);"></a></li>
							<li><a class="fa fa-linkedin" href="javascript:void(0);"></a></li>
							<li><a class="fa fa-pinterest" href="javascript:void(0);"></a></li>
						</ul>
					</div>
                </div>
            </div>
        </div>
    </footer>
 
