import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slider-three',
  templateUrl: './slider-three.component.html',
  styleUrls: ['./slider-three.component.css']
})
export class SliderThreeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
