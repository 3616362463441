import { Component, OnInit, ViewEncapsulation } from '@angular/core'
declare var TradeZone : any;
@Component({
  selector: 'app-careerpage',
  templateUrl: './careerpage.component.html',
  styleUrls: ['./careerpage.component.css'],


})
export class CareerpageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    this.move_top()
    TradeZone.init();
  }
  move_top() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}
