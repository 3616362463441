<form class="contact-box dzForm" [formGroup]="generalFbForm">
  <div class="section-head  text-left">
    <h2  class="title">Feedback Form I (General Feedback)</h2>
  </div>
<div class="row feedback-form-row">
<div class="col-lg-6 col-md-12">
  <mat-form-field appearance="outline" class="first">
    <mat-label>Name</mat-label>
    <input
      matInput
      type="text"
      placeholder="Your Name"
      formControlName="name"
      maxlength="32"
      required

      (keypress)="onKeyPress($event)"
    />

    <mat-error
      class="error"
      *ngIf="
        generalfb_submitted && errorControl.name.errors?.required
      "
    >
      Name is required.
    </mat-error>
    <mat-error
      class="error"
      *ngIf="
        generalfb_submitted && errorControl.name.errors?.pattern
      "
    >
    Must contain only alphabets of minimum 2 characters to maximum 32 characters
    </mat-error>
  </mat-form-field>
</div>

<div class="col-lg-6 col-md-12">
  <mat-form-field appearance="outline">
    <mat-label>Contact Number</mat-label>
    <input
      matInput
      placeholder="Contact Number"
      formControlName="phone"
      required
      onkeypress="return event.charCode >= 48 && event.charCode <= 57 && this.value.length!=10"
    />

    <mat-error
      class="error"
      *ngIf="
        generalfb_submitted && errorControl.phone.errors?.required
      "
    >
    Contact number is required.
    </mat-error>
    <mat-error
      class="error"
      *ngIf="
        generalfb_submitted && errorControl.phone.errors?.pattern
      "
    >
      Please enter a valid 10 digit Contact number.
    </mat-error>
  </mat-form-field>
</div>

<div class="col-lg-6 col-md-12">
  <mat-form-field appearance="outline">
    <mat-label>Email Address</mat-label>
    <input
      matInput
      placeholder="Email Address"
      formControlName="email"
      required
    />

    <mat-error
      class="error"
      *ngIf="
        generalfb_submitted && errorControl.email.errors?.required
      "
    >
    Email address is required.
    </mat-error>
    <mat-error
      class="error"
      *ngIf="
        generalfb_submitted && errorControl.email.errors?.pattern
      "
    >
      Please enter a valid Email address.
    </mat-error>
  </mat-form-field>
</div>
<hr>
<div class="col-lg-12 col-md-12">

  <mat-radio-group aria-label="How did you hear about our educational institute?" formControlName="educational_institute" (change)="changedetect('educational_institute')">
    <div class="m-b10">
      <mat-label class="query-label1" >How did you hear about our 6Solve LABs?</mat-label>
    </div>
    <div class="mat-radio-cta">
    <mat-radio-button [value]="i"  *ngFor="let i of educational_institute_data">{{i}}</mat-radio-button>
    <mat-radio-button value="Others">Others</mat-radio-button>
    </div>
  </mat-radio-group>

  <span
  class="error"
  *ngIf="
    generalfb_submitted && !flag_educational_institute &&  errorControl.educational_institute.errors?.required
  "
>
  This field is required.
</span>

  <mat-form-field appearance="outline"  *ngIf="flag_educational_institute">
    <mat-label class="shade">Please specify</mat-label>
  <input

  matInput
  type="text"
  placeholder="Please specify"
  formControlName="educational_institute_specific"
  maxlength="32"
  required
/>

<mat-error
  class="error"
  *ngIf="
    generalfb_submitted && errorControl.educational_institute_specific.errors?.required
  "
>
  Kindly provide you specification.
</mat-error>
</mat-form-field>
</div>

<hr>
<div class="col-lg-12 col-md-12">
  <mat-radio-group aria-label="What motivated you to enquire about our streams and paths?" formControlName="our_streams_and_paths" (change)="changedetect('our_streams_and_paths')">
    <div class="m-b10">
      <mat-label class="query-label1">What motivated you to enquire about our streams and paths?</mat-label>
    </div>
    <div class="mat-radio-cta">
    <mat-radio-button [value]="i"  *ngFor="let i of our_streams_and_paths_data">{{i}}</mat-radio-button>
    <mat-radio-button value="Others">Others</mat-radio-button>
    </div>
  </mat-radio-group>

  <span
  class="error"
  *ngIf="
    generalfb_submitted && !flag_our_streams_and_paths &&  errorControl.our_streams_and_paths.errors?.required
  "
>
  This field is required.
</span>

  <mat-form-field appearance="outline"  *ngIf="flag_our_streams_and_paths">
    <mat-label class="shade">Please specify</mat-label>
  <input

  matInput
  type="text"
  placeholder="Please specify"
  formControlName="our_streams_and_paths_specific"
  maxlength="32"
  required
/>

<mat-error
  class="error"
  *ngIf="
    generalfb_submitted && errorControl.our_streams_and_paths_specific.errors?.required
  "
>
  Kindly provide you specification.
</mat-error>
</mat-form-field>
</div>
<hr>
<div class="col-lg-12 col-md-12">
  <mat-radio-group aria-label="How satisfied are you with the responsiveness and helpfulness of our enquiry team?" formControlName="responsiveness_and_helpfulness" (change)="changedetect('responsiveness_and_helpfulness')">
    <div class="m-b10">
      <mat-label class="query-label1">How satisfied are you with the responsiveness and helpfulness of our enquiry team?</mat-label>
    </div>
    <div class="mat-radio-cta">
    <mat-radio-button [value]="i"  *ngFor="let i of responsiveness_and_helpfulness_data">{{i}}</mat-radio-button>
    <mat-radio-button value="Others">Others</mat-radio-button>
    </div>
  </mat-radio-group>

  <span
  class="error"
  *ngIf="
    generalfb_submitted && !flag_responsiveness_and_helpfulness &&  errorControl.responsiveness_and_helpfulness.errors?.required
  "
>
  This field is required.
</span>
  <mat-form-field appearance="outline"  *ngIf="flag_responsiveness_and_helpfulness">
    <mat-label class="shade">Please specify</mat-label>
  <input

  matInput
  type="text"
  placeholder="Please specify"
  formControlName="responsiveness_and_helpfulness_specific"
  maxlength="32"
  required
/>

<mat-error
  class="error"
  *ngIf="
    generalfb_submitted && errorControl.responsiveness_and_helpfulness_specific.errors?.required
  "
>
  Kindly provide you specification.
</mat-error>
</mat-form-field>
</div>
<hr>
<div class="col-lg-12 col-md-12">
  <mat-radio-group aria-label="Which type of course are you interested in?" formControlName="course_interested" (change)="changedetect('course_interested')">
    <div class="m-b10">
      <mat-label class="query-label1">Which type of course are you interested in?</mat-label>
    </div>
    <div class="mat-radio-cta">
    <mat-radio-button [value]="i"  *ngFor="let i of course_interested_data">{{i}}</mat-radio-button>
    <mat-radio-button value="Others">Others</mat-radio-button>
    </div>
  </mat-radio-group>


  <span
  class="error"
  *ngIf="
    generalfb_submitted && !flag_course_interested &&  errorControl.course_interested.errors?.required
  "
>
  This field is required.
</span>

  <mat-form-field appearance="outline"  *ngIf="flag_course_interested">
    <mat-label class="shade">Please specify</mat-label>
  <input

  matInput
  type="text"
  placeholder="Please specify"
  formControlName="course_interested_specific"
  maxlength="32"
  required
/>

<mat-error
  class="error"
  *ngIf="
    generalfb_submitted && errorControl.course_interested_specific.errors?.required
  "
>
  Kindly provide you specification.
</mat-error>
</mat-form-field>
</div>

<hr>
<div class="col-lg-12 col-md-12">
  <label   class="query-label1">What additional information or resources would have been helpful during your enquiry process? </label>
  <mat-form-field appearance="outline">
    <mat-label class="shade">Kindly provide your additional information if any</mat-label>
    <textarea

      matInput
      placeholder="Type Your Message here"
      formControlName="additional_information"
    ></textarea>
  </mat-form-field>
</div>

</div>
</form>

