
<div [ngClass]="['section-full', 'p-tb40', 'bg-primary', 'construct-action', data.color]" style="background-image: url(assets/images/pattern/pt5.png);">
	<div class="container">
		<div class="row spno">
			<div class="col-sm-6 col-6">
				<a href="#"><i class="las la-envelope-open"></i>Email: {{email}}</a>
			</div>
			<div class="col-sm-6 col-6 text-right">
				<a href="#">Support: {{contact_no}}<i class="las la-phone-volume"></i></a>
			</div>
		</div>
	</div>
</div>

