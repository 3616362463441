import { Component, OnInit } from '@angular/core'
import { HttpclientService } from '../../../../apiservices/httpclient.service'
import { env } from '../../../../config/config'
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'

interface add_contact {
  name: string
  phone: number
  email: string
  message: string
}

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css'],
})
export class ContactFormComponent implements OnInit {
  addresslist

  config_email
  add_contact = {} as add_contact
  config
  phone
  flag = false
  address_flag = false
  contact_submitted = false
  contactForm: FormGroup
  contactList: any = [
    {
      icon: 'fa fa-map-marker',
      title: 'Address',
      description: '123 West Street, Melbourne Victoria 3000 Australia',
    },
    {
      icon: 'fa fa-envelope-o',
      title: 'Email',
      description: 'info@example.com',
    },
    {
      icon: 'fa fa-phone',
      title: 'Phone',
      description: '+61 3 8376 6284',
    },
  ]

  constructor(private service: HttpclientService, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.service.getAllValues('configurations').subscribe((res) => {
      console.log('page', res)
      this.config = res
      this.config.map((i) => {
        if (i.content_key == 'config_email') {
          this.config_email = i.content_value
        } else if (i.content_key == 'config_phone') {
          this.phone = i.content_value
        }
      })
      this.flag = true
    })

    this.get_address()

    this.contactForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.pattern('[a-zA-Z ]{2,32}'),
      ]),
      phone: new FormControl('', [
        Validators.required,
        Validators.pattern('[0-9]{10}'),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        ),
      ]),
      message: new FormControl(),
    })
  }

  get errorControl() {

    return this.contactForm.controls
  }

  contact() {
    let result
    this.contact_submitted = true
    if (!this.contactForm.valid) {
      this.contactForm.markAllAsTouched()
      console.log('Please provide all the required values!')
      return false
    } else {
      this.add_contact.name = this.contactForm.value.name
      this.add_contact.phone = this.contactForm.value.phone
      this.add_contact.email = this.contactForm.value.email
      this.add_contact.message = this.contactForm.value.message
      this.service.add_contact(this.add_contact).subscribe((res) => {
        console.log(res)
        this.contactForm.reset()
        this.openDialog()
        setTimeout(() => {
          this.closeDialog()
        }, 2000)
      })
    }
  }

  get_address() {
    this.service.get_address().subscribe((res) => {

      this.addresslist = res
      console.log(this.addresslist)
      if (this.addresslist.length > 0) {
        this.address_flag = true

      }
    })
  }

  openDialog() {
    this.dialog.open(DialogElementsExampleDialog)
  }

  closeDialog() {
    this.dialog.closeAll()
  }
}
@Component({
  selector: 'dialog-elements-example-dialog',
  template: `
    <div class="icon">
      <i class="fa fa-check-circle" aria-hidden="true"></i>
    </div>
    <div mat-dialog-content>Your Form has been submitted sucessfully.</div>
    <div mat-dialog-actions></div>
  `,
  styles: [
    '.fa-check-circle{color:green; font-size:50px;}.icon,.mat-dialog-content{text-align:center}',
  ],
})
export class DialogElementsExampleDialog {}
