
<footer class="site-footer">
	<div class="footer-top overlay-black-dark"  style="background-image:url(assets/images/background/agriculture/bg3.jpg); background-size: cover;">
		<div class="container">
			<div class="row">
				<div class="col-md-3 col-5 col-xl-2 col-lg-2 col-sm-6 footer-col-4">
					<div class="widget widget_services border-0">
						<div class="footer-title">
							<h4 class="title text-white">{{about_title}}</h4>
							<div class="dlab-separator-outer">
								<div class="dlab-separator bg-primary style-skew"></div>
							</div>
						</div>
						<ul>
							<li *ngFor = "let item of serviceList"><a href="javascript:void(0);">{{item.list}}</a></li>
						</ul>
					</div>
				</div>
				<div class="col-md-3 col-7 col-xl-2 col-lg-3 col-sm-6 footer-col-4">
					<div class="widget widget_services border-0">
						<div class="footer-title">
							<h4 class="title text-white">{{link_title}}</h4>
							<div class="dlab-separator-outer">
								<div class="dlab-separator bg-primary style-skew"></div>
							</div>
						</div>
						<ul>
							<li *ngFor ="let item of linkList"><a href="javascript:void(0);">{{item.list}}</a></li>
						</ul>
					</div>
				</div>
				<div class="col-md-6 col-xl-4 col-lg-3 col-sm-6 footer-col-4">
					<div class="widget widget_getintuch">
						<div class="footer-title">
							<h4 class="title text-white">Contact us</h4>
							<div class="dlab-separator-outer">
								<div class="dlab-separator bg-primary style-skew"></div>
							</div>
						</div>
						<ul>
							<li><i class="ti ti-location-pin"></i><strong>address</strong> demo address #8901 Marmora Road Chi Minh City, Vietnam </li>
							<li><i class="ti ti-mobile"></i><strong>phone</strong>0800-123456 (24/7 Support Line)</li>
							<li><i class="ti ti-email"></i><strong>email</strong>info@example.com</li>
						</ul>
					</div>
				</div>
				<div class="col-md-12 col-xl-4 col-lg-4 col-sm-6 footer-col-4 ">
					<div class="widget">
						<div class="footer-title">
							<h4 class="title text-white">Subscribe To Our Newsletter</h4>
							<div class="dlab-separator-outer">
								<div class="dlab-separator bg-primary style-skew"></div>
							</div>
						</div>
						
						<p class="text-capitalize m-b20">Lorem Ipsum is simply dummy text of the printing and typesetting industry has been the industry's standard dummy text ever since the..</p>
						<div class="subscribe-form m-b20">
							<form class="dzSubscribe" action="script/mailchamp.php" method="post">
								<div class="dzSubscribeMsg"></div>
								<div class="input-group">
									<input name="dzEmail" required="required"  class="form-control" placeholder="Your Email Id" type="email">
									<span class="input-group-btn">
										<button name="submit" value="Submit" type="submit" class="site-button btnhover16">Subscribe</button>
									</span> 
								</div>
							</form>
						</div>
						<ul class="list-inline m-a0">
							<li><a href="javascript:void(0);" class="site-button facebook circle "><i class="fa fa-facebook"></i></a></li>
							<li><a href="javascript:void(0);" class="site-button google-plus circle "><i class="fa fa-google-plus"></i></a></li>
							<li><a href="javascript:void(0);" class="site-button linkedin circle "><i class="fa fa-linkedin"></i></a></li>
							<li><a href="javascript:void(0);" class="site-button twitter circle "><i class="fa fa-twitter"></i></a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
	
	<div class="footer-bottom">
		<div class="container">
			<div class="row">
				<div class="col-md-6 col-sm-6 text-left "> <span>Copyright © 2019 DexignZone</span> </div>
				<div class="col-md-6 col-sm-6 text-right "> 
					<div class="widget-link "> 
						<ul>
							<li><a href="javascript:void(0);"> About</a></li> 
							<li><a href="javascript:void(0);"> Help Desk</a></li> 
							<li><a href="javascript:void(0);"> Privacy Policy</a></li> 
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>

