<link
  class="skin"
  rel="stylesheet"
  type="text/css"
  href="/assets/css/skin/skin-4.css"
/>

<div class="page-wraper">
  <div class="whatapp-sticky">
    <div class="inner-sticky">
          <img  (click)="goToUrl()"
          target="_blank" src="/assets/images/whatapp_sticky.png" alt="whatsapp">
    </div>
    </div>
  <app-header-four></app-header-four>
  <div class="page-content bg-white">
    <app-slider-labs></app-slider-labs>
    <div class="content-block">
     <app-about-labs></app-about-labs>
      <app-service-labs></app-service-labs>
      <app-team-labs></app-team-labs>
      <app-scheme></app-scheme>
      <app-about-three></app-about-three>
     <!-- <app-apply-online></app-apply-online> -->
      <!-- <app-enquiry-form></app-enquiry-form> -->
    </div>
  </div>
  <app-footer-four></app-footer-four>
  <button class="scroltop style2 radius" type="button" (click)="move_top()">
    <i class="fa fa-arrow-up"></i>
  </button>
</div>
