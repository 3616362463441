import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { HttpclientService } from '../../../../apiservices/httpclient.service';
import { HistoryService } from 'src/app/apiservices/history.service';
import { env } from '../../../../config/config';

@Component({
  selector: 'app-service-section',
  templateUrl: './service-section.component.html',
  styleUrls: ['./service-section.component.css'],
})
export class ServiceSectionComponent implements OnInit {
  servicelist;
  flag = false;
  url;

  constructor(
    private service: HttpclientService,
    private history: HistoryService,
    private router: Router
  ) {

  }

  ngOnInit(): void {
    this.url = env.apiurl;
    this.service.getAllValues('services').subscribe((res) => {
      this.servicelist = res;
      if (this.servicelist.length > 0) {
        this.flag = true;
        this.servicelist.forEach((el) => {
          if (el.sub_services.length > 0) {
  
            el.sub_services.sort((a, b) => a.sort_order - b.sort_order);

          }
        });
      }
    });
  }

  service_detail(e) {
    console.log(e);
    this.history.set_services(e);
    this.router.navigateByUrl(`/services/${e.service_slug}`);
  }
}
