<div class="section-full bg-gray content-inner content-inner-1" id="team"  >
	<div class="container">
		<div class="section-head text-left">
			<h2 class="title">{{content.title}}</h2>
		</div>
		<div class="row  justify-content-center" >
			<div class="wow col-md-12 col-12" data-wow-duration="2s" data-wow-delay="0.2s">

	<div class="content">
    <p>{{content.description }}</p>
<div class="text-right  m-tb10">
<a class="site-button white" (click)="openDialog()">{{content.button_text }} <span _ngcontent-qtu-c105="" class="circle"></span></a>
</div>
  </div>
							<!-- <img width="358" height="460" alt="home_ourclients_image-{{j}}" src="{{url+item.client_icon.url}}"> -->
			</div>
		</div>
	</div>
</div>

