import {
  Component,
  ElementRef,
  EventEmitter,
  Host,
  HostListener,
  OnInit,
  Output,
} from '@angular/core';
import { HttpclientService } from '../../../../../apiservices/httpclient.service';
import { env } from '../../../../../config/config';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FeedbackFormComponent } from '../feedback-form.component';
import { StarRatingColor } from '../../../star-rating/star-rating.component';
interface OnboardingFM {
  Name: string;
  Phone: number;
  Email_Id: string;
  Quality_of_Courses: Number;
  Relevance_of_Curriculum: Number;
  Learning_Resources: Number;
  Teaching_Effectiveness: Number;
  Accessibility_and_Support: Number;
  Communication: Number;
  Enrollment_and_Registration_Process: Number;
  Financial_Aid_and_Services: Number;
  Timeliness_of_Responses: Number;
  Internet_Facilities: Number;
  Technology_and_Equipment: Number;
  Working_Environment: Number;
  addtional_Comments:string;
}

@Component({
  selector: 'app-feedback-form2',
  templateUrl: './feedback-form2.component.html',
  styleUrls: ['./feedback-form2.component.css'],
})
export class FeedbackForm2Component implements OnInit {
  OnboardingfbForm: FormGroup;

  starCount:number = 5;
 add_onboarding:OnboardingFM

  Onboardingfb_submitted: boolean = false;
  add_Onboardingfb: OnboardingFM;
  about_6Solve
  constructor(
    public services: HttpclientService,
    @Host() private parent: FeedbackFormComponent
  ) {}

  ngOnInit() {
    this.OnboardingfbForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.pattern('[a-zA-Z ]{2,32}'),
      ]),
      phone: new FormControl('', [
        Validators.required,
        Validators.pattern('[0-9]{10}'),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),
      ]),

      quality_of_courses: new FormControl('', [Validators.required]),
      relevance_of_curriculum: new FormControl('', [Validators.required]),
      learning_resources: new FormControl('', [Validators.required]),
      teaching_effectiveness: new FormControl('', [Validators.required]),
      accessibility_and_support: new FormControl('', [Validators.required]),
      communication: new FormControl('', [Validators.required]),
      enrollment_and_registration_process: new FormControl('', [
        Validators.required,
      ]),
      financial_aid_and_services: new FormControl('', [Validators.required]),
      timeliness_of_responses: new FormControl('', [Validators.required]),
      internet_facilities: new FormControl('', [Validators.required]),
      technology_and_equipment: new FormControl('', [Validators.required]),
      working_environment: new FormControl('', [Validators.required]),
      addtional_comments:new FormControl()
    });


    this.add_Onboardingfb = {
      Name:'',
      Phone: null,
      Email_Id: '',
      Quality_of_Courses:0,
      Relevance_of_Curriculum:0,
      Learning_Resources:0,
      Teaching_Effectiveness:0,
      Accessibility_and_Support:0,
      Communication:0,
      Enrollment_and_Registration_Process:0,
      Financial_Aid_and_Services:0,
      Timeliness_of_Responses:0,
      Internet_Facilities: 0,
      Technology_and_Equipment:0,
      Working_Environment: 0,
      addtional_Comments:''
         };

  }
  onRatingChanged(rating, type) {
 if (type === 'QOC') {
    this.add_Onboardingfb.Quality_of_Courses = rating;
    this.OnboardingfbForm.patchValue({

      quality_of_courses:rating

    })
  } else if (type === 'ROC') {
    this.add_Onboardingfb.Relevance_of_Curriculum = rating;
    this.OnboardingfbForm.patchValue({

      relevance_of_curriculum:rating

    })
  } else if (type === 'LR') {

    this.add_Onboardingfb.Learning_Resources = rating;
    this.OnboardingfbForm.patchValue({

      learning_resources:rating

    })
  } else if (type === 'TE') {
    this.add_Onboardingfb.Teaching_Effectiveness = rating;
    this.OnboardingfbForm.patchValue({

      teaching_effectiveness:rating

    })
  } else if (type === 'AS') {
    this.add_Onboardingfb.Accessibility_and_Support = rating;
    this.OnboardingfbForm.patchValue({

      accessibility_and_support:rating

    })
  } else if (type === 'CN') {
    this.add_Onboardingfb.Communication = rating;
    this.OnboardingfbForm.patchValue({

      communication:rating

    })
  } else if (type === 'ERP') {
    this.add_Onboardingfb.Enrollment_and_Registration_Process = rating;
    this.OnboardingfbForm.patchValue({

      enrollment_and_registration_process:rating

    })
  } else if (type === 'FAS') {
    this.add_Onboardingfb.Financial_Aid_and_Services = rating;
    this.OnboardingfbForm.patchValue({

      financial_aid_and_services:rating

    })
  } else if (type === 'TOR') {
    this.add_Onboardingfb.Timeliness_of_Responses = rating;
    this.OnboardingfbForm.patchValue({

      timeliness_of_responses:rating

    })
  } else if (type === 'IF') {
    this.add_Onboardingfb.Internet_Facilities = rating;
    this.OnboardingfbForm.patchValue({

      internet_facilities:rating

    })

  } else if (type === 'TAE') {
    this.add_Onboardingfb.Technology_and_Equipment = rating;

    this.OnboardingfbForm.patchValue({

      technology_and_equipment:rating

    })

  } else if (type === 'WE') {
    this.add_Onboardingfb.Working_Environment = rating;
    this.OnboardingfbForm.patchValue({

      working_environment:rating

    })
  }
}









  onKeyPress(event: KeyboardEvent) {
    const key = event.key;

    // Allow only alphabetic characters (A-Z, a-z) and prevent other characters
    if (!/^[a-zA-Z ]+$/.test(key)) {
      event.preventDefault();
    }
  }

  get errorControl() {
    return this.OnboardingfbForm.controls;
  }

  onboardfeedback() {
    this.Onboardingfb_submitted = true;
    if (!this.OnboardingfbForm.valid) {
      this.OnboardingfbForm.markAllAsTouched();
      console.log('Please provide all the required values!');
      console.log(this.OnboardingfbForm.controls);
      return false;
    } else {
      this.add_Onboardingfb = {
        Name: this.OnboardingfbForm.value.name,
        Phone: this.OnboardingfbForm.value.phone,
        Email_Id: this.OnboardingfbForm.value.email,
        Quality_of_Courses: this.OnboardingfbForm.value.quality_of_courses,
        Relevance_of_Curriculum:
          this.OnboardingfbForm.value.relevance_of_curriculum,
        Learning_Resources: this.OnboardingfbForm.value.learning_resources,
        Teaching_Effectiveness:
          this.OnboardingfbForm.value.teaching_effectiveness,
        Accessibility_and_Support:
          this.OnboardingfbForm.value.accessibility_and_support,
        Communication: this.OnboardingfbForm.value.communication,
        Enrollment_and_Registration_Process:
          this.OnboardingfbForm.value.enrollment_and_registration_process,
        Financial_Aid_and_Services:
          this.OnboardingfbForm.value.financial_aid_and_services,
        Timeliness_of_Responses:
          this.OnboardingfbForm.value.timeliness_of_responses,
        Internet_Facilities: this.OnboardingfbForm.value.internet_facilities,
        Technology_and_Equipment:
          this.OnboardingfbForm.value.technology_and_equipment,
        Working_Environment: this.OnboardingfbForm.value.working_environment,
        addtional_Comments: this.OnboardingfbForm.value.addtional_comments
      };

      this.services.add_onboard_feedback(this.add_Onboardingfb).subscribe(
        (res) => {
          if (res) {
            this.parent.openDialog('Onboarding feedback');
            setTimeout(() => {
              this.parent.closeDialog();
            }, 2000);
            this.services
              .send_mail(this.add_Onboardingfb, 'Onboard-form')
              .subscribe((res) => {
                if (res) {
                  console.log('mail sent successfully');
                }
              });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
}
