import {
  Component,
  ElementRef,
  EventEmitter,
  Host,
  HostListener,
  OnInit,
  Output,
} from '@angular/core';
import { HttpclientService } from '../../../../../apiservices/httpclient.service';
import { env } from '../../../../../config/config';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FeedbackFormComponent } from '../feedback-form.component';
interface generalFb {
  Name: string;
  Phone: number;
  Email_Id: string;
  educational_institute: string;
  our_streams_and_paths: string;
  responsiveness_and_helpfulness: string;
  course_interested: string;
  additional_information: string;
}

@Component({
  selector: 'app-feeback-form1',
  templateUrl: './feeback-form1.component.html',
  styleUrls: ['./feeback-form1.component.css'],
})
export class FeebackForm1Component implements OnInit {
  generalFbForm: FormGroup;
  generalfb_submitted: boolean = false;
  add_generalfb: generalFb;
  flag_educational_institute: boolean = false;
  educational_institute_data: any = [
    'Referral',
    'Website',
    'Social Media',
    'Online Search',
    'Advertisement',
  ];

  flag_our_streams_and_paths: boolean = false;
  our_streams_and_paths_data: any = [
    'Interest in a specific field of study',
    'Career advancement',
    'Personal development',
    'Recommendations from others',
  ];

  flag_responsiveness_and_helpfulness: boolean = false;
  responsiveness_and_helpfulness_data: any = [
    'Very Satisfied',
    'Satisfied',
    'Neutral',
    'Dissatisfied',
    'Very Dissatisfied',
  ];

  flag_course_interested: boolean = false;
  course_interested_data: any = [
    'Full-Time',
    'Part-Time',
    'Online/Distance Learning',
    'Short-Term Workshops or Seminars',
  ];
  constructor(
    public services: HttpclientService,
    @Host() private parent: FeedbackFormComponent
  ) {}

  ngOnInit() {
    this.generalFbForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.pattern('[a-zA-Z ]{2,32}'),
      ]),
      phone: new FormControl('', [
        Validators.required,
        Validators.pattern('[0-9]{10}'),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),
      ]),

      educational_institute: new FormControl('', [Validators.required]),
      educational_institute_specific: this.flag_educational_institute
        ? new FormControl('', [Validators.required])
        : new FormControl(),

      our_streams_and_paths: new FormControl('', [Validators.required]),
      our_streams_and_paths_specific: this.flag_our_streams_and_paths
        ? new FormControl('', [Validators.required])
        : new FormControl(),

      responsiveness_and_helpfulness: new FormControl('', [
        Validators.required,
      ]),
      responsiveness_and_helpfulness_specific: this
        .flag_responsiveness_and_helpfulness
        ? new FormControl('', [Validators.required])
        : new FormControl(),

      course_interested: new FormControl('', [Validators.required]),
      course_interested_specific: this.flag_course_interested
        ? new FormControl('', [Validators.required])
        : new FormControl(),

      additional_information: new FormControl(),
    });
  }

  get errorControl() {
    return this.generalFbForm.controls;
  }

  changedetect(key: any) {
    if (key == 'educational_institute') {
      if (this.generalFbForm.value.educational_institute == 'Others') {
        this.flag_educational_institute = true;
      } else {
        this.flag_educational_institute = false;
      }
    } else if (key == 'our_streams_and_paths') {
      if (this.generalFbForm.value.our_streams_and_paths == 'Others') {
        this.flag_our_streams_and_paths = true;
      } else {
        this.flag_our_streams_and_paths = false;
      }
    } else if (key == 'responsiveness_and_helpfulness') {
      if (this.generalFbForm.value.responsiveness_and_helpfulness == 'Others') {
        this.flag_responsiveness_and_helpfulness = true;
      } else {
        this.flag_responsiveness_and_helpfulness = false;
      }
    } else if (key == 'course_interested') {
      if (this.generalFbForm.value.course_interested == 'Others') {
        this.flag_course_interested = true;
      } else {
        this.flag_course_interested = false;
      }
    }
  }
  onKeyPress(event: KeyboardEvent) {
    const key = event.key;

    // Allow only alphabetic characters (A-Z, a-z) and prevent other characters
    if (!/^[a-zA-Z ]+$/.test(key)) {
      event.preventDefault();
    }
  }
  generalfeedback() {
    this.generalfb_submitted = true;

    if (!this.generalFbForm.valid) {
      this.generalFbForm.markAllAsTouched();
      console.log('Please provide all the required values!');
      console.log(this.generalFbForm.controls);
      return false;
    } else {
      this.add_generalfb = {
        Name: this.generalFbForm.value.name,
        Phone: this.generalFbForm.value.phone,
        Email_Id: this.generalFbForm.value.email,
        educational_institute: this.flag_educational_institute
          ? this.generalFbForm.value.educational_institute_specific
          : this.generalFbForm.value.educational_institute,
        our_streams_and_paths: this.flag_our_streams_and_paths
          ? this.generalFbForm.value.our_streams_and_paths_specific
          : this.generalFbForm.value.our_streams_and_paths,
        responsiveness_and_helpfulness: this.flag_responsiveness_and_helpfulness
          ? this.generalFbForm.value.responsiveness_and_helpfulness_specific
          : this.generalFbForm.value.responsiveness_and_helpfulness,
        course_interested: this.flag_course_interested
          ? this.generalFbForm.value.course_interested_specific
          : this.generalFbForm.value.course_interested,
        additional_information: this.generalFbForm.value.additional_information,
      };

      this.services.add_general_feedback(this.add_generalfb).subscribe(
        (res) => {
          if (res) {
            this.parent.openDialog('General feedback');
            setTimeout(() => {
              this.parent.closeDialog();
            }, 2000);
            this.services
              .send_mail(this.add_generalfb, 'General-form')
              .subscribe((res) => {
                if (res) {
                  console.log('mail sent successfully');
                }
              });
          }
        },
        (error) => {
          console.log(error);
        }
      );
      console.log(this.add_generalfb);
    }
  }
}
