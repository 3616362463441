<div id="demo" class="carousel slide" data-ride="carousel">
	<ul class="carousel-indicators">
		<li data-target="#demo" data-slide-to="0" class="active"></li>
		<li data-target="#demo" data-slide-to="1"></li>
		<li data-target="#demo" data-slide-to="2"></li>
	</ul>
	<div class="carousel-inner">
		<div class="carousel-item active overlay-black-middle">
			<div class="slide1" style="background-image: url(assets/images/main-slider/food/slide1.jpg);">
				<div class="carousel-caption text-white">
					<h1>FOOD INDUSTRY</h1>
					<p>Industry is ready to help you in making unique-looking and best website in a moment. </p>
				</div>   
			</div>
		</div>
		<div class="carousel-item overlay-black-middle">
			<div class="slide1" style="background-image: url(assets/images/main-slider/food/slide2.jpg);">
				<div class="carousel-caption text-white">
					<h1>FOOD INDUSTRY</h1>
					<p>Industry is ready to help you in making unique-looking and best website in a moment.</p>
				</div>   
			</div>
		</div>
	</div>
	<a class="carousel-control-prev" href="#demo" data-slide="prev">
		<span class="carousel-control-prev-icon"></span>
	</a>
	<a class="carousel-control-next" href="#demo" data-slide="next">
		<span class="carousel-control-next-icon"></span>
	</a>
</div>