<div class="section-full bg-primary" id="about-us">
	<div class="container-fluid">
		<div class="row align-item-center">
			<div class="col-lg-6 p-lr0">
				<div class="video-bx agro-video">
					<img src="{{env+content.image.url}}" class="img-cover" alt=""/>
					<div class="video-play-icon">

					</div>
				</div>
			</div>
			<div class="col-lg-6 content-inner-2 agro-about">
				<div class="section-content">
					<div class="section-head text-white m-b20">
						<h2 class="title">{{content.title}}</h2>
					</div>
					<div [innerHTML]="content.description"></div>
					<a class="site-button  white" (click)="openDialog()">{{content.button_text}}<span _ngcontent-qtu-c105="" class="circle"></span></a>
				</div>
			</div>
		</div>
	</div>
</div>
