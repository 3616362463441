<div class="section-full content-inner bg-white about-bx4 frame-out" id="about-us">
	<div class="container-fluid">
		<div class="row align-items-center">
			<div class="col-lg-7 col-md-12 m-b30">
				<div class="about-img-box">
					<div class="about-img-one">
						<div class="video-bx">
							<img src="assets/images/about/creative/about-7.jpg" alt="">
							<div class="video-play-icon">
					
							</div>
						</div>
					</div>
					<div class="about-img-two">
						<img src="assets/images/about/creative/about-6.jpg" alt="">
					</div>
				</div>
			</div>
			<div class="col-lg-5 m-b30">
				<div class="dlab-tabs tabs-site-button">
					<div class="about-inner-content">
						<div class="section-head m-b20">
							<h5 class="title-small">Solar Plant</h5>
							<div class="dlab-separator-outer">
								<div class="dlab-separator bg-primary style-skew"></div>
							</div>
							<h2 class="title">Solar Wind <br/> Power Technology</h2>
						</div>
						<p><strong class='text-primary'>Solar Plant</strong> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an printer took a galley of type and scrambled it to make.</p>
						<a href="javascript:void(0);" class="site-button btnhover14">About Us</a>
					</div>
				</div>
			</div>
		</div>
	</div>
	</div>
