import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { HttpclientService } from '../../../../apiservices/httpclient.service';
import { HistoryService } from 'src/app/apiservices/history.service';
import { env } from '../../../../config/config';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-testimonial-four',
  templateUrl: './testimonial-four.component.html',
  styleUrls: ['./testimonial-four.component.css'],
})
export class TestimonialFourComponent implements OnInit {
  url;
  flag = false;
  double_flag = false;
  servicelist;
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    margin: 30,
    nav: true,
    dots: true,
    navText: [
      '<i class="ti ti-arrow-left"></i>',
      '<i class="ti ti-arrow-right"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 2,
      },
      940: {
        items: 3,
      },
    },
  };

  slideStore = [
    {
      title: 'Some White paper',
      description: 'Some sample description about the white papers',
      image: 'pic1.jpg',
    },
    {
      title: 'Some White paper',
      description: 'Some sample description about the white papers',
      image: 'pic2.jpg',
    },
    {
      title: 'Some White paper',
      description: 'Some sample description about the white papers',
      image: 'pic3.jpg',
    },
    {
      title: 'Some White paper',
      description: 'Some sample description about the white papers',
      image: 'pic4.jpg',
    },
    {
      title: 'Some White paper',
      description: 'Some sample description about the white papers',
      image: 'pic5.jpg',
    },
    {
      title: 'Some White paper',
      description: 'Some sample description about the white papers',
      image: 'pic6.jpg',
    },
    {
      title: 'Some White paper',
      description: 'Some sample description about the white papers',
      image: 'pic1.jpg',
    },
  ];

  constructor(
    private service: HttpclientService,
    private history: HistoryService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.url = env.apiurl;
    this.servicelist = this.history.get_services();
    if (this.servicelist.white_papers.length >= 3) {
      this.flag = true;
    } else if (
      this.servicelist.white_papers.length < 3 &&
      this.servicelist.white_papers.length > 0
    ) {
      this.double_flag = true;
    }
    console.log(this.servicelist);
  }

  get_paper(e) {
    this.history.white_paper(e);
    this.router.navigateByUrl(`/whitepaper/${e.slug}`);
  }
}
