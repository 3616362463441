import { Component, OnInit } from '@angular/core';
import {HttpclientService} from '../apiservices/httpclient.service'
import {env} from '../config/config'
declare var TradeZone : any;
@Component({
  selector: 'app-labs',
  templateUrl: './labs.component.html',
  styleUrls: ['./labs.component.scss']
})
export class LabsComponent implements OnInit {
  config
  flag
  whatsapp_number
  constructor(private service:HttpclientService) { }

  ngOnInit() {
    this.move_top()
    TradeZone.init();
    this.getpage_whatapp_no()
  }

  move_top() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

 getpage_whatapp_no(){
 this.whatsapp_number=localStorage.getItem('whatsapp_number')
 }

  goToUrl(): void {
    window.open(
      `https://wa.me/+${this.whatsapp_number}?text=I%20like%20to%20enquiry%20about`,
      '_blank' // <- This is what makes it open in a new window.
    );
  }
}
