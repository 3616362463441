import { Component, OnInit } from '@angular/core';
import { HttpclientService } from '../../../../apiservices/httpclient.service';
import { env } from '../../../../config/config';

@Component({
  selector: 'app-slider-whitepaper',
  templateUrl: './slider-whitepaper.component.html',
  styleUrls: ['./slider-whitepaper.component.css'],
})
export class SliderWhitepaperComponent implements OnInit {
  slider: any;
  env: any;
  flag = false;
  hide_flag = false;
  constructor(private service: HttpclientService) {}
  banner_image;
  ngOnInit() {
    this.get_banner();
    this.env = env.apiurl;
  }
  get_banner() {
    this.service.getAllValues('whitepaper-2nd-sections').subscribe((res) => {
      this.slider = res;
      this.banner_image = this.slider[0].image.url;
      console.log(this.slider, 'slider');
      if (this.slider) {
        this.flag = true;
      }
    });
  }
}
