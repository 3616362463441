<link
  class="skin"
  rel="stylesheet"
  type="text/css"
  href="/assets/css/skin/skin-4.css"
/>
<div class="page-wraper">
  <app-header-four></app-header-four>
  <app-slider-whitepaper></app-slider-whitepaper>
  <div class="content-block-whitepaper">
    <div class="container">
      <!-- <div class="section-full content-inner">
        <div class="container" *ngIf="sec_flag">
          <div
            class="row align-items-center"
            *ngFor="let i of section; index as j"
          >
            <div class="col-lg-6 col-md-12 m-b30 steel-about align-self-start">
              <div class="row sp10 whiteimage">
                <div class="col-md-12 m-b10">
                  <img
                    src="{{ url + i.image.url }}"
                    class="img-cover img-fluid rounded control-width"
                    alt="whitepaper_image-{{ j }}"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6 m-b30 steel-about">
              <div class="section-head m-b20">
                <h1 class="title">{{ i.title }}</h1>
                <p>{{ i.desc }}</p>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="card rounded-0 border-0 shadow">
        <div class="card-header border-0">
          <div class="row">
            <div class="col-lg-6 offset-lg-6">
              <div class="row dropdown">
                <div class="col-lg-5 col">
                  <mat-select
                    placeholder="All Services"
                    floatlLabel="never"
                    [(ngModel)]="selected"
                  >
                    <mat-option (click)="all_services()">
                      All Services
                    </mat-option>
                    <mat-option
                      *ngFor="let pokemon of content"
                      [value]="pokemon.title"
                      (click)="filter()"
                    >
                      {{ pokemon.title }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body p-0" id="filterContainer">
          <div class="row" *ngIf="flag">
            <div class="col-md-12">
              <div
                class="media position-relative align-items-center py-5 px-3 px-lg-5 border-top all qa generic"
                *ngFor="let paper of whitepaper"
              >
                <p *ngIf="paper.service_name" class="paper-label">
                  {{ paper.service_name }}
                </p>
                <div class="media-body mr-lg-5">
                  <h5 class="my-3">{{ paper.title }}</h5>
                  <p class="my-3 text">{{ paper.short_desc }}</p>
                  <a class="readmore mt-3" (click)="get_paper(paper)">
                    {{ buttontext }}
                    <!-- <span class="circle"></span> -->
                  </a>
                </div>
                <!-- <i class="fa fa-ellipsis-h icon" aria-hidden="true"></i> -->
                <!-- <div class="cs-indicator">
                  <div class="section-indicator type-comp3 on-white casestudy">
                    White Paper
                  </div>
                </div> -->
                <div class="paper-image">
                  <img [src]="url + paper.image.url" alt="white_image" />
                </div>
              </div>
            </div>
          </div>
          <div class="row" id="NotFound" style="display: none">
            <div class="col-md-12">
              <div class="alert alert-warning mx-3 mt-3" role="alert">
                <p class="text-center">Nothing Found...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-footer-four></app-footer-four>
  <button class="scroltop style2 radius" type="button" (click)="move_top()">
    <i class="fa fa-arrow-up"></i>
  </button>
</div>
