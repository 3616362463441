import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-five',
  templateUrl: './about-five.component.html',
  styleUrls: ['./about-five.component.css']
})
export class AboutFiveComponent implements OnInit {
	
		//subtitle= "Solar Plant";
		//title: "Solar Wind <br/> Power Technology";
		//description: "<strong class='text-primary'>Solar Plant</strong> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an printer took a galley of type and scrambled it to make."

  constructor() { }

  ngOnInit(): void {
  }

}
